import React from 'react';
import styled from 'styled-components';
import CSLTable from '../Common/MCCTable';
import { FaPencilAlt,FaEdit, FaTimes,FaPlusCircle, FaEllipsisV } from "react-icons/fa";
import { IoIosCheckboxOutline, IoIosSquareOutline, IoIosWarning } from "react-icons/io";
import RiskTriggersConfigModal from './RiskTriggersConfigModal';
import Store from '../../Common/Store'
import AlertBox from '../Common/AlertBox';
import Toggle from 'react-toggle';
import "./ReactToggle.css";
import VerticalEllipsisMenuFolderReasonBreach from "./VerticalEllipsisMenuFolderReasonBreach";
import CSLLoader from './../Common/CSLLoader';


const SearchInput = styled.input`
    height: 30px;
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #222222;
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
`;
const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #1E3E62;
    color: #ffffff;
    border: 1px solid #1E3E62;
    width: 37%;
    font-size: 13px;
    height: 43px;
    border-radius: 4px;
    display: inline-block;
    margin-left: 5px;
`;
const MRModalLabel = styled.div`
    color: #212121;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
    display: inline-block;
`;
const ExportButton = styled.button`
    box-sizing: border-box;
    background-color: #213E61;
    color: #F2F2F2;
    border: 1px solid #213E61;
    width: 125px;
    font-size: 14px;
    height: 32px;
    border-radius: 5px;
    margin-right: 3px;
    margin-top: 19px;
`;

const EmailContainer = styled.div`
	width: 100%;
`;
const EmailInnerContainer = styled.div`
	background-color: #ffffff;
	padding: 30px;
`;
const EmailBoldLabel = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 5px;
`;
const EmailTopHeader = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 5px;
	width:100%;
	height: 58px;
	padding: 20px;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  background-color: #ffffff;
  box-sizing: border-box;	
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 210%;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: 0%;
  top: -46px;
  z-index: 1000;
  opacity: 0.8;
`;

class RiskTriggers extends React.Component
{
    state = {
        showActiveCheckbox: false,
        risk_triggers: null,
        show_risk_triggers_array: null,
        risk_triggers_search: null,
        riskTriggersActiveArrayAll: null,
        riskTriggersInactiveArrayAll: null,
        ready: false,
        show_risk_triggers_modal: false,
        standard_risk_triggers: null,
        curid: 0,
        content: null,
        alert_param: null,
        headerText: "",
        isLoadedIcon: false
    };
    constructor(props) {
        super(props);        
    }

    componentDidMount()
	{		
		let riskTriggersActiveArrayAll = [];		
		let riskTriggersInactiveArrayAll = [];
		for(let i=0;i<this.props.risk_triggers.length;i++){
			if(this.props.risk_triggers[i].isActive === 1){
                riskTriggersActiveArrayAll.push(this.props.risk_triggers[i])
                riskTriggersInactiveArrayAll.push(this.props.risk_triggers[i])
			}else{
				riskTriggersInactiveArrayAll.push(this.props.risk_triggers[i])
			}
		}
		let risktriggersValue = [];
		if(this.props.general.risk_triggers_active_inactive){
			risktriggersValue = riskTriggersInactiveArrayAll
			console.log("Sourav")
		}else{			
			risktriggersValue = riskTriggersActiveArrayAll
			console.log("Sourav1")
		}

        let risk_triggers_object = {};
        for(let d of this.props.risk_triggers){
            risk_triggers_object[d.riskTriggerID] = d
        }
        let risk_triggers_array = [];
        for(let k in risk_triggers_object){
            risk_triggers_array.push(risk_triggers_object[k])
        }
        console.log("risk_triggers_array===>",risk_triggers_array);

		this.setState({showActiveCheckbox: this.props.general.risk_triggers_active_inactive,
			// risk_triggers: this.props.risk_triggers,
			risk_triggers: risk_triggers_array,
			//show_risk_triggers_array: risktriggersValue,
			// show_risk_triggers_array: this.props.risk_triggers,
			show_risk_triggers_array: risk_triggers_array,
			// risk_triggers_search: this.props.risk_triggers,
			risk_triggers_search: risk_triggers_array,
			riskTriggersActiveArrayAll: riskTriggersActiveArrayAll,
            riskTriggersInactiveArrayAll: riskTriggersInactiveArrayAll,
            standard_risk_triggers: this.props.standard_risk_triggers,
            ready: true,alert_param: null});
		console.log("risk_triggers===>",this.props.risk_triggers);
    }
    
    componentDidUpdate(prevProps)
	{
		if (prevProps !== this.props) {
			let riskTriggersActiveArrayAll = [];		
            let riskTriggersInactiveArrayAll = [];
            for(let i=0;i<this.props.risk_triggers.length;i++){
                if(this.props.risk_triggers[i].isActive === 1){
                    riskTriggersActiveArrayAll.push(this.props.risk_triggers[i])
                    riskTriggersInactiveArrayAll.push(this.props.risk_triggers[i])
                }else{
                    riskTriggersInactiveArrayAll.push(this.props.risk_triggers[i])
                }
            }
            let risktriggersValue = [];
            if(this.props.general.risk_triggers_active_inactive){
                risktriggersValue = riskTriggersInactiveArrayAll
                console.log("Sourav")
            }else{			
                risktriggersValue = riskTriggersActiveArrayAll
                console.log("Sourav1")
            }

            let risk_triggers_object = {};
            for(let d of this.props.risk_triggers){
                risk_triggers_object[d.riskTriggerID] = d
            }
            let risk_triggers_array = [];
            for(let k in risk_triggers_object){
                risk_triggers_array.push(risk_triggers_object[k])
            }
            console.log("risk_triggers_array===>",risk_triggers_array);

            this.setState({showActiveCheckbox: this.props.general.risk_triggers_active_inactive,
                // risk_triggers: this.props.risk_triggers,
                risk_triggers: risk_triggers_array,
                //show_risk_triggers_array: risktriggersValue,
                // show_risk_triggers_array: this.props.risk_triggers,
                show_risk_triggers_array: risk_triggers_array,
                // risk_triggers_search: this.props.risk_triggers,
                risk_triggers_search: risk_triggers_array,
                riskTriggersActiveArrayAll: riskTriggersActiveArrayAll,
                riskTriggersInactiveArrayAll: riskTriggersInactiveArrayAll,
                standard_risk_triggers: this.props.standard_risk_triggers,
                ready: true,alert_param: null,
                isLoadedIcon: false});
            console.log("risk_triggers===>",this.props.risk_triggers);			
		}
    }
    
    searchValue = (event) => {
		event.preventDefault();
        console.log("Sourav",event.target.value);
		console.log("fddsf",this.state.risk_triggers_search)
		if(!this.state.showActiveCheckbox){
			let search_string = event.target.value;
			if (search_string === "") {
				let risk_triggers = this.state.riskTriggersActiveArrayAll;            
				this.setState({show_risk_triggers_array: risk_triggers});
			}else {
				let all_value = this.state.riskTriggersActiveArrayAll;
				let filtered_values = [];
				all_value.forEach((breach) => {
					if (breach.riskTriggerName.toLowerCase().includes(search_string.toLowerCase())) {
						filtered_values.push(breach);
					}                
				})            
				let risk_triggers = filtered_values;

				this.setState({show_risk_triggers_array: risk_triggers})
			}
		}else{
			let search_string = event.target.value;
			if (search_string === "") {
				let risk_triggers = this.state.riskTriggersInactiveArrayAll;            
				this.setState({show_risk_triggers_array: risk_triggers});
			}else {
				let all_value = this.state.riskTriggersInactiveArrayAll;
				let filtered_values = [];
				all_value.forEach((breach) => {
					if (breach.riskTriggerName.toLowerCase().includes(search_string.toLowerCase())) {
						filtered_values.push(breach);
					}                
				})            
				let risk_triggers = filtered_values;

				this.setState({show_risk_triggers_array: risk_triggers})
			}
		}
    }
    
    handleActiveInactive = (value) => (event) => {        
        console.log("value",value)  
		let data = value === 1 ? true : false;
		this.props.changeActiveInactive(data);		       
    }

    handlenaSectionIncluded = (section_id) => (event) => {
		console.log("content:", section_id);
		let {show_risk_triggers_array} = this.state;
		let newrisk_triggers_array = [];
		show_risk_triggers_array.forEach((item) => {
			if (item.riskTriggerID === section_id) {
				item.isActive = item.isActive === 1 ? 0 : 1; 
				//console.log("matched", item);
			}
			newrisk_triggers_array.push(item);
		})
		console.log("emails:", newrisk_triggers_array);
        this.setState({isLoadedIcon: true})
		this.props.updaterisk_triggers_array(newrisk_triggers_array);
	}

    showConfigModal = (curid, isdelete) => (obj) => {
		console.log("curid==>", curid)
		console.log("obj==>", obj)
		if(obj === 'Edit'){			
            const content = this.state.risk_triggers.find((item) => {
                return item.riskTriggerID === curid;
            })
            this.setState({content,curid: curid,show_risk_triggers_modal: true, headerText: "Edit"});   
		}else if(obj === 'Delete'){
            if(isdelete === 0){
                let alert_param = {title: 'Warning', message: 'Are you sure you want to delete this Risk Category from the list?', ok_text: 'Yes', cancel_text: 'No', confirm: true, 
                           alertHandler: this.updatedeleteReasonBreach, stack: {id: curid}}
                this.setState({alert_param: alert_param})
            }else{
                let alert_param = {title: 'ERROR', message: 'This Risk Trigger has already been used.', ok_text: 'Ok', confirm: false, 
                           alertHandler: this.updateafternodelete, stack: {}}
                this.setState({alert_param: alert_param})
            }			
		}
	};

    tableData = () => {
        console.log("this.state.show_risk_triggers_array",this.state.show_risk_triggers_array)
        let ret = {data: [], columns: []};
        ret.columns =[
                        {Header: 'Name', accessor: 'name', minWidth: 240, headerStyle: {textAlign: 'left'}},					
						{'Header' : 'Status', Cell: row => (
							<div> 
							<Toggle
								style={{float:'left'}}
								defaultChecked={row.original.status === "Active" ? true : false}
								icons={false}	
                                onChange={this.handlenaSectionIncluded(row.original.id)}						
							/>
							</div>
						 ), headerStyle:{textAlign: 'center'},style: {textAlign: 'center'}},						 
                        {'Header' : '', Cell: row => (
                            <div>                                
                                  <VerticalEllipsisMenuFolderReasonBreach editDelete={this.showConfigModal(row.original.id, row.original.isdelete)} options={["Edit", "Delete"]} />	
                            </div>
                         ), minWidth: 40, headerStyle:{textAlign: 'left'},style: {textAlign: 'center'}}
                        ];

        let risk_triggers_sort_array = this.state.show_risk_triggers_array.sort(function(a,b) {return (a.riskTriggerName > b.riskTriggerName) ? 1 : ((b.riskTriggerName > a.riskTriggerName) ? -1 : 0);} );
		risk_triggers_sort_array.forEach((item) => {
			let statusValue = item.isActive === 1 ? "Active" : "Inactive"
			let elem = {id: item.riskTriggerID, name: item.riskTriggerName, status: statusValue, isdelete: item.isDelete};
			ret.data.push(elem);
        })
        return ret;
    }

    notDeleteRiskTrigger = () => {
        //alert("This Risk Trigger has already been used.")
        let alert_param = {title: 'ERROR', message: 'This Risk Trigger has already been used.', ok_text: 'Ok', confirm: false, 
                           alertHandler: this.updateafternodelete, stack: {}}

        this.setState({alert_param: alert_param})
    }
    
    updateafternodelete = (result, stack) => {
        console.log(result,stack)
        this.setState({alert_param: null})        
    }

    deleteRiskTrigger = (id) => (event) => {
        event.preventDefault(); 
        let alert_param = {title: 'Warning', message: 'Are you sure you want to delete this Risk Category from the list?', ok_text: 'Yes', cancel_text: 'No', confirm: true, 
                           alertHandler: this.updatedeleteReasonBreach, stack: {id: id}}
        this.setState({alert_param: alert_param})
    }

    updatedeleteReasonBreach = (result, stack) => {  
        if(!result) {
            this.setState({alert_param: null})
            return
        }
        console.log("id",stack.id)
           	let {risk_triggers} = this.state;
			let newreason = [];
			for(let i=0;i<this.state.risk_triggers.length;i++)
			{
				if(this.state.risk_triggers[i].riskTriggerID !== stack.id){
					newreason.push(this.state.risk_triggers[i])
				}
			}			 
			console.log("delete===>",newreason);   
			this.props.deleteRiskTriggersValue(newreason);
    }

    openModal = (id) => (event) => {
		event.preventDefault();     
		console.log("id",id); 
		const content = this.state.risk_triggers.find((item) => {
			return item.riskTriggerID === id;
		})
		this.setState({content,curid: id,show_risk_triggers_modal: true});        
	}
    
    addRiskTrigger = (event) => {
		event.preventDefault();
		this.setState({show_risk_triggers_modal: true,curid: 0, headerText: "Add"})
    }
    closeModal = () => {		
		this.setState({show_risk_triggers_modal: false})
    }
    
    saveRiskTriggerValue = (obj) => {
		this.setState({show_risk_triggers_modal: false, isLoadedIcon: true})
		this.props.saveRiskTriggersValue(obj)
    }
    
    importType = (event) => {
        event.preventDefault();
        console.log("standard_risk_triggers",this.state.standard_risk_triggers)
        let alert_param = {title: 'Warning', message: 'Are you sure you want to import the Risk Triggers?', ok_text: 'Yes', cancel_text: 'No', confirm: true, 
                           alertHandler: this.updateimportTypeBreach, stack: {}}
        this.setState({alert_param: alert_param})
    }

    updateimportTypeBreach = (result, stack) => {       
        if(!result) {
            this.setState({alert_param: null})
            return
        }       
        let show_risk_triggers_array = this.state.risk_triggers;
        this.state.standard_risk_triggers.forEach((breach) => {
            show_risk_triggers_array.push(breach);               
        })
        this.props.importRiskTriggersValue(show_risk_triggers_array)  
    }

    updateRiskTriggersValue = (content) => {
		console.log("content:", content);
		//console.log("this.state",this.state.reason_for_removal)
		let {risk_triggers} = this.state;
		let newreason = [];
		risk_triggers.forEach((item) => {
			if (item.riskTriggerID === content.riskTriggerID) {
				item = content;
				console.log("matched", item);
			}
			newreason.push(item);
		})
		console.log("resons:", newreason);
		this.setState({show_risk_triggers_modal: false, isLoadedIcon: true})
		this.props.updateRiskTriggersValue(newreason);		
	}
    
    
    render()    
	{
        if (!this.state.ready) {
			return (<div>Loading...</div>);
		}          
		return(
        <div>            
            <div>
                    {
                        (() => {
                            if (this.state.show_risk_triggers_modal) {
                                return (<div ><InactiveOverlay style={{height: "105%"}}/><RiskTriggersConfigModal headerText={this.state.headerText} closeModal={this.closeModal} curid={this.state.curid} content={JSON.parse(JSON.stringify(this.state.content))} updateRiskTriggersValue={this.updateRiskTriggersValue} saveRiskTriggerValue={this.saveRiskTriggerValue}/></div>);
                            }
                        })()
                    }
                    {
                        (() => {
                            if(this.state.isLoadedIcon){
                                return <div><CSLLoader style={{position: 'absolute'}}/></div>
                            }
                        })()
                    }                    
                    <EmailContainer>
                        <EmailInnerContainer>
                            <EmailTopHeader style={{display: "inline-block" , fontWeight: 700}}>
                                Risk Categories
                                <div style={{float:'right'}}>
                                    <div onClick={this.addRiskTrigger}  style={{float: "left", cursor: "pointer",fontSize: "25px",color: "#04ADA8"}}>
                                        <FaPlusCircle  /> 
                                    </div>
                            
                                    <div style={{float: "right", cursor: "pointer",fontSize: "25px",color: "#e4e4e4"}}>
                                        <FaEllipsisV  /> 
                                        </div>
                                </div>
							</EmailTopHeader>									
                            <CSLTable add={false} processData={this.tableData} tableRows={10} />
                        </EmailInnerContainer>
                    </EmailContainer>
            </div>
            <AlertBox alertParam = {this.state.alert_param}/>
        </div>
        );
	}
}

export default RiskTriggers;