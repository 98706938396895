import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store';
import APICall from '../../Common/APICall';
import Dropzone from '../Common/Dropzone';
import { FaTimes} from 'react-icons/fa';
import AlertBox from '../Common/AlertBox';
import CSLLoader from '../Common/CSLLoader';
import Utils from '../../Common/Utils.js';
import moment from 'moment';

const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 80%;
    position: absolute;
    top: 350px;
    z-index: 1001;
    background-color: rgb(243, 247, 251);
    box-shadow: rgb(167, 166, 166) 0px 0px 5px;
    margin-bottom: 10px;
    border-radius: 5px;
    margin-left: 100px;
`;
const MRModalHeader = styled.div`
    background-color: white;
    display: block;
    padding-top: 5px;
    font-size: 14px;
    font-weight: 700;
    height: 100px;    
    width: calc(100% - 10px);
    border-width: 1px 1px 1px 10px;    
    border-left: 9px solid #04ADA8; 
    border-bottom: 1px solid #DFDFDF;
`;
const MRModalHeaderText = styled.div`
    font-weight: 700;
    font-size: 22px;
    float: left;
    margin-top: 24px;
    margin-left: 35px;  
    color: #143151;  
`;
const MRModalSubHeaderText = styled.div`
    font-weight: 700;
    font-size: 13px;
    float: left;
    margin-left: 35px;
    margin-bottom: 20px;
    margin-top: 10px;
    color: #636363;
`;
const MRModalHeaderCloseBtn = styled.div`
    position: absolute;
    top: 8px;
    right: 10px;
    box-sizing: border-box;
    margin-top: 24px;
    margin-right: 20px;
    font-size: 25px;
    cursor: pointer;
    font-weight: 200;
`;
const MRModalBody = styled.div`
    position: relative;
    display: block;
    box-sizing: border-box;
    background-color: rgb(243, 247, 251);
    padding: 10px;
    border-radius: 4px;
`;
const MRModalLabel = styled.div`
    color: #030303;
    font-weight: 600;
    margin-top: 15px;
    font-size: 15px;
    margin-bottom: 15px;
`;
const MRModalFooter = styled.div`
    position: relative;
    background-color: white;
    height: 80px;
    border-style: solid;
    border-width: 1px;
    border-color: #f3f7fb;
    width: 100%;
    border-top: 1px solid #DFDFDF;
`;
const MRModalSaveCloseBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #143151;
    color: #ffffff;
    width: 145px;
    height: 45px;
    border-radius: 4px;
    border: 1px solid #143151;
    margin-left: 5px;
    font-size: 15px;
    margin-left: 10px;
    float: right;
    cursor: pointer;
`;
const MRModalTextarea = styled.textarea`
    width: 99%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #000000;
    font-family: 'Montserrat', sans-serif;
`;
const MRModalCancelBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #545454;
    border: 2px solid #dadada;
    width: 145px;
    height: 45px;
    border-radius: 4px;
    font-size: 15px;
    float: right;
    cursor: pointer;
`;
const MRModalSelect = styled.select`
    box-sizing: border-box;
    height: 40px;
    width: 40%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #5F6368;    
`;
const MRModalInput = styled.input`
    height: 25px;
    width: 99%;
    box-shadow: rgb(196, 196, 196) 0px 0px 4px;
    color: #545454;
    font-family: montserrat;
    font-size: 14px;
    font-weight: 600;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(255, 255, 255);
    border-image: initial;
    border-radius: 3px;
    padding: 6px;
`;
const ModalNameInputDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top:30px;
    color: #282828;
    font-family: 'Montserrat', sans-serif;
`;
const CSLDateCover = styled.div`   
    width: 284px;
    border-radius: 3px;
    height: 33px;
    padding-top: 5px;
    padding-left: 10px;
    background-color: #fff;
    border: 1px solid #ffffff;
    box-sizing: border-box; 
    box-shadow: 0 0 4px #c4c4c4;
    color: ${process.env.DEFAULT_TEXT_COLOR};
`;
const UserHr = styled.div`
border-top: 2px solid #E9EBED;
`;
const SpacerXL = styled.div`
    height: 20px;
`;
const SpacerL = styled.div`
    height: 10px;
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 151%;
  width: 82%;
  background-color: #ffffff;
  position: absolute;
  left: 7%;
  top: 30px;
  z-index: 1002;
  opacity: 0.8;
`;
const ModalNameLabelDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top: 30px;
    font-weight: 600;
    color: #282828;
`;


class TeamFollowupModal extends React.Component
{

    state = {task: null, payload: {}, task_id: null, comments: "",task_title:"", task_note: "", ready: false, send_mail_config: false,
    curlane: '',binFiles: [], cur_files_json: null, is_disabled: false,alert_param: null, isLoadedIcon: false, parentTaskID: 0 , dueDateValue: ''};

    GetPayloadData = (parent_task) => {
        let regulatorLists = [];
        if('regulator_types' in Store.getStoreData('module_config') && Store.getStoreData('module_config').regulator_types.length !== 0){
            Store.getStoreData('module_config').regulator_types.forEach((item) => {
                if(item.isActive === 1){
                    regulatorLists.push(item);
                }
            })
        }

        let payload = {};
        let parent_task_json = JSON.parse(parent_task.task_json);
        payload.Ref_ID = parent_task_json.object_data.ref_id;
        payload.breach_type = parent_task_json.object_data.headerinfo.breach_type === 'conduct_rules_breach' ? 'Conduct Rules Breach' : 'Incident';

        let qtext = parent_task_json.object_data.headerinfo.breach_deatils;
        if(Utils.isBase64(parent_task_json.object_data.headerinfo.breach_deatils)){
            qtext = window.decodeURIComponent(atob(qtext))
        }

        payload.breach_description = qtext;
        payload.breach_report = parent_task_json.object_data.headerinfo.is_reportability === 1 ? 'Reportable' : 'Non Reportable';

        const c_date = new Date(parent_task_json.object_data.headerinfo.reported_date);
        let day = c_date.getDate().toString().length === 1 ? "0"+c_date.getDate().toString() : c_date.getDate().toString();
        let month = (c_date.getMonth() + 1).toString().length === 1 ? "0"+(c_date.getMonth() + 1).toString() : (c_date.getMonth() + 1).toString();
        let year = c_date.getFullYear().toString();
        const reported_date_cal = day+"/"+month+"/"+year;
        payload.reported_date = reported_date_cal;
        console.log("parent_task_json.object_data.headerinfo.regulator_id",parent_task_json.object_data.headerinfo.regulator_id)
        let regulatorName = "";
        regulatorLists.forEach((item) => {
            if(parseInt(item.id) === parseInt(parent_task_json.object_data.headerinfo.regulator_id)){
                regulatorName = item.ragulatorname;
            }
        })
        payload.regulator_name = regulatorName;
        return payload;
    }

	componentDidMount()
	{
        let {task,payload,task_id,task_title,task_note,curlane,ready} = this.state;
        let task_data = Store.getStoreData('followup_task');
        curlane = task_data.subtask.cur_lane;
        let followup_task = task_data.subtask;
        task_id = followup_task.id;
        let task_json = JSON.parse(followup_task.task_json);
        task_title = task_json.object_data.subject
        task_note = task_json.object_data.note
        let cur_files_json = [];
        if('bin_files' in task_json.object_data){
            cur_files_json = task_json.object_data.bin_files;
        }
        let task_command = "";
        if('commands' in task_json.object_data){
            task_command = task_json.object_data.commands;
        }
        let parent_task = task_data.parenttask;
        console.log("parent_task",followup_task)
        payload = this.GetPayloadData(parent_task);
        console.log("payload", task_data);
        let dueDate = moment(followup_task.due_date).unix();
        let dueDateValue = moment.unix(dueDate).format('DD/MM/YYYY')
        this.setState({task: task_json,payload,task_id,task_title,task_note,comments: task_command,curlane,cur_files_json: cur_files_json, parentTaskID: parent_task.id,dueDateValue,ready:true});
	}

	componentDidUpdate(prevProps)
	{
		if (prevProps !== this.props) {
            let {task,payload,task_id,task_title,task_note,curlane,ready} = this.state;
            let task_data = Store.getStoreData('followup_task');
            curlane = task_data.subtask.cur_lane;
            let followup_task = task_data.subtask;
            task_id = followup_task.id;
            let task_json = JSON.parse(followup_task.task_json);
            task_title = task_json.object_data.subject
            task_note = task_json.object_data.note
            let parent_task = task_data.parenttask;
            console.log("parent_task",parent_task)
            payload = this.GetPayloadData(parent_task);
            console.log("payload", task_data);
            let dueDate = moment(followup_task.due_date).unix();
            let dueDateValue = moment.unix(dueDate).format('DD/MM/YYYY')
            this.setState({task: task_json,payload,task_id,task_title,task_note,curlane,parentTaskID: parent_task.id, dueDateValue, ready:true});
		}
	}
    closeModal = (event) => {
        if(Store.getStoreData('isreferenceUrl') === true){
            if(Store.getStoreData('isReferenceEmail') === true){
                window.location = '/';
            }else{
                this.props.closeTaskModal();
            }
        } else {
            this.props.closeModal();
        }
    }

    UpdateSubTask = (event) => {
        event.preventDefault();
        let task_json = this.state.task;
        //console.log(task_json);
        let bin_files = this.state.cur_files_json === null ? this.state.binFiles : [...this.state.binFiles, ...this.state.cur_files_json];

        if(this.state.curlane === "COMP_BREACH_FOLLOWUP_ASSIGNMENT"){
            task_json['task_data'].last_action = 'COMP_BREACH_FOLLOWUP_COMPLETE';
            task_json['task_data'].cur_lane = 'COMP_BREACH_FOLLOWUP_COMPLETE';
            task_json['task_data'].actiondate = Date.now();
            task_json['object_data'].commands = this.state.comments;
            task_json['action_data'] = {action: "COMP_BREACH_FOLLOWUP_COMPLETE", actiondate: Date.now(), performed_by: Store.getStoreData('contact_id'), cur_lane: "COMP_BREACH_FOLLOWUP_COMPLETE", comments: this.state.comments, assigned_to: 0 };
        }else if(this.state.curlane === "COMP_BREACH_REMEDIAL_ASSIGNMENT"){
            task_json['task_data'].last_action = 'COMP_BREACH_REMEDIAL_COMPLETE';
            task_json['task_data'].cur_lane = 'COMP_BREACH_REMEDIAL_COMPLETE';
            task_json['task_data'].actiondate = Date.now();
            task_json['object_data'].commands = this.state.comments;
            task_json['action_data'] = {action: "COMP_BREACH_REMEDIAL_COMPLETE", actiondate: Date.now(), performed_by: Store.getStoreData('contact_id'), cur_lane: "COMP_BREACH_REMEDIAL_COMPLETE", comments: this.state.comments, assigned_to: 0 };
        }else if(this.state.curlane === "COMP_BREACH_PREVENTATIVE_ASSIGNMENT"){
            task_json['task_data'].last_action = 'COMP_BREACH_PREVENTATIVE_COMPLETE';
            task_json['task_data'].cur_lane = 'COMP_BREACH_PREVENTATIVE_COMPLETE';
            task_json['task_data'].actiondate = Date.now();
            task_json['object_data'].commands = this.state.comments;
            task_json['action_data'] = {action: "COMP_BREACH_PREVENTATIVE_COMPLETE", actiondate: Date.now(), performed_by: Store.getStoreData('contact_id'), cur_lane: "COMP_BREACH_PREVENTATIVE_COMPLETE", comments: this.state.comments, assigned_to: 0 };
        }
        let postData = {command: "update_breach_followup_task", task_json: task_json, task_id: this.state.task_id, bin_files: bin_files, parentTaskID: this.state.parentTaskID };
        console.log(postData);
        const api = new APICall();
        api.command(postData, this.afterCompleted);
    }
    afterCompleted = (result) => {
        // console.log("Completed:", result);
        let alert_param = {title: 'Success', message: 'Task completed successfully.', ok_text: 'Ok', confirm: false,
                           alertHandler: this.updateaftercompletesave, stack: {}}

        this.setState({alert_param: alert_param})
        //alert('Task completed successfully.');
        //window.location.reload(true);
    }

    updateaftercompletesave = (result, stack) => {
        console.log(result,stack)
        this.setState({alert_param: null})
        window.location.reload();
    }

    SaveSubTask = (event) => {
        event.preventDefault();
        let task_json = this.state.task;
        //console.log(task_json);
        let bin_files = this.state.cur_files_json === null ? this.state.binFiles : [...this.state.binFiles, ...this.state.cur_files_json];

        task_json['object_data'].commands = this.state.comments;

        let postData = {command: "update_breach_followup_task", task_json: task_json, task_id: this.state.task_id, bin_files: bin_files };
        console.log(postData);
        this.setState({isLoadedIcon: true})
        const api = new APICall();
        api.command(postData, this.aftersaveComplete);
    }
    aftersaveComplete = (result) => {
        // console.log("Completed:", result);
        //alert('Task saved successfully.');
        //window.location.reload(true);
        let alert_param = {title: 'Success', message: 'Task saved successfully.', ok_text: 'Ok', confirm: false,
                           alertHandler: this.updateaftercompletesave, stack: {}}

        this.setState({alert_param: alert_param, isLoadedIcon: false})
    }

    handleChange = (event) => {
        event.preventDefault();
        this.setState({[event.target.name]: event.target.value});
    }

    filesLoaded = (files) => {
        let contact_id = Store.getStoreData('contact_id');
        for(let i=0; i < files.length; i++) {
            files[i].name = files[i].name.replace('&', '');
            //console.log('File_name', files[i].name);
            if(!('user_id' in files[i])) {
                files[i].user_id = contact_id;
                files[i].upload_date = Date.now();
            }

        }
        //console.log('files in Modal', files);
        this.setState({binFiles: files});
    }

    render()
    {
        let curAssignTo = Store.getStoreData('followup_task').subtask.cur_assigned_to;
        let curUserID = Store.getStoreData('contact_id');
        let role = Store.getStoreData('role');
        //console.log("task_data===>", role)
        if(!this.state.ready){
            return <div> Loading.....</div>
        }
        return(
            <div>
            {
                (() => {
                    if(this.state.isLoadedIcon){
                        return <div><CSLLoader style={{position: 'absolute'}}/></div>
                    }
                })()
            }
            <MRModalContainer>
                <MRModalHeader>
                    <MRModalHeaderText>{this.state.task_title}</MRModalHeaderText>
                    <MRModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></MRModalHeaderCloseBtn>
                    <div style={{clear: "both"}}></div>
                    <MRModalSubHeaderText>Complete the form to submit the task.</MRModalSubHeaderText>
                    <div style={{clear: "both"}}></div>
                </MRModalHeader>
                <MRModalBody>
                    <div style={{clear: "both"}}></div>
                    <div style={{position: "relative",padding: "35px 35px"}}>
                        <div style={{height: "auto",backgroundColor: "#ffffff",borderRadius: "4px",padding: "16px",marginBottom: "25px",border: "1px solid #ECECEC"}}>
                            <MRModalLabel>Task Subject</MRModalLabel>
                            <MRModalLabel style={{fontWeight: "unset",fontSize: "13px"}}>{this.state.task_title}</MRModalLabel>
                            <MRModalLabel>Due Date</MRModalLabel>
                            <MRModalLabel style={{fontWeight: "unset",fontSize: "13px"}}>{this.state.dueDateValue}</MRModalLabel>
                            <MRModalLabel>Task Description</MRModalLabel>
                            <div dangerouslySetInnerHTML={{__html: this.state.task_note}} style={{textAlign: "justify",color: "#282828"}}></div>
                        </div>
                        <div style={{height: "auto",backgroundColor: "#ffffff",borderRadius: "4px",padding: "16px",marginBottom: "25px",border: "1px solid #ECECEC"}}>
                            <MRModalLabel>Incident Category</MRModalLabel>
                            <MRModalLabel style={{fontWeight: "unset",fontSize: "13px"}}>{this.state.payload.breach_type}</MRModalLabel>
                            <MRModalLabel>Incident Details</MRModalLabel>
                            <div dangerouslySetInnerHTML={{__html: this.state.payload.breach_description}} style={{textAlign: "justify",color: "#282828", fontFamily: "'Montserrat', sans-serif"}}></div>
                            <MRModalLabel>Reportability</MRModalLabel>
                            <MRModalLabel style={{fontWeight: "unset",fontSize: "13px"}}>{this.state.payload.breach_report}</MRModalLabel>
                            <MRModalLabel>Reported Date</MRModalLabel>
                            <MRModalLabel style={{fontWeight: "unset",fontSize: "13px"}}>{this.state.payload.reported_date}</MRModalLabel>
                            <MRModalLabel>Regulator</MRModalLabel>
                            <MRModalLabel style={{fontWeight: "unset",fontSize: "13px"}}>{this.state.payload.regulator_name}</MRModalLabel>
                        </div>
                        {
                            (() => {
                                if(curAssignTo === curUserID && this.state.curlane !== "COMP_BREACH_FOLLOWUP_COMPLETE"){
                                    return(<div>
                                        <MRModalLabel>Comments</MRModalLabel>
                                        <MRModalTextarea rows="6" name="comments" value={this.state.comments} onChange={this.handleChange} />
                                    </div>)
                                }else if(curAssignTo === curUserID && this.state.curlane !== "COMP_BREACH_REMEDIAL_COMPLETE"){
                                    return(<div>
                                        <MRModalLabel>Comments</MRModalLabel>
                                        <MRModalTextarea rows="6" name="comments" value={this.state.comments} onChange={this.handleChange} />
                                    </div>)
                                }else if(curAssignTo === curUserID && this.state.curlane !== "COMP_BREACH_PREVENTATIVE_COMPLETE"){
                                    return(<div>
                                        <MRModalLabel>Comments</MRModalLabel>
                                        <MRModalTextarea rows="6" name="comments" value={this.state.comments} onChange={this.handleChange} />
                                    </div>)
                                }else{
                                    return(<div>
                                        <MRModalLabel>Comments</MRModalLabel>
                                        <MRModalTextarea rows="6" name="comments" value={this.state.comments} readOnly/>
                                    </div>)
                                }
                            })()
                        }
                        <div>
                            <ModalNameLabelDiv>Attachments</ModalNameLabelDiv>
                            <ModalNameInputDiv>
                                <Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.cur_files_json} addnew={true}/>
                            </ModalNameInputDiv>
                        </div>


                    </div>
                </MRModalBody>
                <MRModalFooter>
                    <div style={{position: "relative",padding: "17px"}}>
                        {
                            (() => {
                                if(curAssignTo === curUserID && this.state.curlane !== "COMP_BREACH_FOLLOWUP_COMPLETE"){
                                    return(<div>
                                       <MRModalSaveCloseBtn onClick={this.UpdateSubTask}>Submit</MRModalSaveCloseBtn>

                                    </div>)
                                }else if(curAssignTo === curUserID && this.state.curlane !== "COMP_BREACH_REMEDIAL_COMPLETE"){
                                    return(<div>
                                        <MRModalSaveCloseBtn onClick={this.UpdateSubTask}>Submit</MRModalSaveCloseBtn>
                                    </div>)
                                }else if(curAssignTo === curUserID && this.state.curlane !== "COMP_BREACH_PREVENTATIVE_COMPLETE"){
                                    return(<div>
                                        <MRModalSaveCloseBtn onClick={this.UpdateSubTask}>Submit</MRModalSaveCloseBtn>
                                    </div>)
                                }
                            })()
                        }
                        <MRModalSaveCloseBtn onClick={this.SaveSubTask}>Save and Close</MRModalSaveCloseBtn>
                        <MRModalCancelBtn onClick={this.closeModal}>Cancel</MRModalCancelBtn>
                    </div>
                </MRModalFooter>
            </MRModalContainer>
            <AlertBox alertParam = {this.state.alert_param}/>
            </div>
        )
    }

}
export default TeamFollowupModal;
