import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store.js';
import BMBreachList from './BMBreachList';
import BMAskBreachModal from './BMAskBreachModal';

const SubmitButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #11aba6;
    color: #ffffff;
    width: 132px;
    height: 43px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 12px;
    font-size: 11px;
    padding-left: 12px;
    cursor: pointer;
    margin-top: 26px;
`;

const IndexContainer = styled.div`
    padding-top: 8px;
    padding-left: 5px;
`;

const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 210%;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: -2%;
  top: -46px;
  z-index: 1000;
  opacity: 0.8;
`;


class BMIndex extends React.Component
{
    state = {        
        showModal: null
    };
    constructor(props) {
        super(props);
    }   

    showBreachModal = (row) => (event) => {       
        event.preventDefault();
        let index = row.index === null ? 0 : row.index;        
        let q_index = index;
        Store.updateStore('q_index', q_index);
        this.setState({showModal: "BMAskBreachModal"})
    }
    
    closeModal = (event) => {
        //event.preventDefault();
        this.setState({
            showModal: null
        })
    }

    render()
    {
        let task_id = this.props.taskId !== 0 ? this.props.taskId : 0;
        console.log("task_id2===>",task_id)        
        return (
            <IndexContainer>
                {
                    (() => {
                        if(task_id === 0) {
                            return(<div style={{width:"100%",height: "100px", padding: "2px 2px 2px 2px",backgroundColor: "#e7f0f7", boxSizing: "border-box"}}>
                                <SubmitButton onClick={this.showBreachModal({index: -1})}>ADD INCIDENT</SubmitButton>
                            </div>)
                        }
                    })()
                }  
                <div style={{display: "block",height: "auto", paddingLeft: "30px",paddingRight: "30px"}}>
                    <BMBreachList taskId={task_id} />
                </div>
                {
                (() => {
                    if (this.state.showModal !== null) {
                        switch(this.state.showModal) {
                            case 'BMAskBreachModal' : return <div style={{position:'absolute', left:'2%', top:'50px', width:'100%', height:'600px'}}><InactiveOverlay style={{height: "150%"}}/><BMAskBreachModal closeModal={this.closeModal} /></div>; break;
                        }
                    }
                })()
                }
            </IndexContainer>
        );
    }
}
export default BMIndex;