import React from 'react';
import {FaTimes, FaTrashAlt, FaQuestionCircle} from 'react-icons/fa';
import styled from 'styled-components';
import { IoIosCheckboxOutline, IoIosSquareOutline, IoIosWarning } from "react-icons/io";
import AlertBox from '../Common/AlertBox';
import Toggle from 'react-toggle';
import "./ReactToggle.css";
import Utils from '../../Common/Utils';
import CslSimpleDropDown from './CslSimpleDropDown';

const EmailContainer = styled.div`
	width: 596px;
	//height:700px;
	top:75px;
	position: absolute;
	background-color: #f6f9fd;
    border-radius: 2px;
    box-shadow: 0 0 20px #a7a6a6;
	z-index: 1005;
	padding: 0px;
`;
const EmailInput = styled.input`
    height: 30px;
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #222222;
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
`;
const EmailTextArea = styled.textarea`
    height: 150px;
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #222222;
    font-family: 'Montserrat', sans-serif;
`;
const VariablePill = styled.div`
	padding: 10px 15px;
	background-color: #ffffff;
	color: #222222;
	font-weight: 600;
	border: 1px solid #222222;
	display: inline-block;
	margin-left: 5px;
	margin-right: 5px;
	margin-top: 10px;
	border-radius: 2em;
	cursor: pointer;
`;
const CancelBtn = styled.button`
    padding: 10px 30px;
	background-color: #ffffff;
	border: 1px solid #EBEBEB;
	border-radius: 2px;
	color: #282828;
	cursor: pointer;	
`;
const SubmitBtn = styled.button`
	background-color: #37ADA7;
	border: 1px solid #37ADA7;
	border-radius: 4px;
	padding: 10px 30px;
	cursor: pointer;
	color: #ffffff;
`;
const MRModalLabel = styled.div`
    font-family: "Roboto-Medium", "Roboto Medium", "Roboto", sans-serif;
    font-weight: 600;
    color: #1a3552;
    margin-top: 10px;
    margin-bottom: 10px;
`;
const MRModalInput = styled.input`
    height: 25px;
    width: 95%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    padding-right: 10px;
    padding-left: 10px;
    color: #000000;
`;
const MRModalHeader = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 40px 40px 15px 40px;
`;
const MRModalHeaderText = styled.div`
    box-sizing: border-box;
    float: left;
    color: #1b3452
    font-weight: 600;
    font-size: 20px;
    font-family: "Roboto-Bold", "Roboto Bold", "Roboto", sans-serif;
    font-weight: 700;
`;
const MRModalHeaderCloseBtn = styled.div`
    box-sizing: border-box;
    float: right;
    font-size: 16px;
    color: #1b3452
    cursor: pointer;
    font-size: 20px;
`;
const MRModalBody = styled.div`
    display: block;
    box-sizing: border-box;
    padding: 10px 40px 0px 40px;
    height:474px;
`;
const MRModalSelect = styled.select`
    display: block;
    box-sizing: border-box;
    height: 40px;
    width: 70%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #727272;
    margin-top: 10px;
    margin-bottom: 10px;
`;
const SaveBtn = styled.button`
	padding: 10px 30px;
	background-color: #37ADA7;
	border: 1px solid #37ADA7;
	border-radius: 2px;
	color: #ffffff;
	cursor: pointer;
	margin-left: 15px;
`; 

const DocFooter = styled.div`
    position: relative;
    background-color: white;
    border-style: solid;
    border-width: 1px;
    border-color: #e9ecef;
    height: 79px;
    width: 100%;
    margin-left: 0px;
    border-bottom-radius: 2px;
`;
const FooterButtonsContainer = styled.div`
	text-align: right;
	padding-top: 25px;
	width: 95%;
`; 

const SubHeading = styled.div`
	font-style: italic;
	font-size: 12px;
	color: #adacac;
	margin-top: 5px;
`;
const DropdownOptions = styled.textarea`
	border: 1px solid #ffffff;
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 0 4px #c4c4c4;
    resize: none;
    padding: 5px;
    width: 98%;
    font-family: 'Montserrat',sans-serif;
    margin-top: 7px;
`;
const ScoreBodyContainer = styled.div`
  padding: 10px;
  text-align: left;
  overflow-y: scroll;
  height: 555px;
`;

class CustomFieldsConfigModal extends React.Component
{
	state = {ready: false,alert_param: null, 
        name: null, 
        nickname: "",
        type: "Alpha",
        dropdown_options: [],
        required: false,
        other: false,
        isActive: 1,
        isDelete: 0,
        displayin: "All Records",
        custom_fields: null,
        inner_data: null,
        display_types: ["All Records", "Incident Record only", "Conduct Breach only", "Manager Screen Only"],
        dropdown_options_new: ""        
    };

	componentDidMount()
	{        
        if(this.props.curid !== 0) {
            let dropdown_options_new = "";
            if (this.props.content.dropdown_options.length !== 0) {
                this.props.content.dropdown_options.forEach((item, index) => {
                    if (index !== (this.props.content.dropdown_options.length - 1)) {
                        dropdown_options_new = dropdown_options_new + item + "\n";
                    } else {
                        dropdown_options_new = dropdown_options_new + item;
                    }
                })
            }
            this.setState({ready:true,
                dropdown_options_new, 
                name: this.props.content.name,
                nickname: this.props.content.nickname,
                type: this.props.content.type,
                dropdown_options: this.props.content.dropdown_options,
                required: this.props.content.required,
                other: this.props.content.other,
                isActive: this.props.content.isActive,
                isDelete: this.props.content.isDelete,
                displayin: this.props.content.displayin,
                custom_fields: this.props.data
            })    
        }else{ 
            const randtxt = Utils.genKey(10);            
            this.setState({name: randtxt, ready:true, custom_fields: this.props.data});            
        }
	}

	closeModal = (event) => {
		event.preventDefault();
		this.props.closeModal();
    }
    
    handleChange = (event) => {
        event.preventDefault();
        this.setState({[event.target.name]: event.target.value});
    }

    submitCustom_validation = (event) => {
        event.preventDefault();
        let problems = false
        let message = ''
        if(this.state.nickname === "")
        {
            problems = true
            message += 'Please provide a custom field name.'
        }
        if(problems) {
            let alert_param = {title: 'ERROR', message: message, ok_text: 'Ok', confirm: false, 
                           alertHandler: this.checkvalidation, stack: {}}

            this.setState({alert_param: alert_param})            
        } else {
            this.checkvalidation(false, {})
        }
    } 

    checkvalidation = (result, stack) => {        
        this.setState({alert_param: null})
        if(!result){
            console.log("Sourav")
            this.submitCustomField()
        }        
    }

    submitCustomField = () => {
        //event.preventDefault();   
        let breachObject = {}
        breachObject.name = this.state.name;
        breachObject.nickname = this.state.nickname;            
        breachObject.type = this.state.type;            
        breachObject.dropdown_options = this.state.dropdown_options;            
        breachObject.required = this.state.required;            
        breachObject.other = this.state.other;            
        breachObject.displayin = this.state.displayin;            
        breachObject.isActive = parseInt(this.state.isActive);
        breachObject.isDelete = parseInt(this.state.isDelete);         
        
        console.log("breachObject", breachObject)
        if(this.props.curid !== 0) {
            this.props.updateCustomFieldValue(breachObject);
        }else{                
            this.props.saveCustomFieldValue(breachObject);
        }
    }

    handleToggleChange = (arg)=>{
		let {isActive} = this.state;
		isActive = isActive === 1 ? 0 : 1;		
		this.setState({isActive})
	}
    handleRequiredToggleChange = (arg)=>{
		let {required} = this.state;
		required = required ? false : true;		
		this.setState({required})
	}

    changeFieldType = (selected_type) => {		
        this.setState({type: selected_type})
	}
    changeDisplayFieldType = (selected_type) => {		
        this.setState({displayin: selected_type})
	}

    modifyDropdownOptions = (event) => {
		event.preventDefault();
		let custom_fields = JSON.parse(JSON.stringify(this.state.custom_fields));
		
        const trimedvalue = event.target.value;
        let optarr = trimedvalue.split("\n");
        if (optarr.length === 1 && optarr[0] === "") {
            optarr = [];
        }
        let dropdown_options = optarr;
			
		
		let dropdown_options_new = this.state.dropdown_options;
		dropdown_options_new = event.target.value;
		this.setState({dropdown_options_new, dropdown_options});		
	}	

	render()
	{
		if (!this.state.ready) {
			return (<div>Loading...</div>);
		}
		return (
            <div>
		
		    <EmailContainer id="root_cause">
                <MRModalHeader>
                    <MRModalHeaderText>{this.props.headerText} Custom Field</MRModalHeaderText>
                    <MRModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></MRModalHeaderCloseBtn>
                    <div style={{clear: "both"}}></div>                
                </MRModalHeader>    
                <ScoreBodyContainer>        
                <MRModalBody>
                    <MRModalLabel style={{display: "block"}}>CUSTOM FIELD NAME <span style={{marginLeft: "5px",color: "red"}}>*</span></MRModalLabel>
                    <MRModalInput name="nickname" value={this.state.nickname} onChange={this.handleChange}/>

                    <MRModalLabel style={{marginTop: "30px",display: "block"}}>FIELD TYPE </MRModalLabel>                    
					<CslSimpleDropDown options={this.state.custom_fields.available_field_types} selected={this.state.type} changeFieldType={this.changeFieldType} />
                    {
                        (() => {
                            if (this.state.type === "Dropdown") {
                                return (
                                    <div style={{padding: "10px 0px"}}>
                                        <SubHeading>Configure the dropdown options. New line separates options.</SubHeading>
                                        <DropdownOptions rows="7" value={this.state.dropdown_options_new} onChange={this.modifyDropdownOptions} />
                                    </div>
                                );
                            }
                        })()
                    }
				    

                    <MRModalLabel style={{marginTop: "30px",display: "block"}}>DISPLAY</MRModalLabel>
                    <SubHeading style={{marginBottom: "5px"}}>Define which forms to should display this custom field.</SubHeading>
                    <CslSimpleDropDown options={this.state.display_types} selected={this.state.displayin} changeFieldType={this.changeDisplayFieldType} />

                    <MRModalLabel style={{marginTop: "30px",display: "block"}}>REQUIRED?</MRModalLabel>
                    <Toggle name="isActive"
					    defaultChecked={this.state.required ? true : false} 
					    icons={false}
					    onChange={()=> this.handleRequiredToggleChange('required')} />   

                    <MRModalLabel style={{marginTop: "30px",display: "block"}}>STATUS</MRModalLabel>
                    <Toggle name="isActive"
					    defaultChecked={this.state.isActive === 1 ? true : false} 
					    icons={false}
					    onChange={()=> this.handleToggleChange('isActive')} />                         
                </MRModalBody>
                </ScoreBodyContainer>                
                <div style={{clear: "both"}}></div> 
                <DocFooter style={{float: "right",marginTop: "30px"}}>
                        <FooterButtonsContainer>   
                        <CancelBtn onClick={this.closeModal}>Cancel</CancelBtn>
                        <SaveBtn onClick={this.submitCustom_validation}>Submit</SaveBtn>
                        </FooterButtonsContainer>   
                </DocFooter>
               
			</EmailContainer>
            <AlertBox alertParam = {this.state.alert_param}/>
            </div>
		);
	}
}

export default CustomFieldsConfigModal;