import React from 'react';
import Store from '../../Common/Store'
import styled from 'styled-components';
import {FaTimes, FaPlusCircle, FaLock} from 'react-icons/fa';
import {IoIosArrowForward, IoIosLock} from 'react-icons/io';
import General from './General';
import FinancialImpact from './FinancialImpact';
import APICall from '../../Common/APICall.js';
import Email from './Email';
import CustomFields from './CustomFields';
import ReasonForBreach from './ReasonForBreach';
import BreachImpact from './BreachImpact';
import RiskTriggers from './RiskTriggers';
import BusinessAreas from './BusinessAreas';
import Permissions from './Permissions';
import IncidentType from './IncidentType';
import Regulators from './Regulators';
import Reload from '../../Common/Reload.js';

import CSLLoader from '../Common/CSLLoader';
import AlertBox from '../Common/AlertBox';


const ReviewBodyContainer = styled.div`
    padding-top: 2px;
    width: 100%;
    background-color:#f6f9fd;
`;
const DashboardModalHeader = styled.div`
    border-width: 0px;
    background-color: white;
    border-left-width: 10px;
    border-style: solid;
    border-bottom-style: none;
    border-color: #11aba6;
    display: block;
    padding-top: 5px;
    font-size: 14px;
    font-weight: 700;
    height: 68px;
    color: #67788c;
    width: calc(100% - 10px);
`;
const ReviewLeftContainer = styled.div`
    float: left;
    padding-right: 0px;
    width: 20%;
    box-sizing: border-box;
`;
const ReviewRightContainer = styled.div`
    float: right;
    padding-left: 0px;
    width: 80%;
    box-sizing: border-box;
`;
const ReviewTab = styled.div`
    background-color: #ffffff;
    color: ${process.env.DEFAULT_TEXT_COLOR};
    padding: 17px 20px;
    cursor: pointer;
    font-family: Roboto-Medium, "Roboto Medium", Roboto, sans-serif;
    font-weight: 600;
`;
const CloseBtn = styled.div`
    float: right;
    font-size: 20px;
    color: #9c9c9c;
    cursor: pointer;
    padding-top: 20px;
    padding-right: 30px;

`;
const FooterButtonsContainer = styled.div`
	text-align: right;
	padding-top: 25px;
	//width: 100%;
	background-color:#fff;
	padding-bottom:25px;
`;
const CancelBtn = styled.button`
	padding: 10px 20px;
	background-color: #ffffff;
	border: 1px solid #ffffff;
	border-radius: 2px;
	color: #333333;
	cursor: pointer;
`;
const SaveBtn = styled.button`
	padding: 10px 30px;
	background-color: #37ADA7;
	border: 1px solid #37ADA7;
	border-radius: 2px;
	color: #ffffff;
	cursor: pointer;
	margin-left: 15px;
	margin-right:5%;
`;
const ErrorBar = styled.div`
    background-color: #F6DBDB;
    color: #DE8D8E;
    font-weight: 600;
    padding: 10px 20px;
`;
const ReviewTab_inactive = styled.div`
    background-color: #ffffff;
    color: #dedede;
    padding: 10px 20px;
`;
const ExportButton = styled.button`
    box-sizing: border-box;
    background-color: #213E61;
    color: #f6f9fd;
    border: 1px solid #213E61;
    width: 125px;
    font-size: 14px;
    height: 32px;
    border-radius: 5px;
    margin-right: 3px;
    float: left;
`;

class ModuleConfigHOC extends React.Component
{
	state = {
		active_section_id: "general",
		dirty: false,
        permissions: {},
		module_config: null,
        all_company_users: null,
        changed_user_roles: {},
		requester: "",
		standard_reason_for_breach: [],
		standard_breach_impact: [],
		standard_risk_triggers: [],
		standard_business_areas: [],
        ready: false,
        alert_param: null
	};
	constructor(props) {
        super(props);
        if (Store.getStoreData('role') === 'admin_manager') {
            const postData = { command: "list_company_and_users" };
            const api = new APICall();
            api.command(postData, this.getInitialPermission);
        }
    }

    getInitialPermission = (result) => {
        let requester = this.props.match ? this.props.match.params.requester : "";
        //console.log("requester:", requester);
        let contactlist = JSON.parse(localStorage.getItem('contactlist'));
        let user_roles = contactlist.result.user_roles['breach'];
        let cls = contactlist.result.contactlist;

        let company_users = [];
        // for(let key in cls){
        //     if(user_roles[key.toString()] !== undefined){
        //         let cu = {};
        //         cu['email'] = cls[key].EmailAddress;
        //         cu['user_id'] = key;
        //         cu['name'] = cls[key].ContactName;
        //         cu['role'] = key in user_roles ? user_roles[key] : 'no_access';
        //         company_users.push(cu)
        //     }
        // }
        for(let d of result.activeUsers){
            let cu = {};
            cu['email'] = d.EmailAddress;
            cu['user_id'] = d.ID;
            cu['name'] = d.ContactName;
            cu['role'] = d.ID in user_roles ? user_roles[d.ID] : 'no_access';
            company_users.push(cu)
        }
        console.log("company_users===>", company_users);
        console.log("result ===>", result);
        const permissions = {
            user_roles: user_roles,
            roles: result.roles,
            company_users: company_users

        };
        const all_company_users = JSON.parse(JSON.stringify(company_users));

        // console.log("result", result);
        // const permissions = {
        //     user_roles: result.user_roles,
        //     gc_companies: result.gc_companies,
        //     roles: result.roles,
        //     company_users: result.company_users
        // };
        // const all_company_users = JSON.parse(JSON.stringify(result.company_users));

		this.setState({permissions, all_company_users, module_config: result.moduleconfig, requester, standard_reason_for_breach: result.standard_reason_for_breach,
			standard_breach_impact: result.standard_breach_impact, standard_risk_triggers: result.standard_risk_triggers, standard_business_areas: result.standard_business_areas,ready: true});
    }

	returnSection = (section) => (event) => {
        event.preventDefault();
        console.log("section==>",section)
        // if (Store.getStoreData('role') === 'admin_manager') {
        //     const postData = { command: "list_company_and_users" };
        //     const api = new APICall();
        //     api.command(postData, this.getInitialPermission);
        // }
        this.setState({active_section_id: section});
    }

    //all function start
    updateCurrencyValue = (value) => {
        console.log("moduleemail",value)
        let {module_config} = this.state;
        module_config.general.companyCurrencyDetails.allowable_value_of_cost = value
        this.setState({module_config});
	}

    updateFinancialImpactValue = (value) => {
        console.log("moduleemail",value)
        let {module_config} = this.state;
        module_config.financial_impact.financial_impact_calculation_methodology = value
        this.setState({module_config});
	}

    updateFinancialImpactDate = (value) => {
        //console.log("moduleemail",value)
        let {module_config} = this.state;
        module_config.financial_impact.compensation_paid = value
        this.setState({module_config});
	}

	updateEmailRemainder = (obj) => {
        let {module_config, dirty} = this.state;
        console.log("old_config", this.state);
        const old_config = {...module_config};
        // console.log("old_config", old_config);
        module_config.general = obj;
        const new_config = {...module_config};
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterUpdateEmailRemainder);
    }
    afterUpdateEmailRemainder = (result) => {
        console.log(result);
       this.setState({module_config: result.result});
   	}

   	updateEmail = (obj) => {
		let {module_config} = this.state;
		module_config.email = obj;
		console.log("modulecong",module_config)
		console.log("moduleemail",obj)
		const postData = {command: "save_module_config", module_config: module_config};
		const api = new APICall();
		api.command(postData, this.afterUpdateEmailsection);
    }

    afterUpdateEmailsection = (result) => {
		console.log(result);
        //alert("Email modified successfully")
        let alert_param = {title: 'Success', message: 'Email modified successfully', ok_text: 'Ok', confirm: false,
                           alertHandler: this.updateafterallProcesscomplete, stack: {}}
		this.setState({module_config: result.result, alert_param: alert_param});
    }

    updatereason_for_breach = (obj) => {
		let {module_config} = this.state;
		module_config.reason_for_Breach = obj;
		const postData = {command: "save_module_config", module_config: module_config};
		const api = new APICall();
		api.command(postData, this.afterUpdateEmailRemainder);
    }

    updateAdditional_fields = (obj) => {
		let {module_config} = this.state;
		module_config.custom_fields.additional_fields = obj;
		const postData = {command: "save_module_config", module_config: module_config};
		const api = new APICall();
		api.command(postData, this.afterUpdateEmailRemainder);
    }

    updatebreach_impact = (obj) => {
		let {module_config} = this.state;
		module_config.breach_impact = obj;
		const postData = {command: "save_module_config", module_config: module_config};
		const api = new APICall();
		api.command(postData, this.afterUpdateEmailRemainder);
    }

    updaterisk_triggers_array = (obj) => {
		let {module_config} = this.state;
		module_config.risk_triggers = obj;
		const postData = {command: "save_module_config", module_config: module_config};
		const api = new APICall();
		api.command(postData, this.afterUpdateEmailRemainder);
    }

    updateshow_business_areas = (obj) => {
		let {module_config} = this.state;
		module_config.business_areas = obj;
		const postData = {command: "save_module_config", module_config: module_config};
		const api = new APICall();
		api.command(postData, this.afterUpdateEmailRemainder);
    }

    updateshow_incident_types = (obj) => {
		let {module_config} = this.state;
		module_config.incident_types = obj;
		const postData = {command: "save_module_config", module_config: module_config};
		const api = new APICall();
		api.command(postData, this.afterUpdateEmailRemainder);
    }

    updateshow_regulator_types = (obj) => {
		let {module_config} = this.state;
		module_config.regulator_types = obj;
		const postData = {command: "save_module_config", module_config: module_config};
		const api = new APICall();
		api.command(postData, this.afterUpdateEmailRemainder);
    }

    updateafterallProcesscomplete = (result, stack) => {
        console.log(result,stack)
        this.setState({alert_param: null})
    }

	updateCustomFields = (obj) => {
        let module_config = JSON.parse(JSON.stringify(this.state.module_config));
        module_config.custom_fields = obj;
        //this.setState({module_config});
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterUpdate);
	}

	changeActiveInactiveReasonforBreach = (obj) => {
        let {module_config} = this.state;
        module_config.general.reason_breach_active_inactive = obj;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterUpdateEmailRemainder);
	}

	changeActiveInactiveBreachImpact = (obj) => {
        let {module_config} = this.state;
        module_config.general.breach_impact_active_inactive = obj;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterUpdateEmailRemainder);
	}

	changeActiveInactiveRiskTriggers = (obj) => {
        let {module_config} = this.state;
        module_config.general.risk_triggers_active_inactive = obj;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterUpdateEmailRemainder);
	}

	changeActiveInactiveBusinessAreas = (obj) => {
        let {module_config} = this.state;
        module_config.general.business_areas_active_inactive = obj;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterUpdateEmailRemainder);
	}

	importResonForBreachValue = (value) => {
        console.log("object",value);
        let {module_config} = this.state;
        module_config.reason_for_Breach = value;
        module_config.general.standard_reason_breach_type_enable = false;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterimportResonforBreachValue);
   }

   afterimportResonforBreachValue = (result) => {
		console.log(result);
        //alert("Reason for breach imported successfully")
        let alert_param = {title: 'Success', message: 'Reason for breach imported successfully', ok_text: 'Ok', confirm: false,
                           alertHandler: this.updateafterallProcesscomplete, stack: {}}
		this.setState({module_config: result.result, alert_param: alert_param});
	}

	importBreachImpactValue = (value) => {
        console.log("object",value);
        let {module_config} = this.state;
        module_config.breach_impact = value;
        module_config.general.standard_breach_impact_type_enable = false;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterimportBreachImpactValue);
   }

   afterimportBreachImpactValue = (result) => {
		console.log(result);
        //alert("Breach impact imported successfully")
        let alert_param = {title: 'Success', message: 'Breach impact imported successfully', ok_text: 'Ok', confirm: false,
                           alertHandler: this.updateafterallProcesscomplete, stack: {}}
		this.setState({module_config: result.result, alert_param: alert_param});
	}

	importRiskTriggersValue = (value) => {
        console.log("object",value);
        let {module_config} = this.state;
        module_config.risk_triggers = value;
        module_config.general.standard_risk_triggers_type_enable = false;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterimportRiskTriggersValue);
   }

   afterimportRiskTriggersValue = (result) => {
		console.log(result);
        //alert("Risk Triggers imported successfully")
        let alert_param = {title: 'Success', message: 'Risk Triggers imported successfully', ok_text: 'Ok', confirm: false,
                           alertHandler: this.updateafterallProcesscomplete, stack: {}}
		this.setState({module_config: result.result, alert_param: alert_param});
	}

	importBusinessAreasValue = (value) => {
        console.log("object",value);
        let {module_config} = this.state;
        module_config.business_areas = value;
        module_config.general.standard_business_areas_type_enable = false;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterimportBusinessAreasValue);
   }

   afterimportBusinessAreasValue = (result) => {
		console.log(result);
        let alert_param = {title: 'Success', message: 'Department imported successfully', ok_text: 'Ok', confirm: false,
                           alertHandler: this.updateafterallProcesscomplete, stack: {}}
		this.setState({module_config: result.result, alert_param: alert_param});
	}

	deleteReasonForBreachValue = (arr) => {
        console.log("object",arr);
        let {module_config} = this.state;
        module_config.reason_for_Breach = arr;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterDeletereasonbreachValue);
    }
    afterDeletereasonbreachValue = (result) => {
        console.log(result);
        //alert("Reason for Breach deleted successfully")
        let alert_param = {title: 'Success', message: 'Root Cause deleted successfully', ok_text: 'Ok', confirm: false,
                           alertHandler: this.updateafterallProcesscomplete, stack: {}}
		this.setState({module_config: result.result, alert_param: alert_param});
	}

    deleteCustomFieldsValue = (arr) => {
        console.log("object",arr);
        let {module_config} = this.state;
        module_config.custom_fields.additional_fields = arr;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterDeleteCustomFieldsValue);
    }
    afterDeleteCustomFieldsValue = (result) => {
        console.log(result);
        //alert("Reason for Breach deleted successfully")
        let alert_param = {title: 'Success', message: 'Custom deleted successfully', ok_text: 'Ok', confirm: false,
                           alertHandler: this.updateafterallProcesscomplete, stack: {}}
		this.setState({module_config: result.result, alert_param: alert_param});
	}

	deleteBreachImpactValue = (arr) => {
        console.log("object",arr);
        let {module_config} = this.state;
        module_config.breach_impact = arr;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterDeleteBreachImpactValue);
    }
    afterDeleteBreachImpactValue = (result) => {
        console.log(result);
        //alert("Breach Impact deleted successfully")
        let alert_param = {title: 'Success', message: 'Incident Impact deleted successfully', ok_text: 'Ok', confirm: false,
                           alertHandler: this.updateafterallProcesscomplete, stack: {}}
		this.setState({module_config: result.result, alert_param: alert_param});
	}

	deleteRiskTriggersValue = (arr) => {
        console.log("object",arr);
        let {module_config} = this.state;
        module_config.risk_triggers = arr;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterDeleteRiskTriggersValue);
    }
    afterDeleteRiskTriggersValue = (result) => {
        console.log(result);
        //alert("Risk Trigger deleted successfully")
        let alert_param = {title: 'Success', message: 'Risk Category deleted successfully', ok_text: 'Ok', confirm: false,
                           alertHandler: this.updateafterallProcesscomplete, stack: {}}
		this.setState({module_config: result.result, alert_param: alert_param});
	}

	deleteBusinessAreasValue = (arr) => {
        console.log("object",arr);
        let {module_config} = this.state;
        module_config.business_areas = arr;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterDeleteBusinessAreasValue);
    }
    afterDeleteBusinessAreasValue = (result) => {
        console.log(result);
        let alert_param = {title: 'Success', message: 'Department deleted successfully', ok_text: 'Ok', confirm: false,
                           alertHandler: this.updateafterallProcesscomplete, stack: {}}
		this.setState({module_config: result.result, alert_param: alert_param});
	}

    deleteIncidentTypesValue = (arr) => {
        console.log("object",arr);
        let {module_config} = this.state;
        module_config.incident_types = arr;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterDeleteIncidentTypesValue);
    }
    afterDeleteIncidentTypesValue = (result) => {
        console.log(result);
        let alert_param = {title: 'Success', message: 'Incident Type deleted successfully', ok_text: 'Ok', confirm: false,
                           alertHandler: this.updateafterallProcesscomplete, stack: {}}
		this.setState({module_config: result.result, alert_param: alert_param});
	}

    deleteRegulatorTypesValue = (arr) => {
        console.log("object",arr);
        let {module_config} = this.state;
        module_config.regulator_types = arr;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterDeleteRegulatorTypesValue);
    }
    afterDeleteRegulatorTypesValue = (result) => {
        console.log(result);
        let alert_param = {title: 'Success', message: 'Regulator deleted successfully', ok_text: 'Ok', confirm: false,
                           alertHandler: this.updateafterallProcesscomplete, stack: {}}
		this.setState({module_config: result.result, alert_param: alert_param});
	}

	updateReasonForBreachValue = (arr) => {
        console.log("object",arr);
        let {module_config} = this.state;
        module_config.reason_for_Breach = arr;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterUpdateReasonBreachValue);
    }
    afterUpdateReasonBreachValue = (result) => {
        console.log(result);
        //alert("Reason for Breach updated successfully")
        let alert_param = {title: 'Success', message: 'Root Cause updated successfully', ok_text: 'Ok', confirm: false,
                           alertHandler: this.updateafterallProcesscomplete, stack: {}}
		this.setState({module_config: result.result, alert_param: alert_param});
	}

    updateCustomFieldsValue = (arr) => {
        console.log("object",arr);
        let {module_config} = this.state;
        module_config.custom_fields.additional_fields = arr;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterUpdateCustomFieldsValue);
    }
    afterUpdateCustomFieldsValue = (result) => {
        console.log(result);
        //alert("Reason for Breach updated successfully")
        let alert_param = {title: 'Success', message: 'Custom updated successfully', ok_text: 'Ok', confirm: false,
                           alertHandler: this.updateafterallProcesscomplete, stack: {}}
		this.setState({module_config: result.result, alert_param: alert_param});
	}

	updateBreachImpactValue = (arr) => {
        console.log("object",arr);
        let {module_config} = this.state;
        module_config.breach_impact = arr;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterUpdateBreachImpactValue);
    }
    afterUpdateBreachImpactValue = (result) => {
        console.log(result);
        //alert("Breach Impact updated successfully")
        let alert_param = {title: 'Success', message: 'Incident Impact updated successfully', ok_text: 'Ok', confirm: false,
                           alertHandler: this.updateafterallProcesscomplete, stack: {}}
		this.setState({module_config: result.result, alert_param: alert_param});
	}

	updateRiskTriggersValue = (arr) => {
        console.log("object",arr);
        let {module_config} = this.state;
        module_config.risk_triggers = arr;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterUpdateRiskTriggersValue);
    }
    afterUpdateRiskTriggersValue = (result) => {
        console.log(result);
        //alert("Risk Trigger updated successfully")
        let alert_param = {title: 'Success', message: 'Risk Category updated successfully', ok_text: 'Ok', confirm: false,
                           alertHandler: this.updateafterallProcesscomplete, stack: {}}
		this.setState({module_config: result.result, alert_param: alert_param});
	}

	updateBusinessAreasValue = (arr) => {
        console.log("object",arr);
        let {module_config} = this.state;
        module_config.business_areas = arr;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterUpdateBusinessAreasValue);
    }
    afterUpdateBusinessAreasValue = (result) => {
        console.log(result);
        let alert_param = {title: 'Success', message: 'Department updated successfully', ok_text: 'Ok', confirm: false,
                           alertHandler: this.updateafterallProcesscomplete, stack: {}}
		this.setState({module_config: result.result, alert_param: alert_param});
	}

    updateIncidentTypesValue = (arr) => {
        console.log("object",arr);
        let {module_config} = this.state;
        module_config.incident_types = arr;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterUpdateIncidentTypesValue);
    }
    afterUpdateIncidentTypesValue = (result) => {
        console.log(result);
        let alert_param = {title: 'Success', message: 'Incident Type updated successfully', ok_text: 'Ok', confirm: false,
                           alertHandler: this.updateafterallProcesscomplete, stack: {}}
		this.setState({module_config: result.result, alert_param: alert_param});
	}

    updateRegulatorTypesValue = (arr) => {
        console.log("object",arr);
        let {module_config} = this.state;
        module_config.regulator_types = arr;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterUpdateRegulatorTypesValue);
    }
    afterUpdateRegulatorTypesValue = (result) => {
        console.log(result);
        let alert_param = {title: 'Success', message: 'Regulator updated successfully', ok_text: 'Ok', confirm: false,
                           alertHandler: this.updateafterallProcesscomplete, stack: {}}
		this.setState({module_config: result.result, alert_param: alert_param});
	}

	saveReasonForBreachValue = (obj) => {
        console.log("object",obj);
        let {module_config} = this.state;
        module_config.reason_for_Breach.push(obj)
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afteraddReasonForBreachValue);
    }
    afteraddReasonForBreachValue = (result) => {
        console.log(result);
        //alert("Reason for Breach added successfully")
        let alert_param = {title: 'Success', message: 'Root Cause added successfully', ok_text: 'Ok', confirm: false,
                           alertHandler: this.updateafterallProcesscomplete, stack: {}}
		this.setState({module_config: result.result, alert_param: alert_param});
   }

   saveCustomFieldsValue = (obj) => {
        console.log("object",obj);
        let {module_config} = this.state;
        module_config.custom_fields.additional_fields.push(obj)
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afteraddCustomFieldValue);
    }
    afteraddCustomFieldValue = (result) => {
        console.log(result);
        let alert_param = {title: 'Success', message: 'Custom Field added successfully', ok_text: 'Ok', confirm: false,
                        alertHandler: this.updateafterallProcesscomplete, stack: {}}
        this.setState({module_config: result.result, alert_param: alert_param});
    }

   saveBreachImpactValue = (obj) => {
		console.log("object",obj);
		let {module_config} = this.state;
		module_config.breach_impact.push(obj)
		const postData = {command: "save_module_config", module_config: module_config};
		const api = new APICall();
		api.command(postData, this.afteraddBreachImpactValue);
	}
	afteraddBreachImpactValue = (result) => {
		console.log(result);
        //alert("Breach Impact added successfully")
        let alert_param = {title: 'Success', message: 'Incident Impact added successfully', ok_text: 'Ok', confirm: false,
                           alertHandler: this.updateafterallProcesscomplete, stack: {}}
		this.setState({module_config: result.result, alert_param: alert_param});
	}

	saveRiskTriggersValue = (obj) => {
		console.log("object",obj);
		let {module_config} = this.state;
		module_config.risk_triggers.push(obj)
		const postData = {command: "save_module_config", module_config: module_config};
		const api = new APICall();
		api.command(postData, this.afteraddRiskTriggersValue);
	}
	afteraddRiskTriggersValue = (result) => {
		console.log(result);
        //alert("Risk Trigger added successfully")
        let alert_param = {title: 'Success', message: 'Risk Category added successfully', ok_text: 'Ok', confirm: false,
                           alertHandler: this.updateafterallProcesscomplete, stack: {}}
		this.setState({module_config: result.result, alert_param: alert_param});
	}

	saveBusinessAreasValue = (obj) => {
		console.log("object",obj);
		let {module_config} = this.state;
		module_config.business_areas.push(obj)
		const postData = {command: "save_module_config", module_config: module_config};
		const api = new APICall();
		api.command(postData, this.afteraddBusinessAreasValue);
	}
	afteraddBusinessAreasValue = (result) => {
		console.log(result);
        let alert_param = {title: 'Success', message: 'Department added successfully', ok_text: 'Ok', confirm: false,
                           alertHandler: this.updateafterallProcesscomplete, stack: {}}
		this.setState({module_config: result.result, alert_param: alert_param});
	}

    saveIncidentTypesValue = (obj) => {
		console.log("object",obj);
		let {module_config} = this.state;
		module_config.incident_types.push(obj)
		const postData = {command: "save_module_config", module_config: module_config};
		const api = new APICall();
		api.command(postData, this.afteraddIncidentTypesValue);
	}
	afteraddIncidentTypesValue = (result) => {
		console.log(result);
        let alert_param = {title: 'Success', message: 'Incident Type added successfully', ok_text: 'Ok', confirm: false,
                           alertHandler: this.updateafterallProcesscomplete, stack: {}}
		this.setState({module_config: result.result, alert_param: alert_param});
	}

    saveRegulatorTypesValue = (obj) => {
		console.log("object",obj);
		let {module_config} = this.state;
		module_config.regulator_types.push(obj)
		const postData = {command: "save_module_config", module_config: module_config};
		const api = new APICall();
		api.command(postData, this.afteraddRegulatorTypesValue);
	}
	afteraddRegulatorTypesValue = (result) => {
		console.log(result);
        let alert_param = {title: 'Success', message: 'Regulator added successfully', ok_text: 'Ok', confirm: false,
                           alertHandler: this.updateafterallProcesscomplete, stack: {}}
		this.setState({module_config: result.result, alert_param: alert_param});
	}

	updatePermissions = (obj, single_obj) => {
        // console.log("single_obj:", single_obj);
        let changed_user_roles = JSON.parse(JSON.stringify(this.state.changed_user_roles));
        changed_user_roles[single_obj.user_id] = single_obj.role;
        // console.log("changed_user_roles:", changed_user_roles);
        this.setState({permissions: obj, changed_user_roles});
        // console.log("Updated Permission:", obj);
	}

	searchUsers = (search_string) => {
        if (search_string === "") {
            let permissions = JSON.parse(JSON.stringify(this.state.permissions));
            permissions.company_users = this.state.all_company_users;
            this.setState({permissions});
        } else {
            let all_company_users = JSON.parse(JSON.stringify(this.state.all_company_users));
            let filtered_company_users = [];
            all_company_users.forEach((user) => {
                if (user.name.toLowerCase().includes(search_string.toLowerCase()) || user.role.includes(search_string.toLowerCase())) {
                    filtered_company_users.push(user);
                }
            })
            let permissions = JSON.parse(JSON.stringify(this.state.permissions));
            permissions.company_users = filtered_company_users;
            // console.log("in search:", permissions.company_users);
            this.setState({permissions});
        }
    }

    changeAllRoles = (role) => {
        let permissions = JSON.parse(JSON.stringify(this.state.permissions));
        if (role !== "0") {
            permissions.company_users.forEach((user) => {
                user.role = role;
            })
        }else{
            permissions.company_users = this.state.all_company_users;
        }
        let changed_user_roles = {};
        permissions.company_users.forEach((user) => {
            changed_user_roles[user.user_id] = user.role;
        })
        // console.log("change all roles:", permissions.company_users);
        this.setState({permissions, changed_user_roles});
    }


    //all function end


    updateModuleconfig = (event) => {
        event.preventDefault();
        let {module_config, changed_user_roles} = this.state;
        console.log("modulecong",module_config)
        const api = new APICall();
        let postData = {command: "save_module_config", module_config: module_config };
        if (this.state.active_section_id === "permissions") {
            postData = {command: "save_user_role", changed_user_roles: changed_user_roles};
            api.command(postData, this.afterRoleUpdate);
            Reload.RestoreStorage(changed_user_roles);
        } else {
            api.command(postData, this.afterUpdate);
        }
    }

    afterRoleUpdate = (result) => {
        //alert(result.error_msg);
        let alert_param = {title: 'Success', message: result.error_msg, ok_text: 'Ok', confirm: false,
                           alertHandler: this.updateafterallProcesscomplete, stack: {}}
		this.setState({alert_param: alert_param});
    }
    afterUpdate = (result) => {
        console.log(result);
       //alert(result.error_msg);
       let alert_param = {title: 'Success', message: result.error_msg, ok_text: 'Ok', confirm: false,
                           alertHandler: this.updateafterallProcesscomplete, stack: {}}
		this.setState({module_config: result.result, alert_param: alert_param});
       // window.location.reload();
   }

   closePageCustomField = () => {
        //window.close();
        let url ="/";
        if (this.state.requester !== "") {
            const reqarr = this.state.requester.split("_");
            url = `/${reqarr[0]}/${reqarr[1]}`;
        }
        window.location.replace(url);
   }

    closePage = (event) => {
        event.preventDefault();
        let url = "/";
        if (this.state.requester !== "") {
            const reqarr = this.state.requester.split("_");
            url = `/${reqarr[1]}`;
        }
        window.location.replace(url);
        //window.close();
    }

    updateGeneral = (obj) => {
        let {module_config} = this.state;
        module_config.general = obj;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.aftergeneralUpdate);
    }
    aftergeneralUpdate = (result) => {
        this.setState({module_config: result.result});
    }

    updateFinancialImpact = (obj) => {
        let {module_config} = this.state;
        module_config.financial_impact = obj;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.aftergeneralUpdate);
    }

    render()
	{  if (Store.getStoreData('role') !== 'admin_manager') {
		return (
			<ErrorBar>
				<div style={{float: "left"}}>You are not authorised to access this page. If you think this is in error, please contact your administrator.</div>
				<div style={{float: "right", fontSize: "16px", cursor: "pointer"}} onClick={this.closePage}>x</div>
				<div style={{clear: "both"}}></div>
			</ErrorBar>
		);
	}
	if (this.state.module_config === null) {
		return <div><CSLLoader /></div>
    }
	return (
		<div style={{padding: "2px 2px 2px 2px"}}>
		<DashboardModalHeader>
			<CloseBtn onClick={this.closePage}><FaTimes /></CloseBtn>
			<div style={{clear: "both"}}></div></DashboardModalHeader>
			<ReviewBodyContainer>
				<ReviewLeftContainer style={{position: "sticky", top: "2px"}}>
					<ReviewTab onClick={this.returnSection("general")} style={{marginTop: "2px", backgroundColor:this.state.active_section_id === "general"?"#11aba6" : "#f6f9fd"}}>
						{
							(() => {
								if (this.state.active_section_id === "general") {
									return (<div style={{float: "left", fontWeight: "600", color:"#fff"}}>General</div>);
								} else {
									return (<div style={{float: "left",color: "#1a3552"}}>General</div>);
								}
							})()
						}
						<div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}></div>
						<div style={{clear: "both"}}></div>
					</ReviewTab>
					<ReviewTab onClick={this.returnSection("financial_impact")} style={{marginTop: "2px", backgroundColor:this.state.active_section_id === "financial_impact"?"#11aba6" : "#f6f9fd"}}>
						{
							(() => {
								if (this.state.active_section_id === "financial_impact") {
									return (<div style={{float: "left", fontWeight: "600", color:"#fff"}}>Financial Impact</div>);
								} else {
									return (<div style={{float: "left", color: "#1a3552"}}>Financial Impact</div>);
								}
							})()
						}
						<div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}></div>
						<div style={{clear: "both"}}></div>
					</ReviewTab>
                    <ReviewTab onClick={this.returnSection("email")} style={{marginTop: "2px", backgroundColor:this.state.active_section_id === "email"?"#11aba6" : "#f6f9fd"}}>
						{
							(() => {
								if (this.state.active_section_id === "email") {
									return (<div style={{float: "left", fontWeight: "600", color:"#fff"}}>Email</div>);
								} else {
									return (<div style={{float: "left", color: "#1a3552"}}>Email</div>);
								}
							})()
						}
						<div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}></div>
						<div style={{clear: "both"}}></div>
					</ReviewTab>
					<ReviewTab onClick={this.returnSection("custom_fields")} style={{marginTop: "2px", backgroundColor:this.state.active_section_id === "custom_fields"?"#11aba6" : "#f6f9fd"}}>
						{
							(() => {
								if (this.state.active_section_id === "custom_fields") {
									return (<div style={{float: "left", fontWeight: "600", color:"#fff"}}>Custom Fields</div>);
								} else {
									return (<div style={{float: "left", color: "#1a3552"}}>Custom Fields</div>);
								}
							})()
						}
						<div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}></div>
						<div style={{clear: "both"}}></div>
					</ReviewTab>
                    <ReviewTab onClick={this.returnSection("reason_for_breach")} style={{marginTop: "2px", backgroundColor:this.state.active_section_id === "reason_for_breach"?"#11aba6" : "#f6f9fd"}}>
						{
							(() => {
								if (this.state.active_section_id === "reason_for_breach") {
									return (<div style={{float: "left", fontWeight: "600", color:"#fff"}}>Root Cause</div>);
								} else {
									return (<div style={{float: "left", color: "#1a3552"}}>Root Cause</div>);
								}
							})()
						}
						<div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}></div>
						<div style={{clear: "both"}}></div>
					</ReviewTab>
                    <ReviewTab onClick={this.returnSection("breach_impact")} style={{marginTop: "2px", backgroundColor:this.state.active_section_id === "breach_impact"?"#11aba6" : "#f6f9fd"}}>
						{
							(() => {
								if (this.state.active_section_id === "breach_impact") {
									return (<div style={{float: "left", fontWeight: "600",color:"#fff"}}>Incident Impact</div>);
								} else {
									return (<div style={{float: "left", color: "#1a3552"}}>Incident Impact</div>);
								}
							})()
						}
						<div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}></div>
						<div style={{clear: "both"}}></div>
					</ReviewTab>
                    <ReviewTab onClick={this.returnSection("risk_triggers")} style={{marginTop: "2px", backgroundColor:this.state.active_section_id === "risk_triggers"?"#11aba6" : "#f6f9fd"}}>
						{
							(() => {
								if (this.state.active_section_id === "risk_triggers") {
									return (<div style={{float: "left", fontWeight: "600", color:"#fff"}}>Risk Categories</div>);
								} else {
									return (<div style={{float: "left", color: "#1a3552"}}>Risk Categories</div>);
								}
							})()
						}
						<div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}></div>
						<div style={{clear: "both"}}></div>
					</ReviewTab>
                    <ReviewTab onClick={this.returnSection("business_areas")} style={{marginTop: "2px", backgroundColor:this.state.active_section_id === "business_areas"?"#11aba6" : "#f6f9fd"}}>
						{
							(() => {
								if (this.state.active_section_id === "business_areas") {
									return (<div style={{float: "left", fontWeight: "600", color:"#fff"}}>Departments</div>);
								} else {
									return (<div style={{float: "left", color: "#1a3552"}}>Departments</div>);
								}
							})()
						}
						<div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}></div>
						<div style={{clear: "both"}}></div>
					</ReviewTab>
                    <ReviewTab onClick={this.returnSection("IncidentType")} style={{marginTop: "2px", backgroundColor:this.state.active_section_id === "IncidentType"?"#11aba6" : "#f6f9fd"}}>
						{
							(() => {
								if (this.state.active_section_id === "IncidentType") {
									return (<div style={{float: "left", fontWeight: "600", color:"#fff"}}>Incident Type</div>);
								} else {
									return (<div style={{float: "left", color: "#1a3552"}}>Incident Type</div>);
								}
							})()
						}
						<div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}></div>
						<div style={{clear: "both"}}></div>
					</ReviewTab>
                    <ReviewTab onClick={this.returnSection("Regulators")} style={{marginTop: "2px", backgroundColor:this.state.active_section_id === "Regulators"?"#11aba6" : "#f6f9fd"}}>
						{
							(() => {
								if (this.state.active_section_id === "Regulators") {
									return (<div style={{float: "left", fontWeight: "600", color:"#fff"}}>Regulators</div>);
								} else {
									return (<div style={{float: "left", color: "#1a3552"}}>Regulators</div>);
								}
							})()
						}
						<div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}></div>
						<div style={{clear: "both"}}></div>
					</ReviewTab>
					{/*<ReviewTab onClick={this.returnSection("permissions")} style={{marginTop: "2px", backgroundColor:this.state.active_section_id === "permissions"?"#11aba6" : "#f6f9fd"}}>*/}
					{/*	{*/}
					{/*		(() => {*/}
					{/*			if (this.state.active_section_id === "permissions") {*/}
					{/*				return (<div style={{float: "left", fontWeight: "600", color:"#fff"}}>Permissions</div>);*/}
					{/*			} else {*/}
					{/*				return (<div style={{float: "left", color: "#1a3552"}}>Permissions</div>);*/}
					{/*			}*/}
					{/*		})()*/}
					{/*	}*/}
					{/*	<div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}></div>*/}
					{/*	<div style={{clear: "both"}}></div>*/}
					{/*</ReviewTab>*/}
				</ReviewLeftContainer>
				<ReviewRightContainer>
					{
						(() => {
							if (this.state.active_section_id === "general") {
								return (<General general={JSON.parse(JSON.stringify(this.state.module_config.general))} updateCurrencyValue={this.updateCurrencyValue} updateGeneral={this.updateGeneral}/>);
							}
                            if (this.state.active_section_id === "financial_impact") {
								return (<FinancialImpact financial_impact={JSON.parse(JSON.stringify(this.state.module_config.financial_impact))} updateFinancialImpact={this.updateFinancialImpact} updateFinancialImpactValue={this.updateFinancialImpactValue} updateFinancialImpactDate={this.updateFinancialImpactDate}/>);
							}
							if (this.state.active_section_id === "email") {
                                return (<Email email={JSON.parse(JSON.stringify(this.state.module_config.email))} variables={this.state.module_config.variables} general={JSON.parse(JSON.stringify(this.state.module_config.general))} updateGeneralSave={this.updateEmailRemainder} updateEmail={this.updateEmail}/>);
							}
							if (this.state.active_section_id === "custom_fields") {
								return (<CustomFields custom_fields={this.state.module_config.custom_fields} updateCustomFields={this.updateCustomFields} closePage={this.closePageCustomField} saveCustomFieldsValue={this.saveCustomFieldsValue} deleteCustomFieldsValue={this.deleteCustomFieldsValue} updateCustomFieldsValue={this.updateCustomFieldsValue} updateAdditional_fields={this.updateAdditional_fields}/>);
							}
							if (this.state.active_section_id === "reason_for_breach") {
								return (<ReasonForBreach general={JSON.parse(JSON.stringify(this.state.module_config.general))} reason_for_Breach={JSON.parse(JSON.stringify(this.state.module_config.reason_for_Breach))} standard_reason_for_breach={this.state.standard_reason_for_breach} importResonForBreachValue={this.importResonForBreachValue} changeActiveInactive={this.changeActiveInactiveReasonforBreach} deleteReasonForBreachValue={this.deleteReasonForBreachValue} updateReasonForBreachValue={this.updateReasonForBreachValue} saveReasonForBreachValue={this.saveReasonForBreachValue} updatereason_for_breach={this.updatereason_for_breach}/>);
							}
							if (this.state.active_section_id === "breach_impact") {
								return (<BreachImpact general={JSON.parse(JSON.stringify(this.state.module_config.general))} breach_impact={JSON.parse(JSON.stringify(this.state.module_config.breach_impact))} standard_breach_impact={this.state.standard_breach_impact} importBreachImpactValue={this.importBreachImpactValue} changeActiveInactive={this.changeActiveInactiveBreachImpact} deleteBreachImpactValue={this.deleteBreachImpactValue} updateBreachImpactValue={this.updateBreachImpactValue} saveBreachImpactValue={this.saveBreachImpactValue} updatebreach_impact={this.updatebreach_impact}/>);
							}
							if (this.state.active_section_id === "risk_triggers") {
								return (<RiskTriggers general={JSON.parse(JSON.stringify(this.state.module_config.general))} risk_triggers={JSON.parse(JSON.stringify(this.state.module_config.risk_triggers))} standard_risk_triggers={this.state.standard_risk_triggers} importRiskTriggersValue={this.importRiskTriggersValue} changeActiveInactive={this.changeActiveInactiveRiskTriggers} deleteRiskTriggersValue={this.deleteRiskTriggersValue} updateRiskTriggersValue={this.updateRiskTriggersValue} saveRiskTriggersValue={this.saveRiskTriggersValue} updaterisk_triggers_array={this.updaterisk_triggers_array}/>);
							}
							if (this.state.active_section_id === "business_areas") {
								return (<BusinessAreas general={JSON.parse(JSON.stringify(this.state.module_config.general))} business_areas={JSON.parse(JSON.stringify(this.state.module_config.business_areas))} standard_business_areas={this.state.standard_business_areas} importBusinessAreasValue={this.importBusinessAreasValue} changeActiveInactive={this.changeActiveInactiveBusinessAreas} deleteBusinessAreasValue={this.deleteBusinessAreasValue} updateBusinessAreasValue={this.updateBusinessAreasValue} saveBusinessAreasValue={this.saveBusinessAreasValue} updateshow_business_areas={this.updateshow_business_areas}/>);
                            }
                            if (this.state.active_section_id === "IncidentType") {
								return (<IncidentType  general={JSON.parse(JSON.stringify(this.state.module_config.general))} incident_types={JSON.parse(JSON.stringify(this.state.module_config.incident_types))} deleteIncidentTypesValue={this.deleteIncidentTypesValue} updateshow_incident_types={this.updateshow_incident_types} saveIncidentTypesValue={this.saveIncidentTypesValue} updateIncidentTypesValue={this.updateIncidentTypesValue}/>);
							}
                            if (this.state.active_section_id === "Regulators") {
								return (<Regulators  general={JSON.parse(JSON.stringify(this.state.module_config.general))} regulator_types={JSON.parse(JSON.stringify(this.state.module_config.regulator_types))} updateshow_regulator_types={this.updateshow_regulator_types} deleteRegulatorTypesValue={this.deleteRegulatorTypesValue} saveRegulatorTypesValue={this.saveRegulatorTypesValue} updateRegulatorTypesValue={this.updateRegulatorTypesValue}/>);
							}
							if (this.state.active_section_id === "permissions") {
								return (<Permissions permissions={JSON.parse(JSON.stringify(this.state.permissions))} updatePermissions={this.updatePermissions} searchUsers={this.searchUsers} changeAllRoles={this.changeAllRoles} />);
							}
						})()
					}
                    {
                        (() => {
                            if (this.state.active_section_id !== "general" && this.state.active_section_id !== "financial_impact"&& this.state.active_section_id !== "email" && this.state.active_section_id !== "reason_for_breach" && this.state.active_section_id !== "breach_impact" && this.state.active_section_id !== "risk_triggers" && this.state.active_section_id !== "business_areas" && this.state.active_section_id !== "custom_fields" && this.state.active_section_id !== "IncidentType") {
                                return (
                                    <FooterButtonsContainer>
                                        <CancelBtn onClick={this.closePage}>Cancel</CancelBtn>
                                        <SaveBtn onClick={this.updateModuleconfig}>Save</SaveBtn>
                                    </FooterButtonsContainer>
                                )
                            }
                        })()
                    }


				</ReviewRightContainer>
				<div style={{clear: "both"}}></div>
			</ReviewBodyContainer>
            <AlertBox alertParam = {this.state.alert_param}/>
		</div>
	);
}
}

export default ModuleConfigHOC;
