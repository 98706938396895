import React from 'react';
import styled from 'styled-components';
import { FaEdit,FaPencilAlt, FaTimes,FaPlusCircle } from "react-icons/fa";
import { IoIosCheckboxOutline, IoIosSquareOutline, IoIosWarning } from "react-icons/io";
import Toggle from 'react-toggle';
import "./ReactToggle.css";
import CslSimpleDropDown from './CslSimpleDropDown';
import Store from '../../Common/Store';
import CSLLoader from './../Common/CSLLoader';

const SelectedRadio = styled.div`
	background-color: #37ADA7;
	border: 1px solid #1f615e;
	padding: 5px 10px;
	color: #ffffff;
	display: inline-block;
	cursor: pointer;
`;
const UnselectedRadio = styled.div`
	background-color: #F4F4F4;
	border: 1px solid #8c8c8c;
	padding: 5px 10px;
	color: #262626;
	display: inline-block;
	cursor: pointer;
`;
const GeneralContainer = styled.div`
	width: 100%;
`;
const GeneralInnerContainer = styled.div`
	background-color: #ffffff;
	padding: 30px;
`;
const GeneralLabel = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 20px;
`;
const RadioBlock = styled.div`
	margin-top: 10px;
`;
const SearchInput = styled.input`
    height: 30px;
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #222222;
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
`;
const MRModalLabel = styled.div`
	color: #1a3552;
	font-family: "DMSans-Medium", "DM Sans Medium", "DM Sans", sans-serif;
	font-weight: 600;
	margin-top: 10px;
	display: inline-block;
`;
const MRModalSubLabel = styled.div`
	color: #838c91;
	font-weight: 500;
	margin-top: 10px;
	display: inline-block;
`;

const MRModalHeaderLabel = styled.div`
  
  font-family: "Roboto-Bold", "Roboto Bold", "Roboto", sans-serif;
  font-weight: 900;
  color: #1b3452;
  text-align: left;
   margin-top: 10px;
`;

const EmailContainer = styled.div`
	width: 100%;
`;
const EmailInnerContainer = styled.div`
	background-color: #ffffff;
	padding: 18px;
`;
const EmailBoldLabel = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 5px;
`;
const MRModalInput = styled.input`
    height: 25px;
    width: 99%;
    box-shadow: rgb(196, 196, 196) 0px 0px 4px;
    color: #545454;
    font-family: montserrat;
    font-size: 12px;
    font-weight: 600;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(255, 255, 255);
    border-image: initial;
    border-radius: 3px;
    padding: 6px;
`;
const MRModalTextarea = styled.textarea`
    width: 99%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #545454;
	font-family: 'Montserrat', sans-serif;
	font-size: 12px;
    font-weight: 600;
`;

const SubHeading = styled.div`
	font-style: italic;
	font-size: 12px;
	color: #adacac;
	margin-top: 5px;
`;
const DropdownOptions = styled.textarea`
	border: 1px solid #ffffff;
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 0 4px #c4c4c4;
    resize: none;
    padding: 5px;
    width: 98%;
    font-family: 'Montserrat',sans-serif;
    margin-top: 7px;
`;


const MRModalSelect = styled.select`
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #444444;    
    padding: 10px;
    font-weight: 600;
`;

class General extends React.Component
{
	state = {general: null,currency: "",minvalue: 0, isLoadedIcon: false};

	componentDidMount()
	{
		this.setState({general: this.props.general,currency: this.props.general.companyCurrencyDetails.currency,minvalue:this.props.general.companyCurrencyDetails.allowable_value_of_cost})
	}

	componentDidUpdate(prevProps)
	{
		if (prevProps !== this.props) {
			this.setState({general: this.props.general,currency: this.props.general.companyCurrencyDetails.currency,minvalue:this.props.general.companyCurrencyDetails.allowable_value_of_cost, isLoadedIcon: false})			
		}
	}

	handleChange = (evt) => {    
		this.props.updateCurrencyValue(evt.target.value)		
	}
	
	handleToggleChange = (arg)=>{
		let {general} = this.state;
		general[arg] = general[arg] ? false : true;		
		this.setState({general, isLoadedIcon: true})	 
		this.props.updateGeneral(general);
	}
	
	modifyDropdownOptions = (key , e)=>{	
		let {general} = this.state;
		general[key] = e.target.value;	
		this.setState({general, isLoadedIcon: true})	 
		this.props.updateGeneral(general);
	}
	

	render()
	{
		if (this.state.general === null) {
			return (<div>Loading...</div>);
		}

		return (
			<div>	
			{
				(() => {
					if(this.state.isLoadedIcon){
						return <div><CSLLoader style={{position: 'absolute'}}/></div>
					}
				})()
			}		
			<GeneralContainer>
				<GeneralInnerContainer>
					{
						(() => {
							if(Store.getStoreData('has_gc')){
								return(
									<div style={{marginBottom:"10px", display: "block"}}>
										<MRModalLabel style={{display: "block"}}>Show Parent Company?</MRModalLabel>
										<MRModalSubLabel style={{marginTop: "10px",marginBottom:"10px", display: "block"}}>This option allows Incidents to be reported at the Parent Company level.</MRModalSubLabel>
										<Toggle					    
											icons={false}
											onChange={()=> this.handleToggleChange('show_parent_company_breach')}
											defaultChecked={this.state.general.show_parent_company_breach ? true : false}
										/>
									</div>
								)
							}
							// else{
							// 	return(
							// 		<div style={{marginBottom:"10px", display: "block"}}>
							// 			<MRModalLabel style={{display: "block", color: "#1d3957a8"}}>Show Parent Company?</MRModalLabel>
							// 			<MRModalSubLabel style={{marginTop: "10px",marginBottom:"10px", display: "block"}}>This option allows Incidents to be reported at the Parent Company level.</MRModalSubLabel>
							// 			<Toggle					    
							// 				icons={false}											
							// 				defaultChecked={this.state.general.show_parent_company_breach ? true : false} 
							// 				disabled
							// 			/>
							// 		</div>
							// 	)
							// }
						})()
					}
					
				    <div style={{marginBottom:"10px", display: "block"}}>
						<MRModalLabel style={{display: "block"}}>Allow Completion of Incidents with Outstanding Tasks?</MRModalLabel>
						<MRModalSubLabel style={{marginTop: "10px",marginBottom:"10px",display: "block"}}>This option allows Incidents to be closed even though they have open Remedial Actions, Preventative Measures or Follow-up tasks.</MRModalSubLabel>
						<Toggle
					    defaultChecked={this.state.general.complete_incidencewith_outstanding_tasks ? true : false} 
					    icons={false}
					    onChange={()=> this.handleToggleChange('complete_incidencewith_outstanding_tasks')} />
				    </div>
				    <div>
				    	<MRModalHeaderLabel style={{marginTop: "20px",marginBottom:"10px", display: "block"}}>Regulator Reporting</MRModalHeaderLabel>
				    	<MRModalSubLabel style={{marginTop: "10px",marginBottom:"10px",display: "block"}}>Chose whether to include the following fields when reporting an incident to a Regulator.</MRModalSubLabel>
						
				    </div>
				    
				    <div style={{marginBottom:"10px", display: "block"}}>
						<MRModalLabel style={{display: "block"}}>Trading Error</MRModalLabel>
						<MRModalSubLabel style={{marginTop: "10px",marginBottom:"10px",display: "block"}}>Choose whether reportable Incidents can be recorded as being the result of a trading error.</MRModalSubLabel>
						<Toggle
					    defaultChecked={this.state.general.reporting_trading_err ? true : false} 
					    icons={false}
					    onChange={()=> this.handleToggleChange('reporting_trading_err')} />
				    </div>
				    
				    <div style={{marginBottom:"10px", display: "block"}}>
						<MRModalLabel style={{display: "block"}}>Fund/Portfolio/Product Name (Single line description)</MRModalLabel>
						<MRModalSubLabel style={{marginTop: "10px",marginBottom:"10px",display: "block"}}>Choose whether reportable Incidents can record the name of the fund, portfolio or product.</MRModalSubLabel>
						<Toggle
					     defaultChecked={this.state.general.reporting_fund_portfolio_product_name ? true : false} 
					    icons={false}
					    onChange={()=> this.handleToggleChange('reporting_fund_portfolio_product_name')} />
				    </div>
				    
				    <div>
				    	<MRModalHeaderLabel style={{marginTop: "20px",marginBottom:"10px", display: "block"}}>Customise Form Options</MRModalHeaderLabel>
				    	<MRModalSubLabel style={{marginTop: "10px",marginBottom:"10px",display: "block"}}>Chose whether to include the following fields and where they should appear</MRModalSubLabel>
						
				    </div>
				    
				    <div style={{display:"flex", width:"100%"}}>
				    <div style={{float:"left", width:"45%"}}>
						<MRModalLabel style={{marginTop: "20px",display: "block",marginBottom: "10px"}}>Root Cause</MRModalLabel> 					
						<div style={{width: "80%"}}>
							<MRModalSelect id="form_customisation_root_cause" name="form_customisation_root_cause" onChange={(e)=> this.modifyDropdownOptions('form_customisation_root_cause' , e)} value={this.state.general.form_customisation_root_cause} >                            					
								<option value=''>Select...</option>
								<option value='do_not_show'>Don't Show</option>
								<option value='show_on_entry_form'>Show on Entry Form</option>
								<option value='show_on_manager_form'>Show on Manager Form</option>	
							</MRModalSelect>	
						</div>						
						<MRModalLabel style={{marginTop: "20px",display: "block",marginBottom: "10px"}}>Incident Impact</MRModalLabel> 					
						<div style={{width: "80%"}}>
						<MRModalSelect id="form_customisation_incident_impact" name="form_customisation_incident_impact" onChange={(e)=> this.modifyDropdownOptions('form_customisation_incident_impact' , e)} value={this.state.general.form_customisation_incident_impact} >
							<option value=''>Select...</option>
							<option value='do_not_show'>Don't Show</option>
							<option value='show_on_entry_form'>Show on Entry Form</option>
							<option value='show_on_manager_form'>Show on Manager Form</option>
                        </MRModalSelect>						
						</div>						
				    </div>
				    <div style={{float:"right", width:"45%"}}>
						<MRModalLabel style={{marginTop: "20px",display: "block",marginBottom: "10px"}}>Resolution Owner</MRModalLabel> 					
						<div style={{width: "80%"}}>
							<MRModalSelect id="form_customisation_resolution_owner" name="form_customisation_resolution_owner" onChange={(e)=> this.modifyDropdownOptions('form_customisation_resolution_owner' , e)} value={this.state.general.form_customisation_resolution_owner} >
								<option value=''>Select...</option>
								<option value='do_not_show'>Don't Show</option>
								<option value='show_on_entry_form'>Show on Entry Form</option>
								<option value='show_on_manager_form'>Show on Manager Form</option>	
							</MRModalSelect>				
						</div>
							<MRModalLabel style={{marginTop: "20px",display: "block",marginBottom: "10px"}}>Risk Category</MRModalLabel> 					
						<div style={{width: "80%"}}>
						
						<MRModalSelect id="form_customisation_risk_category" name="form_customisation_risk_category" onChange={(e)=> this.modifyDropdownOptions('form_customisation_risk_category' , e)} value={this.state.general.form_customisation_risk_category} >
							<option value=''>Select...</option>
							<option value='do_not_show'>Don't Show</option>
							<option value='show_on_entry_form'>Show on Entry Form</option>
							<option value='show_on_manager_form'>Show on Manager Form</option>	
                        </MRModalSelect>
                        
					
						</div>
					
				    </div>
				    </div>				    
				     
				</GeneralInnerContainer>												
			</GeneralContainer>
			</div>
		);
	}
}

export default General;