import React from 'react';
import styled from 'styled-components';
import CSLTable from '../Common/MCCTable';
import { FaPencilAlt,FaEdit, FaTimes,FaPlusCircle, FaEllipsisV } from "react-icons/fa";
import { IoIosCheckboxOutline, IoIosSquareOutline, IoIosWarning } from "react-icons/io";
import BreachImpactConfigModal from './BreachImpactConfigModal';
import Store from '../../Common/Store'
import AlertBox from '../Common/AlertBox';
import Toggle from 'react-toggle';
import "./ReactToggle.css";
import VerticalEllipsisMenuFolderReasonBreach from "./VerticalEllipsisMenuFolderReasonBreach";
import CSLLoader from './../Common/CSLLoader';


const SearchInput = styled.input`
    height: 30px;
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #222222;
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
`;
const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #1E3E62;
    color: #ffffff;
    border: 1px solid #1E3E62;
    width: 37%;
    font-size: 13px;
    height: 43px;
    border-radius: 4px;
    display: inline-block;
    margin-left: 5px;
`;
const MRModalLabel = styled.div`
    color: #212121;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
    display: inline-block;
`;
const ExportButton = styled.button`
    box-sizing: border-box;
    background-color: #213E61;
    color: #F2F2F2;
    border: 1px solid #213E61;
    width: 125px;
    font-size: 14px;
    height: 32px;
    border-radius: 5px;
    margin-right: 3px;
    margin-top: 19px;
`;

const EmailContainer = styled.div`
	width: 100%;
`;
const EmailInnerContainer = styled.div`
	background-color: #ffffff;
	padding: 30px;
`;
const EmailBoldLabel = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 5px;
`;
const EmailTopHeader = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 5px;
	width:100%;
	height: 58px;
	padding: 20px;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  background-color: #ffffff;
  box-sizing: border-box;	
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 210%;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: 0%;
  top: -46px;
  z-index: 1000;
  opacity: 0.8;
`;
class BreachImpact extends React.Component
{
    state = {
        showActiveCheckbox: false,
        breach_impact: null,
        show_breach_impact_array: null,
        breach_impact_search: null,
        breachImpactActiveArrayAll: null,
        breachImpactInactiveArrayAll: null,
        ready: false,
        show_breach_impact_modal: false,
        standard_breach_impact: null,
        curid: 0,
        content: null,
        alert_param: null,
        headerText: "",
        isLoadedIcon: false
    };
    constructor(props) {
        super(props);        
    }

    componentDidMount()
	{		
		let breachImpactActiveArrayAll = [];		
		let breachImpactInactiveArrayAll = [];
		for(let i=0;i<this.props.breach_impact.length;i++){
			if(this.props.breach_impact[i].isActive === 1){
                breachImpactActiveArrayAll.push(this.props.breach_impact[i])
                breachImpactInactiveArrayAll.push(this.props.breach_impact[i])
			}else{
				breachImpactInactiveArrayAll.push(this.props.breach_impact[i])
			}
		}
		let breachimpactValue = [];
		if(this.props.general.breach_impact_active_inactive){
			breachimpactValue = breachImpactInactiveArrayAll
			console.log("Sourav")
		}else{			
			breachimpactValue = breachImpactActiveArrayAll
			console.log("Sourav1")
		}
		this.setState({showActiveCheckbox: this.props.general.breach_impact_active_inactive,
			breach_impact: this.props.breach_impact,
			//show_breach_impact_array: breachimpactValue,
			show_breach_impact_array: this.props.breach_impact,
			breach_impact_search: this.props.breach_impact,
			breachImpactActiveArrayAll: breachImpactActiveArrayAll,
            breachImpactInactiveArrayAll: breachImpactInactiveArrayAll,
            standard_breach_impact: this.props.standard_breach_impact,
            ready: true,alert_param: null});
		console.log("breach_impact===>",this.props.breach_impact);
    }
    
    componentDidUpdate(prevProps)
	{
		if (prevProps !== this.props) {
			let breachImpactActiveArrayAll = [];		
            let breachImpactInactiveArrayAll = [];
            for(let i=0;i<this.props.breach_impact.length;i++){
                if(this.props.breach_impact[i].isActive === 1){
                    breachImpactActiveArrayAll.push(this.props.breach_impact[i])
                    breachImpactInactiveArrayAll.push(this.props.breach_impact[i])
                }else{
                    breachImpactInactiveArrayAll.push(this.props.breach_impact[i])
                }
            }
            let breachimpactValue = [];
            if(this.props.general.breach_impact_active_inactive){
                breachimpactValue = breachImpactInactiveArrayAll
                console.log("Sourav")
            }else{			
                breachimpactValue = breachImpactActiveArrayAll
                console.log("Sourav1")
            }
            this.setState({showActiveCheckbox: this.props.general.breach_impact_active_inactive,
                breach_impact: this.props.breach_impact,
                //show_breach_impact_array: breachimpactValue,
                show_breach_impact_array: this.props.breach_impact,
                breach_impact_search: this.props.breach_impact,
                breachImpactActiveArrayAll: breachImpactActiveArrayAll,
                breachImpactInactiveArrayAll: breachImpactInactiveArrayAll,
                standard_breach_impact: this.props.standard_breach_impact,
                ready: true,alert_param: null,
                isLoadedIcon: false});
            console.log("breach_impact===>",this.props.breach_impact);			
		}
    }
    
    searchValue = (event) => {
		event.preventDefault();
        console.log("Sourav",event.target.value);
		console.log("fddsf",this.state.breach_impact_search)
		if(!this.state.showActiveCheckbox){
			let search_string = event.target.value;
			if (search_string === "") {
				let breach_impact = this.state.breachImpactActiveArrayAll;            
				this.setState({show_breach_impact_array: breach_impact});
			}else {
				let all_value = this.state.breachImpactActiveArrayAll;
				let filtered_values = [];
				all_value.forEach((breach) => {
					if (breach.breachImpactName.toLowerCase().includes(search_string.toLowerCase())) {
						filtered_values.push(breach);
					}                
				})            
				let breach_impact = filtered_values;

				this.setState({show_breach_impact_array: breach_impact})
			}
		}else{
			let search_string = event.target.value;
			if (search_string === "") {
				let breach_impact = this.state.breachImpactInactiveArrayAll;            
				this.setState({show_breach_impact_array: breach_impact});
			}else {
				let all_value = this.state.breachImpactInactiveArrayAll;
				let filtered_values = [];
				all_value.forEach((breach) => {
					if (breach.breachImpactName.toLowerCase().includes(search_string.toLowerCase())) {
						filtered_values.push(breach);
					}                
				})            
				let breach_impact = filtered_values;

				this.setState({show_breach_impact_array: breach_impact})
			}
		}
    }
    
    handleActiveInactive = (value) => (event) => {        
        console.log("value",value)  
		let data = value === 1 ? true : false;
		this.props.changeActiveInactive(data);		       
    }

    handlenaSectionIncluded = (section_id) => (event) => {
		console.log("content:", section_id);
		let {show_breach_impact_array} = this.state;
		let newbreach_impact = [];
		show_breach_impact_array.forEach((item) => {
			if (item.breachImpactID === section_id) {
				item.isActive = item.isActive === 1 ? 0 : 1; 
				//console.log("matched", item);
			}
			newbreach_impact.push(item);
		})
		console.log("emails:", newbreach_impact);
        this.setState({isLoadedIcon: true})
		this.props.updatebreach_impact(newbreach_impact);
	}

    showConfigModal = (curid, isdelete) => (obj) => {
		console.log("curid==>", curid)
		console.log("obj==>", obj)
		if(obj === 'Edit'){			
            const content = this.state.breach_impact.find((item) => {
                return item.breachImpactID === curid;
            })
            this.setState({content,curid: curid,show_breach_impact_modal: true, headerText: "Edit"});
		}else if(obj === 'Delete'){
            if(isdelete === 0){
                let alert_param = {title: 'Warning', message: 'Are you sure you want to delete this Incident Impact from the list?', ok_text: 'Yes', cancel_text: 'No', confirm: true, 
                           alertHandler: this.updatedeleteReasonBreach, stack: {id: curid}}
                this.setState({alert_param: alert_param})
            }else{
                let alert_param = {title: 'ERROR', message: 'This Incident Impact has already been used.', ok_text: 'Ok', confirm: false, 
                           alertHandler: this.updateafternodelete, stack: {}}
                this.setState({alert_param: alert_param})
            }			
		}
	};

    tableData = () => {
        console.log("this.state.show_breach_impact_array",this.state.show_breach_impact_array)
        let ret = {data: [], columns: []};
        ret.columns =[
                        {Header: 'Name', accessor: 'name', minWidth: 240, headerStyle: {textAlign: 'left'}},					
						{'Header' : 'Status', Cell: row => (
							<div> 
							<Toggle
								style={{float:'left'}}
								defaultChecked={row.original.status === "Active" ? true : false}
								icons={false}	
                                onChange={this.handlenaSectionIncluded(row.original.id)}						
							/>
							</div>
						 ), headerStyle:{textAlign: 'center'},style: {textAlign: 'center'}},						
                        {'Header' : '', Cell: row => (
                            <div>                                
                                  <VerticalEllipsisMenuFolderReasonBreach editDelete={this.showConfigModal(row.original.id, row.original.isdelete)} options={["Edit", "Delete"]} />	
                            </div>
                         ), minWidth: 40, headerStyle:{textAlign: 'left'},style: {textAlign: 'center'}}
                        ];

        let breach_impact_sort_array = this.state.show_breach_impact_array.sort(function(a,b) {return (a.breachImpactName > b.breachImpactName) ? 1 : ((b.breachImpactName > a.breachImpactName) ? -1 : 0);} );
		breach_impact_sort_array.forEach((item) => {
			let statusValue = item.isActive === 1 ? "Active" : "Inactive"
			let elem = {id: item.breachImpactID, name: item.breachImpactName, status: statusValue, isdelete: item.isDelete};
			ret.data.push(elem);
        })
        return ret;
    }

    notDeleteBreachImpact = () => {
        //alert("This Breach Impact has already been used.")
        let alert_param = {title: 'ERROR', message: 'This Breach Impact has already been used.', ok_text: 'Ok', confirm: false, 
                           alertHandler: this.updateafternodelete, stack: {}}

        this.setState({alert_param: alert_param})
    }
    updateafternodelete = (result, stack) => {
        console.log(result,stack)
        this.setState({alert_param: null})        
    }

    deleteBreachImpact = (id) => (event) => {
        event.preventDefault();
        let alert_param = {title: 'Warning', message: 'Are you sure you want to delete this Incident Impact from the list?', ok_text: 'Yes', cancel_text: 'No', confirm: true, 
                           alertHandler: this.updatedeleteReasonBreach, stack: {id: id}}
        this.setState({alert_param: alert_param})

        // if (window.confirm('Are you sure you want to delete this Breach Impact from the list?')) {
        //    	console.log("id",id)
        //    	let {breach_impact} = this.state;
		// 	let newreason = [];
		// 	for(let i=0;i<this.state.breach_impact.length;i++)
		// 	{
		// 		if(this.state.breach_impact[i].breachImpactID !== id){
		// 			newreason.push(this.state.breach_impact[i])
		// 		}
		// 	}			 
		// 	console.log("delete===>",newreason);   
		// 	this.props.deleteBreachImpactValue(newreason);        
		// }         		  
    }

    updatedeleteReasonBreach = (result, stack) => {    
        if(!result) {
            this.setState({alert_param: null})
            return
        }
        console.log("id",stack.id)
        let {breach_impact} = this.state;
        let newreason = [];
        for(let i=0;i<this.state.breach_impact.length;i++)
        {
            if(this.state.breach_impact[i].breachImpactID !== stack.id){
                newreason.push(this.state.breach_impact[i])
            }
        }			 
        console.log("delete===>",newreason);   
        this.props.deleteBreachImpactValue(newreason);           
    }

    openModal = (id) => (event) => {
		event.preventDefault();     
		console.log("id",id); 
		const content = this.state.breach_impact.find((item) => {
			return item.breachImpactID === id;
		})
		this.setState({content,curid: id,show_breach_impact_modal: true});        
	}
    
    addBreachImpact = (event) => {
		event.preventDefault();
		this.setState({show_breach_impact_modal: true,curid: 0, headerText: "Add"})
    }
    closeModal = () => {		
		this.setState({show_breach_impact_modal: false})
    }
    
    saveBreachImpactValue = (obj) => {
		this.setState({show_breach_impact_modal: false, isLoadedIcon: true})
		this.props.saveBreachImpactValue(obj)
    }
    
    importType = (event) => {
        event.preventDefault();
        console.log("standard_breach_impact",this.state.standard_breach_impact)
        let alert_param = {title: 'Warning', message: 'Are you sure you want to import the Breach Impact?', ok_text: 'Yes', cancel_text: 'No', confirm: true, 
                           alertHandler: this.updateimportTypeBreach, stack: {}}
        this.setState({alert_param: alert_param})
        // if (window.confirm('Are you sure you want to import the Breach Impact?')) {
        //     let show_breach_impact_array = this.state.breach_impact;
        //     this.state.standard_breach_impact.forEach((breach) => {
        //         show_breach_impact_array.push(breach);               
        //     })
        //     this.props.importBreachImpactValue(show_breach_impact_array)            
        // }
    }

    updateimportTypeBreach = (result, stack) => {       
        if(!result) {
            this.setState({alert_param: null})
            return
        }       
        let show_breach_impact_array = this.state.breach_impact;
        this.state.standard_breach_impact.forEach((breach) => {
            show_breach_impact_array.push(breach);               
        })
        this.props.importBreachImpactValue(show_breach_impact_array)    
    }

    updateBreachImpactValue = (content) => {
		console.log("content:", content);
		//console.log("this.state",this.state.reason_for_removal)
		let {breach_impact} = this.state;
		let newreason = [];
		breach_impact.forEach((item) => {
			if (item.breachImpactID === content.breachImpactID) {
				item = content;
				console.log("matched", item);
			}
			newreason.push(item);
		})
		console.log("resons:", newreason);
		this.setState({show_breach_impact_modal: false, isLoadedIcon: true})
		this.props.updateBreachImpactValue(newreason);		
	}
    
    
    render()    
	{
        if (!this.state.ready) {
			return (<div>Loading...</div>);
		}          
		return(
        <div>            
            <div>
                    {
                        (() => {
                            if (this.state.show_breach_impact_modal) {
                                return (<div ><InactiveOverlay style={{height: "105%"}}/><BreachImpactConfigModal headerText={this.state.headerText} closeModal={this.closeModal} curid={this.state.curid} content={JSON.parse(JSON.stringify(this.state.content))} updateBreachImpactValue={this.updateBreachImpactValue} saveBreachImpactValue={this.saveBreachImpactValue}/></div>);
                            }
                        })()
                    }
                    {
                        (() => {
                            if(this.state.isLoadedIcon){
                                return <div><CSLLoader style={{position: 'absolute'}}/></div>
                            }
                        })()
                    }                    
                    <EmailContainer>
                        <EmailInnerContainer>                            
                            <EmailTopHeader style={{display: "inline-block" , fontWeight: 700}}>								
                                Incident Impact                                
                                <div style={{float:'right'}}>
                                    <div onClick={this.addBreachImpact}  style={{float: "left", cursor: "pointer",fontSize: "25px",color: "#04ADA8"}}>
                                        <FaPlusCircle  /> 
                                    </div>
                            
                                    <div style={{float: "right", cursor: "pointer",fontSize: "25px",color: "#e4e4e4"}}>
                                        <FaEllipsisV  /> 
                                    </div>
                                </div>
							</EmailTopHeader>
                            <CSLTable add={false} processData={this.tableData} tableRows={10} />
                        </EmailInnerContainer>
                    </EmailContainer>
            </div>
            <AlertBox alertParam = {this.state.alert_param}/>
        </div>
        );
	}
}

export default BreachImpact;