import React from 'react';
import styled from 'styled-components';
import CSLTable from '../Common/MCCTable';
import EmailConfigModal from './EmailConfigModal';
import Toggle from 'react-toggle';
import "./ReactToggle.css";
import VerticalEllipsisMenuFolderEmail from "./VerticalEllipsisMenuFolderEmail";
import CSLLoader from './../Common/CSLLoader';


const EmailContainer = styled.div`
	width: 100%;
`;
const EmailInnerContainer = styled.div`
	background-color: #ffffff;
	padding: 30px;
`;
const EmailBoldLabel = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 5px;
`;
const EmailTopHeader = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 5px;
	width:100%;
	height: 58px;
	padding: 20px;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  background-color: #ffffff;
  box-sizing: border-box;
  
	
`;
const EmailLabel = styled.div`
	color: #595959;
	margin-top: 5px;
`;

const EmailSubLabel = styled.div`
	color: #838c91;
	font-weight: 500;
	margin-top: 10px;
	display: inline-block;
`;

class Email extends React.Component
{
	state = {email: null, variables: null, show_email_config_modal: null, content: null, general: true, isLoadedIcon: false};

	componentDidMount()
	{
		this.setState({email: this.props.email, variables: this.props.variables, general: this.props.general});
	}

	componentDidUpdate(prevProps)
	{
		if (prevProps !== this.props) {
			this.setState({email: this.props.email, variables: this.props.variables, general: this.props.general, isLoadedIcon: false});
		}
	}

	showEmailConfigModal = (email_id) => (event) => {
		event.preventDefault();
		console.log("email_id:", email_id);
		const content = this.state.email.find((item) => {
			return item.id === email_id;
		})
		this.setState({content, show_email_config_modal: true});
	}

	showEmailConfigModalUpdate = (email_id) => (obj) => {
		console.log("curid==>", email_id)
		console.log("obj==>", obj)
		if(obj === 'Edit'){
			const content = this.state.email.find((item) => {
				return item.id === email_id;
			})
			this.setState({content, show_email_config_modal: true});
		}
	  };

	modifyEmails = (content) => {
		console.log("content:", content);
		let {email} = this.state;
		let newemail = [];
		email.forEach((item) => {
			if (item.id === content.id) {
				item = content;
				console.log("matched", item);
			}
			newemail.push(item);
		})
		console.log("emails:", newemail);
		this.props.updateEmail(newemail);
		this.setState({email: newemail, show_email_config_modal: null});
	}

	closeModal = () => {
		this.setState({show_email_config_modal: null, content: null});
	}

	

	tableData = () => {
        let ret = {data: [], columns: []};
        ret.columns =[
                        {Header: 'Email', accessor: 'email', minWidth: 120, headerStyle: {textAlign: 'left'}}, 
						{Header: 'Subject', accessor: 'subject', minWidth: 240, headerStyle: {textAlign: 'left'}},						
						{'Header' : 'Status', Cell: row => (
							<div> 
								<Toggle
									style={{float:'left'}}
									defaultChecked={row.original.enabled}
									icons={false}
									onChange={this.handlenaSectionIncluded(row.original.id)} 
								/>
							</div>
						 ), headerStyle:{textAlign: 'center'},style: {textAlign: 'center'}},
                        {'Header' : '', Cell: row => (
                           <div>                               
								<VerticalEllipsisMenuFolderEmail editDelete={this.showEmailConfigModalUpdate(row.original.id)} options={["Edit"]} />	
                           </div>
                        ), minWidth: 40, headerStyle:{textAlign: 'left'}}];
        this.state.email.forEach((item) => {
        	let elem = {id: item.id, email: item.name, subject: item.subject, enabled: item.enabled};
        	ret.data.push(elem);
        })
        return ret;
	}

	handlenaSectionIncluded = (section_id) => (event) => {
		console.log("content:", section_id);
		let {email} = this.state;
		let newemail = [];
		email.forEach((item) => {
			if (item.id === section_id) {
				item.enabled = item.enabled ? false : true; 
				console.log("matched", item);
			}
			newemail.push(item);
		})
		console.log("emails:", newemail);
		this.setState({isLoadedIcon: true})
		this.props.updateEmail(newemail);
	}

	switchRadio = (key) => (event) => {
		event.preventDefault();
		let {general} = this.state;
		general[key] = general[key] ? false : true;
		this.props.updateGeneralSave(general);
	}

	handleToggleChange = (arg)=>{
		let {general} = this.state;
		general[arg] = general[arg] ? false : true;		
		this.setState({general, isLoadedIcon: true})	 
		this.props.updateGeneralSave(general);
	}

	render()
	{
		if (this.state.email === null) {
			return (
				<div>Loading...</div>
			);
		}
		return (
			<div>
			{
				(() => {
					if (this.state.show_email_config_modal !== null) {
						return (<EmailConfigModal variables={this.state.variables} content={JSON.parse(JSON.stringify(this.state.content))} modifyEmails={this.modifyEmails} closeModal={this.closeModal
						} />);
					}
				})()
			}
			{
				(() => {
					if(this.state.isLoadedIcon){
						return <div><CSLLoader style={{position: 'absolute'}}/></div>
					}
				})()
			}
			<EmailContainer>
				<EmailInnerContainer>
					<EmailBoldLabel style={{marginBottom:"10px"}}>Enable Email Reminder?</EmailBoldLabel>					
					<Toggle					    
					    icons={false}
					    onChange={()=> this.handleToggleChange('email_remainder_enable')}
					    defaultChecked={this.state.general.email_remainder_enable ? true : false} 
					    />
				{/* </EmailInnerContainer> */}
			{/* </EmailContainer> */}
			<div styled={{clear:"both"}}></div>
				{
					(() => {
						if (this.state.general.email_remainder_enable) {
							return(
									// <EmailContainer>
									// 	<EmailInnerContainer>
										<div style={{marginTop: "10px"}}>
											<EmailTopHeader style={{display: "inline-block" , fontWeight: 700}}>
												Emails												
											</EmailTopHeader>											
											<CSLTable add={false} processData={this.tableData} headerText={''} tableRows={5} refreshCallback={this.refreshState} />
											</div>
									// 	</EmailInnerContainer>
									// </EmailContainer>
								)
							
						}
					})()
				}	
				</EmailInnerContainer>		
				</EmailContainer>
			</div>
		);
	}
}

export default Email;