import React from 'react';
import styled from 'styled-components';
import { FaEdit,FaPencilAlt, FaTimes,FaPlusCircle, FaCalendarAlt } from "react-icons/fa";
import { IoIosCheckboxOutline, IoIosSquareOutline, IoIosWarning } from "react-icons/io";
import Toggle from 'react-toggle';
import "./ReactToggle.css";
import DatePicker from 'react-date-picker';
import moment from 'moment';
import CSLLoader from './../Common/CSLLoader';

const SelectedRadio = styled.div`
	background-color: #37ADA7;
	border: 1px solid #1f615e;
	padding: 5px 10px;
	color: #ffffff;
	display: inline-block;
	cursor: pointer;
`;
const UnselectedRadio = styled.div`
	background-color: #F4F4F4;
	border: 1px solid #8c8c8c;
	padding: 5px 10px;
	color: #262626;
	display: inline-block;
	cursor: pointer;
`;
const GeneralContainer = styled.div`
	width: 101%;
`;
const GeneralInnerContainer = styled.div`
	background-color: #ffffff;
	padding: 30px;
`;
 

const GeneralLabel = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 20px;
`;
const RadioBlock = styled.div`
	margin-top: 10px;
`;
const SearchInput = styled.input`
    height: 30px;
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #222222;
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
`;
const MRModalLabel = styled.div`
	color: #1a3552;
	font-family: "DMSans-Medium", "DM Sans Medium", "DM Sans", sans-serif;
	font-weight: 600;
	margin-top: 10px;
	display: inline-block;
`;
const MRModalSubLabel = styled.div`
	color: #838c91;
	font-weight: 500;
	margin-top: 10px;
	display: inline-block;
`;

const MRModalHeaderLabel = styled.div`
  
  font-family: "Roboto-Bold", "Roboto Bold", "Roboto", sans-serif;
  font-weight: 900;
  color: #1b3452;
  text-align: left;
   margin-top: 10px;
`;

const EmailContainer = styled.div`
	width: 100%;
`;
const EmailInnerContainer = styled.div`
	background-color: #ffffff;
	padding: 18px;
`;
const EmailBoldLabel = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 5px;
`;
const MRModalInput = styled.input`
    height: 25px;
    width: 99%;
    box-shadow: rgb(196, 196, 196) 0px 0px 4px;
    color: #545454;
    font-family: montserrat;
    font-size: 12px;
    font-weight: 600;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(255, 255, 255);
    border-image: initial;
    border-radius: 3px;
    padding: 6px;
`;
const MRModalTextarea = styled.textarea`
    width: 99%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #545454;
	font-family: 'Montserrat', sans-serif;
	font-size: 12px;
    font-weight: 600;
`;
const DropdownOptions = styled.textarea`
	border: 1px solid #ffffff;
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 0 4px #c4c4c4;
    resize: none;
    padding: 5px;
    width: 98%;
    font-family: 'Montserrat',sans-serif;
    margin-top: 7px;
`;

const MRModalSelect = styled.select`
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #444444;    
    padding: 10px;
    font-weight: 600;
`;
const CSLDateCover = styled.div`   
    width: 140px;
    border-radius: 3px;
    height: 33px;
    padding-top: 5px;
    padding-left: 10px;
    background-color: #fff;
    border: 1px solid #ffffff;
    box-sizing: border-box; 
    box-shadow: 0 0 4px #c4c4c4;
    color: #758597; 
`;
class FinancialImpact extends React.Component
{
	state = {financial_impact: null, compensation_paid_date: null, isLoadedIcon: false};	

	componentDidMount()
	{	
        let paidDate = this.props.financial_impact.compensation_paid;
        let updatedPaidDate = null
        if(paidDate !== ''){
            updatedPaidDate = moment.unix(paidDate);
            updatedPaidDate = new Date(updatedPaidDate);
        }
		this.setState({financial_impact: this.props.financial_impact, compensation_paid_date: updatedPaidDate});	
	}

	componentDidUpdate(prevProps)
	{
		if (prevProps !== this.props) {
            let paidDate = this.props.financial_impact.compensation_paid;
            let updatedPaidDate = null
            if(paidDate !== ''){
                updatedPaidDate = moment.unix(paidDate);
                updatedPaidDate = new Date(updatedPaidDate);
            }
			this.setState({financial_impact: this.props.financial_impact, compensation_paid_date: updatedPaidDate, isLoadedIcon: false});			
		}
	}

	handleChange = (evt) => {    
		this.props.updateFinancialImpactValue(evt.target.value)
	}	

    modifyDropdownOptions = (key , e)=>{	
		let {financial_impact} = this.state;
		financial_impact[key] = e.target.value;	
		this.setState({financial_impact, isLoadedIcon: true})	 
		this.props.updateFinancialImpact(financial_impact);
	}

    handleToggleFUChange = (arg)=>{
		let {financial_impact} = this.state;
		financial_impact[arg] = financial_impact[arg] ? false : true;		
		this.setState({financial_impact, isLoadedIcon: true})	 
		this.props.updateFinancialImpact(financial_impact);
	}

    onChangeDate = (date) => {    
        let updateDate = moment(date).unix();  
        this.props.updateFinancialImpactDate(updateDate)
    }

	render()
	{
		if (this.state.financial_impact === null) {
			return (<div>Loading...</div>);
		}

		return (
			<div>	
			{
				(() => {
					if(this.state.isLoadedIcon){
						return <div><CSLLoader style={{position: 'absolute'}}/></div>
					}
				})()
			}		
			<GeneralContainer>
				<GeneralInnerContainer>
					<MRModalSubLabel style={{marginTop: "10px",marginBottom:"10px", display: "block"}}>Select which fields you would like to appear in a Financial Impact section. If no options are selected, the Financial Impact section will not appear</MRModalSubLabel>
					<div style={{marginBottom:"10px", display: "block"}}>
						<MRModalLabel style={{display: "block"}}>Impact Type</MRModalLabel>
						<MRModalSubLabel style={{marginTop: "10px",marginBottom:"10px", display: "block"}}>The user will be required to record whether the Incident resulted in a gain, a loss or that there was no financial impact. Please note this is a mandatory field when enabled.</MRModalSubLabel>
                        {/* <div style={{width: "20%"}}>
						<MRModalSelect id="impact_type" name="impact_type" onChange={(e)=> this.modifyDropdownOptions('impact_type' , e)} value={this.state.financial_impact.impact_type} >                            					
								<option value=''>Select...</option>
								<option value='gain'>Gain</option>
								<option value='loss'>Loss</option>
								<option value='no_impact'>No Impact</option>	
						</MRModalSelect>
                        </div> */}
						<Toggle
					    defaultChecked={this.state.financial_impact.impact_type ? true : false}                        
					    icons={false}
					    onChange={()=> this.handleToggleFUChange('impact_type')} />
				    </div>
				    <div style={{marginBottom:"10px", display: "block"}}>
						<MRModalLabel style={{display: "block"}}>Record Estimated and Actual Cost of Incident (Loss)?</MRModalLabel>
						<MRModalSubLabel style={{marginTop: "10px",marginBottom:"10px",display: "block"}}>The users can optionally provide estimated and actual costs to the company that are the result of the Incident.</MRModalSubLabel>
						<Toggle
					    defaultChecked={this.state.financial_impact.record_cost_of_incident ? true : false}                        
					    icons={false}
					    onChange={()=> this.handleToggleFUChange('record_cost_of_incident')} />
				    </div>
				     
				    
				     <div style={{marginBottom:"10px", display: "block"}}>
						<MRModalLabel style={{display: "block"}}>Record Estimated and Actual Third Party Costs of Incident?</MRModalLabel>
						<MRModalSubLabel style={{marginTop: "10px",marginBottom:"10px",display: "block"}}>The users can optionally provide estimated and actual costs to a Third Party that are the result of the Incident.</MRModalSubLabel>
						<Toggle
					    defaultChecked={this.state.financial_impact.record_third_party_costs ? true : false}
					    icons={false}
					    onChange={()=> this.handleToggleFUChange('record_third_party_costs')} />
				    </div>
				    
				    <div style={{marginBottom:"10px", display: "block"}}>
						<MRModalLabel style={{display: "block"}}>Financial Impact Calculation Methodology</MRModalLabel>
						<MRModalSubLabel style={{marginTop: "10px",marginBottom:"10px",display: "block"}}>Provides a text box where an explanation can be provided as to how the gain/loss/costs were calculated.</MRModalSubLabel>
						{/* <MRModalTextarea id="financial_impact_calculation_methodology" rows="6" name="financial_impact_calculation_methodology" value={this.state.financial_impact.financial_impact_calculation_methodology} onChange={this.handleChange}/> */}
						<Toggle
					    defaultChecked={this.state.financial_impact.financial_impact_calculation_methodology ? true : false}                        
					    icons={false}
					    onChange={()=> this.handleToggleFUChange('financial_impact_calculation_methodology')} />
				    </div>
				    
				    <div style={{marginBottom:"10px", display: "block"}}>
						<MRModalLabel style={{display: "block"}}>Compensation Paid Date</MRModalLabel>
						<MRModalSubLabel style={{marginTop: "10px",marginBottom:"10px",display: "block"}}>Allows the date to be recorded when compensation was paid.</MRModalSubLabel>

						{/* <CSLDateCover id="breach_date" style={{display: "inline-block"}}>
                            <DatePicker
                                    onChange={this.onChangeDate}
                                    clearIcon={null}
                                    calendarIcon={null}
                                    locale={"en-GB"}
                                    calendarIcon={<FaCalendarAlt style={{color: '#243E57'}} />}
                                    value={this.state.compensation_paid_date}
                            />
                        </CSLDateCover> */}
						<Toggle
					    defaultChecked={this.state.financial_impact.compensation_paid_date ? true : false}                        
					    icons={false}
					    onChange={()=> this.handleToggleFUChange('compensation_paid_date')} />
				    </div>	
				    
				    <div>
						<MRModalLabel style={{marginTop: "20px",display: "block",marginBottom: "10px"}}>Client Currency</MRModalLabel> 					
						<div style={{width: "10%"}}>
												
						<MRModalSelect id="client_currency" name="client_currency" onChange={(e)=> this.modifyDropdownOptions('client_currency' , e)} value={this.state.financial_impact.client_currency} >                            
                            <option value='GBP'>GBP</option>
                            <option value='EUR'>EUR</option>
                            <option value='USD'>USD</option>
							<option value='CAD'>CAD</option>
                        </MRModalSelect>					
						</div>
					</div>
				</GeneralInnerContainer>												
			</GeneralContainer>
			</div>
		);
	}
}

export default FinancialImpact;