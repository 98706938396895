import React from 'react';
import styled from 'styled-components';
import CSLTable from '../Common/MCCTable';
import { FaPencilAlt,FaEdit, FaTimes,FaPlusCircle, FaEllipsisV } from "react-icons/fa";
import { IoIosCheckboxOutline, IoIosSquareOutline, IoIosWarning } from "react-icons/io";
import RegulatorTypesConfigModal from './RegulatorTypesConfigModal';
import Store from '../../Common/Store'
import AlertBox from '../Common/AlertBox';
import Toggle from 'react-toggle';
import "./ReactToggle.css";
import VerticalEllipsisMenuFolderReasonBreach from "./VerticalEllipsisMenuFolderReasonBreach";
import CSLLoader from './../Common/CSLLoader';

const SearchInput = styled.input`
    height: 30px;
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #222222;
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
`;
const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #1E3E62;
    color: #ffffff;
    border: 1px solid #1E3E62;
    width: 37%;
    font-size: 13px;
    height: 43px;
    border-radius: 4px;
    display: inline-block;
    margin-left: 5px;
`;
const MRModalLabel = styled.div`
    color: #212121;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
    display: inline-block;
`;
const ExportButton = styled.button`
    box-sizing: border-box;
    background-color: #213E61;
    color: #F2F2F2;
    border: 1px solid #213E61;
    width: 125px;
    font-size: 14px;
    height: 32px;
    border-radius: 5px;
    margin-right: 3px;
    margin-top: 19px;
`;

const EmailContainer = styled.div`
	width: 100%;
`;
const EmailInnerContainer = styled.div`
	background-color: #ffffff;
	padding: 30px;
`;
const EmailBoldLabel = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 5px;
`;

const EmailTopHeader = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 5px;
	width:100%;
	height: 58px;
	padding: 20px;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  background-color: #ffffff;
  box-sizing: border-box;
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 210%;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: 0%;
  top: -46px;
  z-index: 1000;
  opacity: 0.8;
`;

class Regulators extends React.Component
{
    state = {        
        regulator_types: null,
        show_regulator_types_array: null,
        regulator_types_search: null,
        regulatorTypesActiveArrayAll: null,
        regulatorTypesInactiveArrayAll: null,
        ready: false,
        show_regulator_types_modal: false,
        standard_regulator_types: null,
        curid: 0,
        content: null,
        alert_param: null,
        headerText: "",
        isLoadedIcon: false
    };
    constructor(props) {
        super(props);        
    }

    componentDidMount()
	{		
		let regulatorTypesActiveArrayAll = [];		
		let regulatorTypesInactiveArrayAll = [];
		for(let i=0;i<this.props.regulator_types.length;i++){
			if(this.props.regulator_types[i].isActive === 1){
                regulatorTypesActiveArrayAll.push(this.props.regulator_types[i])
                regulatorTypesInactiveArrayAll.push(this.props.regulator_types[i])
			}else{
				regulatorTypesInactiveArrayAll.push(this.props.regulator_types[i])
			}
		}		
		
		this.setState({
			regulator_types: this.props.regulator_types,
			show_regulator_types_array: this.props.regulator_types,
			regulator_types_search: this.props.regulator_types,
			regulatorTypesActiveArrayAll: regulatorTypesActiveArrayAll,
            regulatorTypesInactiveArrayAll: regulatorTypesInactiveArrayAll,            
            ready: true,alert_param: null});
		console.log("regulator_types===>",this.props.regulator_types);
    }
    
    componentDidUpdate(prevProps)
	{
		if (prevProps !== this.props) {
			let regulatorTypesActiveArrayAll = [];		
            let regulatorTypesInactiveArrayAll = [];
            for(let i=0;i<this.props.regulator_types.length;i++){
                if(this.props.regulator_types[i].isActive === 1){
                    regulatorTypesActiveArrayAll.push(this.props.regulator_types[i])
                    regulatorTypesInactiveArrayAll.push(this.props.regulator_types[i])
                }else{
                    regulatorTypesInactiveArrayAll.push(this.props.regulator_types[i])
                }
            }		
            
            this.setState({
                regulator_types: this.props.regulator_types,
                show_regulator_types_array: this.props.regulator_types,
                regulator_types_search: this.props.regulator_types,
                regulatorTypesActiveArrayAll: regulatorTypesActiveArrayAll,
                regulatorTypesInactiveArrayAll: regulatorTypesInactiveArrayAll,            
                ready: true,alert_param: null,
                isLoadedIcon: false});
            console.log("regulator_types===>",this.props.regulator_types);			
		}
    } 

    handlenaSectionIncluded = (section_id) => (event) => {
		console.log("content:", section_id);
		let {show_regulator_types_array} = this.state;
		let newshow_regulator_types_array = [];
		show_regulator_types_array.forEach((item) => {
			if (item.id === section_id) {
				item.isActive = item.isActive === 1 ? 0 : 1; 				
			}
			newshow_regulator_types_array.push(item);
		})
		console.log("emails:", newshow_regulator_types_array);
        this.setState({isLoadedIcon: true})
		this.props.updateshow_regulator_types(newshow_regulator_types_array);
	}

    showConfigModal = (curid, isdelete) => (obj) => {
		console.log("curid==>", curid)
		console.log("obj==>", obj)
		if(obj === 'Edit'){			
            const content = this.state.regulator_types.find((item) => {
                return item.id === curid;
            })
            this.setState({content,curid: curid, show_regulator_types_modal: true, headerText: "Edit"});   
		}else if(obj === 'Delete'){
            if(isdelete === 0){
                let alert_param = {title: 'Warning', message: 'Are you sure you want to delete this Regulator from the list?', ok_text: 'Yes', cancel_text: 'No', confirm: true, 
                           alertHandler: this.updatedeleteRegulatorType, stack: {id: curid}}
                this.setState({alert_param: alert_param})
            }else{
                let alert_param = {title: 'ERROR', message: 'This Regulator type has already been used.', ok_text: 'Ok', confirm: false, 
                           alertHandler: this.updateafternodelete, stack: {}}
                this.setState({alert_param: alert_param})
            }			
		}
	};

    updatedeleteRegulatorType = (result, stack) => {  
        if(!result) {
            this.setState({alert_param: null})
            return
        }
        console.log("id",stack.id)           	
        let newreason = [];
        for(let i=0;i<this.state.regulator_types.length;i++)
        {
            if(this.state.regulator_types[i].id !== stack.id){
                newreason.push(this.state.regulator_types[i])
            }
        }			 
        console.log("delete===>",newreason);   
        this.props.deleteRegulatorTypesValue(newreason);
    }

    updateafternodelete = (result, stack) => {
        console.log(result,stack)
        this.setState({alert_param: null})        
    }  

    tableData = () => {
        console.log("this.state.show_regulator_types_array",this.state.show_regulator_types_array)
        let ret = {data: [], columns: []};
        ret.columns =[
                        {Header: 'Name', accessor: 'name', minWidth: 240, headerStyle: {textAlign: 'left'}},					
						{'Header' : 'Status', Cell: row => (
							<div> 
							<Toggle
								style={{float:'left'}}
								defaultChecked={row.original.status === "Active" ? true : false}
								icons={false}	
                                onChange={this.handlenaSectionIncluded(row.original.id)}						
							/>
							</div>
						 ), headerStyle:{textAlign: 'center'},style: {textAlign: 'center'}},						
                        {'Header' : '', Cell: row => (
                            <div>                                
                                  <VerticalEllipsisMenuFolderReasonBreach editDelete={this.showConfigModal(row.original.id, row.original.isdelete)} options={["Edit", "Delete"]} />	
                            </div>
                         ), minWidth: 40, headerStyle:{textAlign: 'left'},style: {textAlign: 'center'}}
                        ];

        let regulator_types_sort_array = this.state.show_regulator_types_array.sort(function(a,b) {return (a.ragulatorname > b.ragulatorname) ? 1 : ((b.ragulatorname > a.ragulatorname) ? -1 : 0);} );
		regulator_types_sort_array.forEach((item) => {
			let statusValue = item.isActive === 1 ? "Active" : "Inactive"
			let elem = {id: item.id, name: item.ragulatorname, status: statusValue, isdelete: item.isDelete};
			ret.data.push(elem);
        })
        return ret;
    }   
    
    addRegulatorType = (event) => {
		event.preventDefault();
		this.setState({show_regulator_types_modal: true,curid: 0, headerText: "Add"})
    }
    closeModal = () => {		
		this.setState({show_regulator_types_modal: false})
    }
    
    saveRegulatorTypeValue = (obj) => {
		this.setState({show_regulator_types_modal: false, isLoadedIcon: true})
		this.props.saveRegulatorTypesValue(obj)
    }

    updateRegulatorTypesValue = (content) => {
		console.log("content:", content);
		//console.log("this.state",this.state.reason_for_removal)
		let {regulator_types} = this.state;
		let newreason = [];
		regulator_types.forEach((item) => {
			if (item.id === content.id) {
				item = content;
				console.log("matched", item);
			}
			newreason.push(item);
		})
		console.log("resons:", newreason);
		this.setState({show_regulator_types_modal: false, isLoadedIcon: true})
		this.props.updateRegulatorTypesValue(newreason);		
	}
    
    
    render()    
	{
        if (!this.state.ready) {
			return (<div>Loading...</div>);
		}          
		return(
        <div>            
            <div>
                    {
                        (() => {
                            if (this.state.show_regulator_types_modal) {
                                return (<div ><InactiveOverlay style={{height: "105%"}}/><RegulatorTypesConfigModal headerText={this.state.headerText} closeModal={this.closeModal} curid={this.state.curid} content={JSON.parse(JSON.stringify(this.state.content))} updateRegulatorTypesValue={this.updateRegulatorTypesValue} saveRegulatorTypeValue={this.saveRegulatorTypeValue}/></div>);
                            }
                        })()
                    }
                    {
                        (() => {
                            if(this.state.isLoadedIcon){
                                return <div><CSLLoader style={{position: 'absolute'}}/></div>
                            }
                        })()
                    }
                     
                    <EmailContainer>
                        <EmailInnerContainer>
                             
                            <EmailTopHeader style={{display: "inline-block" , fontWeight: 700}}>
                                Regulators                              
                                <div style={{float:'right'}}>
                                    <div onClick={this.addRegulatorType}  style={{float: "left", cursor: "pointer",fontSize: "25px",color: "#04ADA8"}}>
                                        <FaPlusCircle  /> 
                                    </div>                            
                                    <div style={{float: "right", cursor: "pointer",fontSize: "25px",color: "#e4e4e4"}}>
                                        <FaEllipsisV  /> 
                                    </div>
                                </div>
							</EmailTopHeader>
                            <CSLTable add={false} processData={this.tableData} tableRows={10} />
                        </EmailInnerContainer>
                    </EmailContainer>
            </div>
            <AlertBox alertParam = {this.state.alert_param}/>
        </div>
        );
	}
}

export default Regulators;