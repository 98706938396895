import React from 'react';
import styled from 'styled-components';
import {FaTimes} from 'react-icons/fa';
import Store from '../../Common/Store.js';
import Utils from '../../Common/Utils.js';
import Dropzone from './../Common/Dropzone'

const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    //height: auto;
    width: 45%;
    position: absolute;
    //top: 38px;
    z-index: 1001;
    background-color: rgb(243, 247, 251);
    box-shadow: rgb(167, 166, 166) 0px 0px 5px;
    margin-bottom: 10px;
    border-radius: 5px;
    margin-left: 25%;
`;
const MRModalHeader = styled.div`
    background-color: #F6F9FD;
    display: block;
    padding-top: 5px;
    font-size: 14px;
    font-weight: 700;
    height: 90px;    
    width: calc(100% - 0px);
    border-width: 1px 1px 1px 10px;    
    //border-left: 9px solid #04ADA8; 
    //border-bottom: 1px solid #DFDFDF;
`;
const MRModalHeaderText = styled.div`
    font-weight: 700;
    font-size: 22px;
    float: left;
    margin-top: 30px;
    margin-left: 35px;  
    color: #1A3552;  
`;
const MRModalSubHeaderText = styled.div`
    font-weight: 700;
    font-size: 13px;
    float: left;
    margin-left: 35px;
    margin-bottom: 20px;
    margin-top: 10px;
    color: #636363;
`;
const MRModalHeaderCloseBtn = styled.div`
    position: absolute;
    top: 8px;
    right: 10px;
    box-sizing: border-box;
    margin-top: 26px;
    margin-right: 20px;
    font-size: 25px;
    cursor: pointer;
    font-weight: 200;
`;
const MRModalBody = styled.div`
    position: relative;
    display: block;
    box-sizing: border-box;
    background-color: #F3F7FB;
    padding: 10px;
    border-radius: 4px;
`;
const MRModalLabel = styled.div`
    color: #1a3552;
    font-weight: 600;
    margin-top: 15px;
    font-size: 14px;
    margin-bottom: 15px;
    opacity: 0.58;
    font-family: "Roboto-Medium", "Roboto Medium", "Roboto", sans-serif;
`;
const MRModalFooter = styled.div`
    position: relative;
    background-color: white;
    height: 80px;
    border-style: solid;
    border-width: 1px;
    border-color: #f3f7fb;
    width: 100%;
    border-top: 1px solid #DFDFDF;
`;
const MRModalSaveCloseBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #143151;
    color: #ffffff;
    width: 145px;
    height: 45px;
    border-radius: 4px;
    border: 1px solid #143151;
    margin-left: 5px;
    font-size: 15px;
    margin-left: 10px;
    float: right;
    cursor: pointer;
`;
const MRModalTextarea = styled.textarea`
    width: 99%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #000000;
    font-family: 'Montserrat', sans-serif;
`;
const MRModalCancelBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #758597;
    border: 1px solid #dadada;
    width: 94px;
    height: 43px;
    border-radius: 4px;
    font-size: 12px;
    float: right;
    cursor: pointer;
    font-family: "DMSans-Medium", "DM Sans Medium", "DM Sans", sans-serif;
    font-weight: 500;
`;
const MRModalSelect = styled.select`
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: "Roboto-Regular", "Roboto", sans-serif;
    color: #758597;      
    padding: 10px;
    font-weight: 600;
`;
const MRModalInput = styled.input`
    height: 25px;
    width: 98%;
    box-shadow: rgb(196, 196, 196) 0px 0px 4px;
    color: #758597;   
    font-family: "Roboto-Regular", "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 600;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(255, 255, 255);
    border-image: initial;
    border-radius: 3px;
    padding: 6px;
`;
const ModalNameInputDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top:30px;
    color: #282828;
    font-family: 'Montserrat', sans-serif;
`;
const CSLDateCover = styled.div`   
    width: 284px;
    border-radius: 3px;
    height: 33px;
    padding-top: 5px;
    padding-left: 10px;
    background-color: #fff;
    border: 1px solid #ffffff;
    box-sizing: border-box; 
    box-shadow: 0 0 4px #c4c4c4;
    color: ${process.env.DEFAULT_TEXT_COLOR};   
`;
const UserHr = styled.div`
border-top: 2px solid #E9EBED;
`;
const SpacerXL = styled.div`
    height: 20px;
`;
const SpacerL = styled.div`
    height: 10px;
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 151%;
  width: 82%;
  background-color: #ffffff;
  position: absolute;
  left: 7%;
  top: 30px;
  z-index: 1002;
  opacity: 0.8;
`;

const ModalNameLabelDivReference = styled.div`
    height: auto;
    width: 100%;    
    font-weight: 600;
    color: #282828;    
    display:inline;
`;
const ModalNameLabelDivReferenceID = styled.div`
    height: auto;
    width: 100%;    
    font-weight: 600;
    color: #282828;
    padding-left: 5px;
    display:inline;
`;
const ModalNameLabelDivReference1 = styled.div`
    height: auto;
    width: 100%;
    padding-top: 48px;
    font-weight: 600;
    color: #212121;
    display:inline;
`;
const ScoreBodyContainer = styled.div`
    padding: 10px;
    text-align: left;
    overflow-y: scroll;
    height: 555px;
`;
const MRModalBodyInner = styled.div`
    border-radius: 4px;
    padding: 20px;
    background-color: #e7f0f7;    
    margin-bottom: 25px;
    color: #537386; 
    border: 1px solid #c4cfd6; 
    font-family: "Roboto-Medium", "Roboto Medium", "Roboto", sans-serif;
    font-weight: 600;
`;
const BodyTextHeader = styled.div`     
    color: #537386;     
    font-family: "Roboto-Medium", "Roboto Medium", "Roboto", sans-serif;
    font-weight: 600;
`;
const BodyText = styled.div`     
    color: #537386;     
    font-family: "Roboto-Medium", "Roboto Medium", "Roboto", sans-serif;
    font-weight: 500;
    margin-top: 10px;
`;

class TeamViewIncidentModal extends React.Component
{
    state = {
        ready: false,
        Ref_ID: '',
        groupcompanyName: "",
        incident_title: "",
        breachType_name: "",
        incidentType_name: "",
        breachdate_date: new Date(),
        created_at: new Date(),
        dateofDetection: new Date(),
        closed_date: "-",
        user_name: "",
        breach_deatils: "",
        isinternal_external_incident: "",
        department_name: "",
        impact_type: "",
        est_los_val: "",
        act_los_val: "",
        est_thi_val: "",
        act_thi_val: "",
        fin_impact_cal_method: "",
        com_paid_date: new Date(),
        breach_cur_files_json: null,
        finimp_cur_files_json: null,
        evdence_cur_files_json: null,
        disciplinaryAction_cur_files_json: null,
        is_reportability: "No",
        submittedBy_name: "",

        rootCauseName: "",
        incidentImpactName: "",
        resolutionOwnerName: "",
        riskCategoryName: "",
        breach_binFiles: [],
        finimp_binFiles: [],
        evdence_binFiles: [],
        disciplinaryAction_binFiles: [],

        reported_date_view: "",
        regulator_view: "",
        rule_reference_view: "",
        trading_error_view: "",
        fpp_name_view: "",
        action_required_view: "",
        investigation_details_view: "",
        assign_data: null,
        third_party_details: "",
        isFinancialSectionShow: false
    };
    constructor(props) {
        super(props);
    }

    componentDidMount(){
        let form_data = Store.getStoreData('cur_q_modal_data');
        let task_json = JSON.parse(form_data.task_json);

        let gc_assoc = Store.getStoreData('gc_assoc');
        let gcid = parseInt(form_data.company_id) < 100000 ? parseInt(Store.getStoreData('gc_company')[0].id) : (parseInt(form_data.company_id)%100000);
        let gcname = "";
        if(gcid in gc_assoc){
            gcname = gc_assoc[gcid];
        }

        let incident_title = 'incident_title' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.incident_title : "";
        let breachType_name = task_json.object_data.headerinfo.breach_type === 'conduct_rules_breach' ? 'Conduct Rules Breach' : 'Incident'
        let incidentType_name = '-';
        if(breachType_name === 'Incident'){
            incidentType_name = 'incidentType_name' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.incidentType_name : "-"
        }

        const breachdate_obj = new Date(task_json.object_data.headerinfo.breach_date);
        const breachdate_day = breachdate_obj.getDate() < 10 ? "0"+breachdate_obj.getDate().toString() : breachdate_obj.getDate();
        const breachdate_month = breachdate_obj.getMonth()+1 < 10 ? `0${(breachdate_obj.getMonth() + 1).toString()}` : breachdate_obj.getMonth()+1;
        const breachdate_year = breachdate_obj.getFullYear();
        const breachdate_date = `${breachdate_day}/${breachdate_month}/${breachdate_year}`;

        const created_at_obj = new Date(form_data.created_at);
        const created_at_day = created_at_obj.getDate() < 10 ? "0"+created_at_obj.getDate().toString() : created_at_obj.getDate();
        const created_at_month = created_at_obj.getMonth()+1 < 10 ? `0${(created_at_obj.getMonth() + 1).toString()}` : created_at_obj.getMonth()+1;
        const created_at_year = created_at_obj.getFullYear();
        const created_at_date = `${created_at_day}/${created_at_month}/${created_at_year}`;

        let dateofDetection = 'dateofDetection' in task_json.object_data.headerinfo ? new Date(task_json.object_data.headerinfo.dateofDetection) : "-";
        if(dateofDetection !== '-'){
            const dateofDetection_obj = new Date(dateofDetection)
            const dateofDetection_day = dateofDetection_obj.getDate() < 10 ? "0"+dateofDetection_obj.getDate().toString() : dateofDetection_obj.getDate();
            const dateofDetection_month = dateofDetection_obj.getMonth()+1 < 10 ? `0${(dateofDetection_obj.getMonth() + 1).toString()}` : dateofDetection_obj.getMonth()+1;
            const dateofDetection_year = dateofDetection_obj.getFullYear();
            dateofDetection = `${dateofDetection_day}/${dateofDetection_month}/${dateofDetection_year}`;
        }


        console.log("contacts==>", Store.getStoreData('contacts'))

        let allContacts = Store.getStoreData('contacts');
        let user_name = '';
        if(task_json.object_data.headerinfo.conduct_rule_user_id !== null){
            user_name = allContacts[task_json.object_data.headerinfo.conduct_rule_user_id]
        }

        let submittedBy_name = allContacts[form_data.contact_id]

        let qtext = task_json.object_data.headerinfo.breach_deatils
        if(Utils.isBase64(task_json.object_data.headerinfo.breach_deatils)){
            qtext = window.decodeURIComponent(atob(qtext))
        }

        let third_party_details = 'third_party_details' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.third_party_details : "";
        let qtext_third_party_details = third_party_details;
        if(Utils.isBase64(third_party_details)){
            qtext_third_party_details = window.decodeURIComponent(atob(qtext_third_party_details))
        }

        let isinternal_external_incident = "";
        let department_name = "-";
        if('isinternal_external_incident' in task_json.object_data.headerinfo){
            if(task_json.object_data.headerinfo.isinternal_external_incident === 0){
                isinternal_external_incident = "External";
            }else if(task_json.object_data.headerinfo.isinternal_external_incident === 1){
                isinternal_external_incident = "Internal";
                if(task_json.object_data.headerinfo.department_name !== ""){
                    department_name = task_json.object_data.headerinfo.department_name;
                }
            }
        }

        let impact_type = 'impact_type' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.impact_type !== '' ? task_json.object_data.headerinfo.impact_type : "-" : "-";
        let est_los_val = 'est_los_val' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.est_los_val !== '' ? task_json.object_data.headerinfo.est_los_val : "-" : "-";
        let act_los_val = 'act_los_val' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.act_los_val !== '' ? task_json.object_data.headerinfo.act_los_val : "-" : "-";
        let est_thi_val = 'est_thi_val' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.est_thi_val !== '' ? task_json.object_data.headerinfo.est_thi_val : "-" : "-";
        let act_thi_val = 'act_thi_val' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.act_thi_val !== '' ? task_json.object_data.headerinfo.act_thi_val : "-" : "-";
        let fin_impact_cal_method = 'fin_impact_cal_method' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.fin_impact_cal_method !== '' ? task_json.object_data.headerinfo.fin_impact_cal_method : "-" : "-";
        let com_paid_date = 'com_paid_date' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.com_paid_date !== '' ? task_json.object_data.headerinfo.com_paid_date : "-" : "-";

        if(com_paid_date !== '-'){
            const com_paid_obj = new Date(com_paid_date);
            const com_paid_day = com_paid_obj.getDate() < 10 ? "0"+com_paid_obj.getDate().toString() : com_paid_obj.getDate();
            const com_paid_month = com_paid_obj.getMonth()+1 < 10 ? `0${(com_paid_obj.getMonth() + 1).toString()}` : com_paid_obj.getMonth()+1;
            const com_paid_year = com_paid_obj.getFullYear();
            com_paid_date = `${com_paid_day}/${com_paid_month}/${com_paid_year}`;
        }

        let breach_cur_files_json = []
        let finimp_cur_files_json = []
        let evdence_cur_files_json = []
        let disciplinaryAction_cur_files_json = []

        if('bin_files' in  task_json.object_data) {
            task_json.object_data.bin_files.forEach((item) => {
                if("type" in item && item.type === 'breach_resource'){
                    breach_cur_files_json.push(item);
                }else if("type" in item && item.type === 'evidence_resource'){
                    evdence_cur_files_json.push(item);
                }else if("type" in item && item.type === 'finimp_resource'){
                    finimp_cur_files_json.push(item);
                }else{
                    disciplinaryAction_cur_files_json.push(item);
                }
            });
        }

        let is_reportability = 'is_reportability' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.is_reportability === 0 ? "No" : "Yes" : "-";



        let reasonforbreachlist = {};
        if('reason_for_Breach' in Store.getStoreData('module_config') && Store.getStoreData('module_config').reason_for_Breach.length !== 0){
            Store.getStoreData('module_config').reason_for_Breach.forEach((item) => {
                if(item.isActive === 1){
                    reasonforbreachlist[item.reasonforBreachID.toString()] = item.reasonforBreachName;
                }
            })
        }

        let rootCauseName = "-";
        if('reasonforbreach' in task_json.object_data.headerinfo && task_json.object_data.headerinfo.reasonforbreach in reasonforbreachlist){
            rootCauseName = reasonforbreachlist[task_json.object_data.headerinfo.reasonforbreach];
        }

        let breachimpactlist = {};
        if('breach_impact' in Store.getStoreData('module_config') && Store.getStoreData('module_config').breach_impact.length !== 0){
            Store.getStoreData('module_config').breach_impact.forEach((item) => {
                if(item.isActive === 1){
                    breachimpactlist[item.breachImpactID.toString()] = item.breachImpactName;
                }
            })
        }

        let incidentImpactName = "-";
        if('breachimpact' in task_json.object_data.headerinfo && task_json.object_data.headerinfo.breachimpact in breachimpactlist){
            incidentImpactName = breachimpactlist[task_json.object_data.headerinfo.breachimpact];
        }

        //new
        let regulator_types_object = {};
        if('regulator_types' in Store.getStoreData('module_config') && Store.getStoreData('module_config').regulator_types.length !== 0){
            Store.getStoreData('module_config').regulator_types.forEach((item) => {
                if(item.isActive === 1){
                    regulator_types_object[item.id.toString()] = item.ragulatorname;
                }
            })
        }

        let reported_date_date = '-';
        let regulator_view = "-";
        let rule_reference_view = "-";
        let trading_error_view = "-"
        let fpp_name_view = "-"
        if(is_reportability === 'Yes'){
            const reported_date_obj = new Date(task_json.object_data.headerinfo.reported_date);
            const reported_date_day = reported_date_obj.getDate() < 10 ? "0"+reported_date_obj.getDate().toString() : reported_date_obj.getDate();
            const reported_date_month = reported_date_obj.getMonth()+1 < 10 ? `0${(reported_date_obj.getMonth() + 1).toString()}` : reported_date_obj.getMonth()+1;
            const reported_date_year = reported_date_obj.getFullYear();
            reported_date_date = `${reported_date_day}/${reported_date_month}/${reported_date_year}`;

            if('regulator_id' in task_json.object_data.headerinfo && task_json.object_data.headerinfo.regulator_id in regulator_types_object){
                regulator_view = regulator_types_object[task_json.object_data.headerinfo.regulator_id];
            }
            if('rule_reference' in task_json.object_data.headerinfo && task_json.object_data.headerinfo.rule_reference !== ''){
                rule_reference_view = task_json.object_data.headerinfo.rule_reference;
            }
            if('istrading_error' in task_json.object_data.headerinfo && task_json.object_data.headerinfo.istrading_error === 1){
                trading_error_view = "Yes";
            }else if('istrading_error' in task_json.object_data.headerinfo && task_json.object_data.headerinfo.istrading_error === 0){
                trading_error_view = "No";
            }
            if('fpp_name' in task_json.object_data.headerinfo && task_json.object_data.headerinfo.fpp_name !== ''){
                fpp_name_view = task_json.object_data.headerinfo.fpp_name;
            }
        }

        let action_required_view = 'disciplinary_action' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.disciplinary_action === 'yes' ? "Yes" : "No" : "-";

        let investigation_details_view = '-';
        if(action_required_view === 'Yes'){
            if('details_of_investigation' in task_json.object_data.headerinfo && task_json.object_data.headerinfo.details_of_investigation !== ''){
                investigation_details_view = task_json.object_data.headerinfo.details_of_investigation;
            }
        }

        let assign_data = {}
        assign_data.additional_fields = "additional_fields" in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.additional_fields : [];

        //resolutionOwnerName start
        let resolution_owner_listObject = {};
        if('business_areas' in Store.getStoreData('module_config') && Store.getStoreData('module_config').business_areas.length !== 0){
			Store.getStoreData('module_config').business_areas.forEach((item) => {
				if(item.isActive === 1){
					resolution_owner_listObject[item.businessAreaID] = item.businessAreaName
				}
			})
        }
		let review_list_all = Store.getStoreData('reviewee_list');
		let user_roles = Store.getStoreData('userroles');
		for(let k of review_list_all){
			if(k.ID in user_roles && user_roles[k.ID.toString()] !== 'no_access'){
				resolution_owner_listObject[k.ContactName + '-' + k.ID] = k.ContactName;
			}
		}

        let resolutionOwnerName = "-";
        if('ownershipresolution' in task_json.object_data.headerinfo && task_json.object_data.headerinfo.ownershipresolution in resolution_owner_listObject){
            resolutionOwnerName = resolution_owner_listObject[task_json.object_data.headerinfo.ownershipresolution];
        }
        //resolutionOwnerName end
        //riskCategoryName start
        let riskCategoryName = "-";
        let riskCategoryValue = "";
        if('selectedRiskCategory' in task_json.object_data.headerinfo && task_json.object_data.headerinfo.selectedRiskCategory.length !== 0){
            let rskcate = task_json.object_data.headerinfo.selectedRiskCategory;
            let i=0;
            for(let d of rskcate){
                riskCategoryValue += d.label;
                if(i < rskcate.length - 1) {
                    riskCategoryValue += ', '
                }
                i++;
            }
        }
        if(riskCategoryValue !== ''){
            riskCategoryName = riskCategoryValue;
        }
        //riskCategoryName end

        let isFinancialSectionShow = false;
        if('financial_impact' in Store.getStoreData('module_config') && 'impact_type' in Store.getStoreData('module_config').financial_impact && Store.getStoreData('module_config').financial_impact.impact_type === true){
            isFinancialSectionShow = true;
        }
        if('financial_impact' in Store.getStoreData('module_config') && 'record_cost_of_incident' in Store.getStoreData('module_config').financial_impact && Store.getStoreData('module_config').financial_impact.record_cost_of_incident === true){
            isFinancialSectionShow = true;
        }
        if('financial_impact' in Store.getStoreData('module_config') && 'record_third_party_costs' in Store.getStoreData('module_config').financial_impact && Store.getStoreData('module_config').financial_impact.record_third_party_costs === true){
            isFinancialSectionShow = true;
        }
        if('financial_impact' in Store.getStoreData('module_config') && 'financial_impact_calculation_methodology' in Store.getStoreData('module_config').financial_impact && Store.getStoreData('module_config').financial_impact.financial_impact_calculation_methodology === true){
            isFinancialSectionShow = true;
        }
        if('financial_impact' in Store.getStoreData('module_config') && 'compensation_paid_date' in Store.getStoreData('module_config').financial_impact && Store.getStoreData('module_config').financial_impact.compensation_paid_date === true){
            isFinancialSectionShow = true;
        }

        this.setState({
            Ref_ID: task_json.object_data.ref_id,
            groupcompanyName: gcname,
            incident_title,
            breachType_name,
            incidentType_name,
            breachdate_date,
            created_at: created_at_date,
            dateofDetection: dateofDetection,
            user_name,
            breach_deatils: qtext,
            third_party_details: qtext_third_party_details,
            isinternal_external_incident,
            department_name,
            impact_type,
            est_los_val,
            act_los_val,
            est_thi_val,
            act_thi_val,
            fin_impact_cal_method,
            com_paid_date,
            breach_cur_files_json: breach_cur_files_json,
            finimp_cur_files_json: finimp_cur_files_json,
            evdence_cur_files_json: evdence_cur_files_json,
            disciplinaryAction_cur_files_json : disciplinaryAction_cur_files_json,
            is_reportability,
            submittedBy_name,
            rootCauseName,
            incidentImpactName,
            resolutionOwnerName,
            riskCategoryName,

            reported_date_view: reported_date_date,
            regulator_view,
            rule_reference_view,
            trading_error_view,
            fpp_name_view,
            action_required_view,
            investigation_details_view,
            assign_data: assign_data,
            isFinancialSectionShow,
            ready: true
        })
    }

    filesLoaded = (files) => {
        let contact_id = Store.getStoreData('contact_id');
        for(let i=0; i < files.length; i++) {
            files[i].name = files[i].name.replace('&', '');
            if(!('user_id' in files[i])) {
                files[i].user_id = contact_id;
                files[i].upload_date = Date.now();
                files[i].type = "breach_resource"
            }
        }
        this.setState({breach_binFiles: files});
    }

    filesLoaded_finimp = (files) => {
        let contact_id = Store.getStoreData('contact_id');
        for(let i=0; i < files.length; i++) {
            files[i].name = files[i].name.replace('&', '');
            if(!('user_id' in files[i])) {
                files[i].user_id = contact_id;
                files[i].upload_date = Date.now();
                files[i].type = "finimp_resource"
            }
        }
        this.setState({finimp_binFiles: files});
    }

    filesLoaded_evidence = (files) => {
        let contact_id = Store.getStoreData('contact_id');
        for(let i=0; i < files.length; i++) {
            files[i].name = files[i].name.replace('&', '');
            if(!('user_id' in files[i])) {
                files[i].user_id = contact_id;
                files[i].upload_date = Date.now();
                files[i].type = "evidence_resource"
            }
        }
        this.setState({evdence_binFiles: files});
    }

    filesLoaded_disciplinaryaction = (files) => {
        let contact_id = Store.getStoreData('contact_id');
        for(let i=0; i < files.length; i++) {
            files[i].name = files[i].name.replace('&', '');
            if(!('user_id' in files[i])) {
                files[i].user_id = contact_id;
                files[i].upload_date = Date.now();
                files[i].type = "disciplinary_resource"
            }

        }
        this.setState({disciplinaryAction_binFiles: files});
    }

    closeView = () => {
        if(Store.getStoreData('isreferenceUrl') === true){
            if(Store.getStoreData('isReferenceEmail') === true){
                window.location = '/';
            }else{
                this.props.closeTaskModal();
            }
        } else {
            this.props.closeModal();
        }
    }

    viewGenerateField = (data, data_container) => {
        if(data.type === 'Date'){
            let dateValue = "";
            if(data.value !== ''){
                const reported_date_obj = new Date(data.value);
                const reported_date_day = reported_date_obj.getDate() < 10 ? "0"+reported_date_obj.getDate().toString() : reported_date_obj.getDate();
                const reported_date_month = reported_date_obj.getMonth()+1 < 10 ? `0${(reported_date_obj.getMonth() + 1).toString()}` : reported_date_obj.getMonth()+1;
                const reported_date_year = reported_date_obj.getFullYear();
                dateValue = `${reported_date_day}/${reported_date_month}/${reported_date_year}`;
            }
            return (
                <div key={data.name} style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                    <div style={{display: "inline-block"}}>
                        <BodyTextHeader>{data.nickname}</BodyTextHeader>
                        <BodyText>{dateValue}</BodyText>
                    </div>
                </div>
            );

        }
        if(data.type === 'User List'){
            let contacts =  Store.getStoreData('contacts');
            let userName = "";
            if(data.value !== ''){
                userName = data.value in contacts ? contacts[data.value] : "";
            }
            return (
                <div key={data.name} style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                    <div style={{display: "inline-block"}}>
                        <BodyTextHeader>{data.nickname}</BodyTextHeader>
                        <BodyText>{userName}</BodyText>
                    </div>
                </div>
            );
        }
        if(data.type !== 'Date' || data.type !== 'User List'){
            return (
                <div key={data.name} style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                    <div style={{display: "inline-block"}}>
                        <BodyTextHeader>{data.nickname}</BodyTextHeader>
                        <BodyText>{data.value}</BodyText>
                    </div>
                </div>
            );
        }
    }

    render()
    {
        if(!this.state.ready) {
            return(<div>Loading...</div>);
        }
        return (
            <MRModalContainer>
                <MRModalHeader>
                    <MRModalHeaderText>{this.state.Ref_ID}</MRModalHeaderText>
                    <MRModalHeaderCloseBtn onClick={this.closeView}><FaTimes /></MRModalHeaderCloseBtn>
                    <div style={{clear: "both"}}></div>
                </MRModalHeader>
                <ScoreBodyContainer>
                <MRModalBody style={{paddingLeft: "25px", paddingRight: "25px"}}>
                    <MRModalBodyInner>
                        You are viewing a read only version of this Incident submitted by {this.state.submittedBy_name}. You do not have the required permissions to make any changes.
                    </MRModalBodyInner>
                    <MRModalBodyInner>
                        <div style={{display: "inline-block", width: "100%"}}>
                            <BodyTextHeader style={{fontSize: "16px",color: "#3b5361"}}>Incident Details</BodyTextHeader>
                        </div>
                        <div style={{display: "inline-block", width: "100%", marginTop: "12px"}}>
                            <div style={{display: "inline-block", width: "50%"}}>
                                <BodyTextHeader>Incident ID</BodyTextHeader>
                                <BodyText>{this.state.Ref_ID}</BodyText>
                            </div>
                            <div style={{display: "inline-block", width: "50%"}}>
                                <BodyTextHeader>Company</BodyTextHeader>
                                <BodyText>{this.state.groupcompanyName}</BodyText>
                            </div>
                        </div>
                        <div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                            <div style={{display: "inline-block", width: "50%"}}>
                                <BodyTextHeader>Incident Title</BodyTextHeader>
                                <BodyText>{this.state.incident_title}</BodyText>
                            </div>
                            <div style={{display: "inline-block", width: "50%"}}>
                                <BodyTextHeader>Created Date</BodyTextHeader>
                                <BodyText>{this.state.created_at}</BodyText>
                            </div>
                        </div>
                        <div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                            <div style={{display: "inline-block", width: "50%"}}>
                                <BodyTextHeader>Incident Category</BodyTextHeader>
                                <BodyText>{this.state.breachType_name}</BodyText>
                            </div>
                            <div style={{display: "inline-block", width: "50%"}}>
                                <BodyTextHeader>Incident Type</BodyTextHeader>
                                <BodyText>{this.state.incidentType_name}</BodyText>
                            </div>
                        </div>
                        <div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                            <div style={{display: "inline-block", width: "50%"}}>
                                <BodyTextHeader>Incident Date</BodyTextHeader>
                                <BodyText>{this.state.breachdate_date}</BodyText>
                            </div>
                            <div style={{display: "inline-block", width: "50%"}}>
                                <BodyTextHeader>Date of Detection</BodyTextHeader>
                                <BodyText>{this.state.dateofDetection}</BodyText>
                            </div>
                        </div>
                        <div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                            <div style={{display: "inline-block", width: "50%"}}>
                                <BodyTextHeader>Closed Date</BodyTextHeader>
                                <BodyText>{this.state.closed_date}</BodyText>
                            </div>
                            <div style={{display: "inline-block", width: "50%"}}>
                                <BodyTextHeader>User</BodyTextHeader>
                                <BodyText>{this.state.user_name}</BodyText>
                            </div>
                        </div>
                        <div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                            <div style={{display: "inline-block"}}>
                                <BodyTextHeader>Incident Details</BodyTextHeader>
                                <div dangerouslySetInnerHTML={{__html: this.state.breach_deatils}} style={{textAlign: "justify", fontFamily: "'Montserrat', sans-serif", color: "#537386",fontWeight: "500", marginTop: "10px"}}></div>
                                {/* <BodyText>{this.state.breach_deatils}</BodyText> */}
                            </div>
                        </div>
                        <div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                            <div style={{display: "inline-block", width: "50%"}}>
                                <BodyTextHeader>Internal/External?</BodyTextHeader>
                                <BodyText>{this.state.isinternal_external_incident}</BodyText>
                            </div>
                            <div style={{display: "inline-block", width: "50%"}}>
                                <BodyTextHeader>Department</BodyTextHeader>
                                <BodyText>{this.state.department_name}</BodyText>
                            </div>
                        </div>
                        <div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                            <div style={{display: "inline-block"}}>
                                <BodyTextHeader>Third Party Details</BodyTextHeader>
                                <div dangerouslySetInnerHTML={{__html: this.state.third_party_details}} style={{textAlign: "justify", fontFamily: "'Montserrat', sans-serif", color: "#537386",fontWeight: "500", marginTop: "10px"}}></div>
                            </div>
                        </div>
                        <div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                            <div style={{display: "inline-block", width: "100%"}}>
                                <BodyTextHeader>Attachments</BodyTextHeader>
                                <BodyText><Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.breach_cur_files_json} addnew={false}/></BodyText>
                            </div>
                        </div>
                        {
                            (() => {
                                if(this.state.isFinancialSectionShow){
                                    return(<div>
                                        <div style={{display: "inline-block", width: "100%", marginTop: "10px"}}>
                                            <BodyTextHeader style={{fontSize: "16px",color: "#3b5361"}}>Financial Impact</BodyTextHeader>
                                        </div>
                                        {
                                            (() => {
                                                if('financial_impact' in Store.getStoreData('module_config') && 'impact_type' in Store.getStoreData('module_config').financial_impact && Store.getStoreData('module_config').financial_impact.impact_type === true || 'financial_impact' in Store.getStoreData('module_config') && 'record_cost_of_incident' in Store.getStoreData('module_config').financial_impact && Store.getStoreData('module_config').financial_impact.record_cost_of_incident === true){
                                                    return(<div style={{display: "inline-block", width: "100%", marginTop: "12px"}}>
                                                    {
                                                        (() => {
                                                            if('financial_impact' in Store.getStoreData('module_config') && 'impact_type' in Store.getStoreData('module_config').financial_impact && Store.getStoreData('module_config').financial_impact.impact_type === true){
                                                                return(<div style={{display: "inline-block", width: "50%"}}>
                                                                <BodyTextHeader>Impact Type</BodyTextHeader>
                                                                <BodyText>{this.state.impact_type}</BodyText>
                                                            </div>)
                                                            }
                                                        })()
                                                    }
                                                    {
                                                        (() => {
                                                            if('financial_impact' in Store.getStoreData('module_config') && 'record_cost_of_incident' in Store.getStoreData('module_config').financial_impact && Store.getStoreData('module_config').financial_impact.record_cost_of_incident === true){
                                                                return(<div style={{display: "inline-block", width: "50%"}}>
                                                                <BodyTextHeader>Estimated Loss</BodyTextHeader>
                                                                <BodyText>{this.state.est_los_val}</BodyText>
                                                            </div>)
                                                            }
                                                        })()
                                                    }
                                                </div>)
                                                }
                                            })()
                                        }
                                        {
                                            (() => {
                                                if('financial_impact' in Store.getStoreData('module_config') && 'record_cost_of_incident' in Store.getStoreData('module_config').financial_impact && Store.getStoreData('module_config').financial_impact.record_cost_of_incident === true || 'financial_impact' in Store.getStoreData('module_config') && 'record_third_party_costs' in Store.getStoreData('module_config').financial_impact && Store.getStoreData('module_config').financial_impact.record_third_party_costs === true){
                                                    return(<div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                                                    {

                                                        (() => {
                                                            if('financial_impact' in Store.getStoreData('module_config') && 'record_cost_of_incident' in Store.getStoreData('module_config').financial_impact && Store.getStoreData('module_config').financial_impact.record_cost_of_incident === true){
                                                                return(<div style={{display: "inline-block", width: "50%"}}>
                                                                <BodyTextHeader>Actual Loss</BodyTextHeader>
                                                                <BodyText>{this.state.act_los_val}</BodyText>
                                                            </div>)
                                                            }
                                                        })()
                                                    }
                                                    {
                                                        (() => {
                                                            if('financial_impact' in Store.getStoreData('module_config') && 'record_third_party_costs' in Store.getStoreData('module_config').financial_impact && Store.getStoreData('module_config').financial_impact.record_third_party_costs === true){
                                                             return(<div style={{display: "inline-block", width: "50%"}}>
                                                             <BodyTextHeader>Estimated 3rd Party Cost</BodyTextHeader>
                                                             <BodyText>{this.state.est_thi_val}</BodyText>
                                                         </div>)
                                                            }
                                                        })()
                                                    }
                                                </div>)
                                                }
                                            })()
                                        }
                                        {
                                            (() => {
                                                if('financial_impact' in Store.getStoreData('module_config') && 'record_third_party_costs' in Store.getStoreData('module_config').financial_impact && Store.getStoreData('module_config').financial_impact.record_third_party_costs === true || 'financial_impact' in Store.getStoreData('module_config') && 'financial_impact_calculation_methodology' in Store.getStoreData('module_config').financial_impact && Store.getStoreData('module_config').financial_impact.financial_impact_calculation_methodology === true){
                                                    return(<div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                                                    {
                                                        (() => {
                                                            if('financial_impact' in Store.getStoreData('module_config') && 'record_third_party_costs' in Store.getStoreData('module_config').financial_impact && Store.getStoreData('module_config').financial_impact.record_third_party_costs === true){
                                                                return(<div style={{display: "inline-block", width: "50%"}}>
                                                                <BodyTextHeader>Actual 3rd Party Cost</BodyTextHeader>
                                                                <BodyText>{this.state.act_thi_val}</BodyText>
                                                            </div>)
                                                            }
                                                        })()
                                                    }
                                                    {
                                                        (() => {
                                                            if('financial_impact' in Store.getStoreData('module_config') && 'financial_impact_calculation_methodology' in Store.getStoreData('module_config').financial_impact && Store.getStoreData('module_config').financial_impact.financial_impact_calculation_methodology === true){
                                                                return(<div style={{display: "inline-block", width: "50%"}}>
                                                                <BodyTextHeader>Financial Impact Calculation</BodyTextHeader>
                                                                <BodyText>{this.state.fin_impact_cal_method}</BodyText>
                                                            </div>)
                                                            }
                                                        })()
                                                    }
                                                </div>)
                                                }
                                            })()
                                        }
                                        {
                                            (() => {
                                                if('financial_impact' in Store.getStoreData('module_config') && 'compensation_paid_date' in Store.getStoreData('module_config').financial_impact && Store.getStoreData('module_config').financial_impact.compensation_paid_date === true){
                                                    return(<div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                                                    <div style={{display: "inline-block", width: "50%"}}>
                                                        <BodyTextHeader>Compensation Paid</BodyTextHeader>
                                                        <BodyText>{this.state.com_paid_date}</BodyText>
                                                    </div>
                                                </div>)
                                                }
                                            })()
                                        }
                                        <div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                                            <div style={{display: "inline-block", width: "100%"}}>
                                                <BodyTextHeader>Attachments</BodyTextHeader>
                                                <BodyText><Dropzone onFilesAdded={this.filesLoaded_finimp} initFiles={this.state.finimp_cur_files_json} addnew={false}/></BodyText>
                                            </div>
                                        </div>
                                    </div>)
                                }
                            })()
                        }
                        <div style={{display: "inline-block", width: "100%", marginTop: "10px"}}>
                            <BodyTextHeader style={{fontSize: "16px",color: "#3b5361"}}>Reportability</BodyTextHeader>
                        </div>
                        <div style={{display: "inline-block", width: "100%", marginTop: "12px"}}>
                            <div style={{display: "inline-block", width: "100%"}}>
                                <BodyTextHeader>Reportable Incident</BodyTextHeader>
                                <BodyText>{this.state.is_reportability}</BodyText>
                            </div>
                        </div>
                        {
                            (() => {
                                if(this.state.is_reportability === 'Yes'){
                                    return(
                                        <div>
                                            <div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                                                <div style={{display: "inline-block", width: "50%"}}>
                                                    <BodyTextHeader>Reported Date</BodyTextHeader>
                                                    <BodyText>{this.state.reported_date_view}</BodyText>
                                                </div>
                                                <div style={{display: "inline-block", width: "50%"}}>
                                                    <BodyTextHeader>Regulator</BodyTextHeader>
                                                    <BodyText>{this.state.regulator_view}</BodyText>
                                                </div>
                                            </div>
                                            <div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                                                <div style={{display: "inline-block"}}>
                                                    <BodyTextHeader>Rule/Reference</BodyTextHeader>
                                                    <BodyText>{this.state.rule_reference_view}</BodyText>
                                                </div>
                                            </div>
                                            <div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                                                <div style={{display: "inline-block", width: "50%"}}>
                                                    <BodyTextHeader>Trading Error</BodyTextHeader>
                                                    <BodyText>{this.state.trading_error_view}</BodyText>
                                                </div>
                                                <div style={{display: "inline-block", width: "50%"}}>
                                                    <BodyTextHeader>Fund/Portfolio/Product Name</BodyTextHeader>
                                                    <BodyText>{this.state.fpp_name_view}</BodyText>
                                                </div>
                                            </div>
                                            <div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                                                <div style={{display: "inline-block", width: "100%"}}>
                                                    <BodyTextHeader>Evidence Attachments</BodyTextHeader>
                                                    <BodyText><Dropzone onFilesAdded={this.filesLoaded_evidence} initFiles={this.state.evdence_cur_files_json} addnew={false}/></BodyText>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            })()
                        }

                        <div style={{display: "inline-block", width: "100%", marginTop: "10px"}}>
                            <BodyTextHeader style={{fontSize: "16px",color: "#3b5361"}}>Disciplinary Action</BodyTextHeader>
                        </div>
                        <div style={{display: "inline-block", width: "100%", marginTop: "12px"}}>
                            <div style={{display: "inline-block", width: "100%"}}>
                                <BodyTextHeader>Action Required</BodyTextHeader>
                                <BodyText>{this.state.action_required_view}</BodyText>
                            </div>
                        </div>
                        {
                            (() => {
                                if(this.state.action_required_view === 'Yes'){
                                    return(
                                        <div>
                                            <div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                                                <div style={{display: "inline-block"}}>
                                                    <BodyTextHeader>Investigation Details</BodyTextHeader>
                                                    <BodyText>{this.state.investigation_details_view}</BodyText>
                                                </div>
                                            </div>
                                            <div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                                                <div style={{display: "inline-block", width: "100%"}}>
                                                    <BodyTextHeader>Investigation Attachments</BodyTextHeader>
                                                    <BodyText><Dropzone onFilesAdded={this.filesLoaded_disciplinaryaction} initFiles={this.state.disciplinaryAction_cur_files_json} addnew={false}/></BodyText>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            })()
                        }

                        <div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                        {
                            (() => {
                                if('form_customisation_root_cause' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.form_customisation_root_cause === 'show_on_entry_form'){
                                    return(<div style={{display: "inline-block", width: "50%"}}>
                                        <BodyTextHeader>Root Cause</BodyTextHeader>
                                        <BodyText>{this.state.rootCauseName}</BodyText>
                                    </div>)
                                }
                            })()
                        }
                        {
                            (() => {
                                if('form_customisation_incident_impact' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.form_customisation_incident_impact === 'show_on_entry_form'){
                                    return(
                                    <div style={{display: "inline-block", width: "50%"}}>
                                        <BodyTextHeader>Incident Impact</BodyTextHeader>
                                        <BodyText>{this.state.incidentImpactName}</BodyText>
                                    </div>
                                    )
                                }
                            })()
                        }
                        </div>
                        <div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                        {
                            (() => {
                                if('form_customisation_resolution_owner' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.form_customisation_resolution_owner === 'show_on_entry_form'){
                                    return(
                                    <div style={{display: "inline-block", width: "50%"}}>
                                        <BodyTextHeader>Resolution Owner</BodyTextHeader>
                                        <BodyText>{this.state.resolutionOwnerName}</BodyText>
                                    </div>
                                    )
                                }
                            })()
                        }
                        {
                            (() => {
                                if('form_customisation_risk_category' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.form_customisation_risk_category === 'show_on_entry_form'){
                                    return(
                                    <div style={{display: "inline-block", width: "50%"}}>
                                        <BodyTextHeader>Risk Category</BodyTextHeader>
                                        <BodyText>{this.state.riskCategoryName}</BodyText>
                                    </div>
                                    )
                                }
                            })()
                        }
                        </div>
                        {
                            (() => {
                                if (this.state.assign_data.additional_fields.length !== 0) {
                                    let custom_html = [];
                                    this.state.assign_data.additional_fields.forEach((item) => {
                                        const field = this.viewGenerateField(item, "additional_fields");
                                        custom_html.push(field);
                                    })
                                    return custom_html;
                                }
                            })()
                        }
                    </MRModalBodyInner>
                </MRModalBody>
                </ScoreBodyContainer>
                <MRModalFooter>
                    <div style={{position: "relative",padding: "17px"}}>
                        <MRModalCancelBtn id="cancel" onClick={this.closeView}>CLOSE</MRModalCancelBtn>
                    </div>
                </MRModalFooter>
            </MRModalContainer>
        );
    }
}
export default TeamViewIncidentModal;
