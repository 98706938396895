import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store.js';
import DatePicker from 'react-date-picker';
import {IoMdRadioButtonOn, IoMdRadioButtonOff} from 'react-icons/io';
import Dropzone from '../Common/Dropzone';
import {FaTimes, FaStarOfLife, FaCalendarAlt} from 'react-icons/fa';
import CSLTextInput from './../BM/CSLTextInput';
import '../../App.css';
import HtmlEditor from './../Common/HtmlEditor';
import BMApprovalTypeAhead from './../Filter/BMApprovalTypeAhead';

const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 80%;
    position: absolute;
    top: 38px;
    z-index: 1001;
    background-color: rgb(243, 247, 251);
    box-shadow: rgb(167, 166, 166) 0px 0px 5px;
    margin-bottom: 10px;
    border-radius: 5px;
    margin-left: 100px;
`;
const MRModalHeader = styled.div`
    background-color: white;
    display: block;
    padding-top: 5px;
    font-size: 14px;
    font-weight: 700;
    height: 100px;    
    width: calc(100% - 10px);
    border-width: 1px 1px 1px 10px;    
    border-left: 9px solid #04ADA8; 
    border-bottom: 1px solid #DFDFDF;
`;
const MRModalHeaderText = styled.div`
    font-weight: 700;
    font-size: 22px;
    float: left;
    margin-top: 24px;
    margin-left: 35px;  
    color: #143151;  
`;
const MRModalSubHeaderText = styled.div`
    font-weight: 700;
    font-size: 13px;
    float: left;
    margin-left: 35px;
    margin-bottom: 20px;
    margin-top: 10px;
    color: #1a3552;
    opacity: 0.58;
    font-family: "Roboto-Medium", "Roboto Medium", "Roboto", sans-serif;
`;
const MRModalHeaderCloseBtn = styled.div`
    position: absolute;
    top: 8px;
    right: 10px;
    box-sizing: border-box;
    margin-top: 24px;
    margin-right: 20px;
    font-size: 25px;
    cursor: pointer;
    font-weight: 200;
`;
const MRModalBody = styled.div`
    position: relative;
    display: block;
    box-sizing: border-box;
    background-color: rgb(243, 247, 251);
    padding: 10px;
    border-radius: 4px;
`;
const MRModalLabel = styled.div`
    color: #1a3552;
    font-weight: 600;
    margin-top: 15px;
    font-size: 14px;
    margin-bottom: 15px;
    opacity: 0.58;
    font-family: "Roboto-Medium", "Roboto Medium", "Roboto", sans-serif;
`;
const MRModalFooter = styled.div`
    position: relative;
    background-color: white;
    height: 80px;
    border-style: solid;
    border-width: 1px;
    border-color: #f3f7fb;
    width: 100%;
    border-top: 1px solid #DFDFDF;
`;
const MRModalSaveCloseBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #143151;
    color: #ffffff;
    width: 145px;
    height: 45px;
    border-radius: 4px;
    border: 1px solid #143151;
    margin-left: 5px;
    font-size: 15px;
    margin-left: 10px;
    float: right;
    cursor: pointer;
`;
const MRModalTextarea = styled.textarea`
    width: 99%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #000000; 
    font-family: "Roboto-Medium", "Roboto Medium", "Roboto", sans-serif;
`;
const MRModalCancelBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #545454;
    border: 2px solid #dadada;
    width: 145px;
    height: 45px;
    border-radius: 4px;
    font-size: 15px;
    float: right;
    cursor: pointer;
`;
const MRModalSelect = styled.select`
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: "Roboto-Regular", "Roboto", sans-serif;
    color: #758597;      
    padding: 10px;
    font-weight: 600;
`;
const MRModalInput = styled.input`
    height: 25px;
    width: 98%;
    box-shadow: rgb(196, 196, 196) 0px 0px 4px;
    color: #758597;
    font-family: "Roboto-Medium", "Roboto Medium", "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 600;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(255, 255, 255);
    border-image: initial;
    border-radius: 3px;
    padding: 6px;
`;
const ModalNameInputDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top:5px;
    color: #282828;
    font-family: 'Montserrat', sans-serif;
`;
const CSLDateCover = styled.div`   
    width: 284px;
    border-radius: 3px;
    height: 33px;
    padding-top: 5px;
    padding-left: 10px;
    background-color: #fff;
    border: 1px solid #ffffff;
    box-sizing: border-box; 
    box-shadow: 0 0 4px #c4c4c4;
    color: #758597; 
`;
const UserHr = styled.div`
border-top: 2px solid #E9EBED;
`;
const SpacerXL = styled.div`
    height: 20px;
`;
const SpacerL = styled.div`
    height: 10px;
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 151%;
  width: 82%;
  background-color: #ffffff;
  position: absolute;
  left: 7%;
  top: 30px;
  z-index: 1002;
  opacity: 0.8;
`;
const FilterBody = styled.div`
	background-color: #ffffff;
	
	margin-top: 2px;
	width: 100%;
	box-sizing: border-box;
`;


class BreachOfRulesSection extends React.Component
{
    state = {        
        ready: false,        
        regulatorLists: [],
        assign_data: null,
        smcrRoleLists: [],
        conductRuleUserLists: [],
        conductRuleUserName: "",
        isFinancialSectionShow: false
    };
    constructor(props) {
        super(props);
    }

    componentDidMount()
	{  
        let review_list = Store.getStoreData('reviewee_list'); 
        let user_roles = Store.getStoreData('userroles');
        let conductRuleUserName = "";
        // let conductRuleUserLists = [];
        // console.log("review_list==>", review_list, this.props.conductRuleUserID)
        // for(let k of review_list){
        //     if(k.ID.toString() === this.props.conductRuleUserID.toString()){
        //         conductRuleUserName = k.ContactName;
        //         conductRuleUserLists.push(k)
        //     }            
        // } 
        if(Store.getStoreData('gc_companies').length === 0){
            let review_list_all = Store.getStoreData('reviewee_list');
            let update_review_list = [];
            for(let k of review_list_all){
                if(k.ID in user_roles && user_roles[k.ID.toString()] !== 'no_access'){
                    update_review_list.push(k);
                }
            }
            review_list = update_review_list;
        }else{
            if(parseInt(this.props.cur_gc_id) === 0){                
                review_list = [];
            }else{
                console.log("review_list222===>", this.props.cur_gc_id)
                let gc_company = Store.getStoreData('gc_company');    
                let list_users = {};               
                for(let gc of gc_company) {                        
                    if(parseInt(gc.id) === parseInt(this.props.cur_gc_id)){
                        list_users = gc.users;
                    }
                }
                let new_review = [];
                let review_list_update = Store.getStoreData('reviewee_list');                
                review_list_update.forEach((user) => {
                    if(user.ID in list_users && user.ID in user_roles && user_roles[user.ID.toString()] === 'bm'){
                        new_review.push(user)
                    }
                    if(user.ID in list_users && user.ID in user_roles && user_roles[user.ID.toString()] === 'team'){
                        new_review.push(user)
                    }
                    if(user.ID in user_roles && user_roles[user.ID.toString()] === 'manager'){
                        new_review.push(user)
                    }
                })
                review_list = new_review;
            }
        }  

        review_list = review_list.sort(function(a,b) {return (a.ContactName > b.ContactName) ? 1 : ((b.ContactName > a.ContactName) ? -1 : 0);} );
        
        let isFinancialSectionShow = false;
        if('financial_impact' in Store.getStoreData('module_config') && 'impact_type' in Store.getStoreData('module_config').financial_impact && Store.getStoreData('module_config').financial_impact.impact_type === true){
            isFinancialSectionShow = true;
        }
        if('financial_impact' in Store.getStoreData('module_config') && 'record_cost_of_incident' in Store.getStoreData('module_config').financial_impact && Store.getStoreData('module_config').financial_impact.record_cost_of_incident === true){
            isFinancialSectionShow = true;
        }
        if('financial_impact' in Store.getStoreData('module_config') && 'record_third_party_costs' in Store.getStoreData('module_config').financial_impact && Store.getStoreData('module_config').financial_impact.record_third_party_costs === true){
            isFinancialSectionShow = true;
        }
        if('financial_impact' in Store.getStoreData('module_config') && 'financial_impact_calculation_methodology' in Store.getStoreData('module_config').financial_impact && Store.getStoreData('module_config').financial_impact.financial_impact_calculation_methodology === true){
            isFinancialSectionShow = true;
        }
        if('financial_impact' in Store.getStoreData('module_config') && 'compensation_paid_date' in Store.getStoreData('module_config').financial_impact && Store.getStoreData('module_config').financial_impact.compensation_paid_date === true){
            isFinancialSectionShow = true;
        }
             
        this.setState({ready:true, assign_data: this.props.assign_data, conductRuleUserName, conductRuleUserLists: review_list, isFinancialSectionShow})
    }

    componentDidUpdate(prevProps)
	{
		if (this.props !== prevProps) {
            let review_list = Store.getStoreData('reviewee_list');  
            let conductRuleUserName = "";
            // let conductRuleUserLists = [];
            // for(let k of review_list){
            //     if(k.ID.toString() === this.props.conductRuleUserID.toString()){
            //         conductRuleUserName = k.ContactName;
            //         conductRuleUserLists.push(k)
            //     }            
            // } 
            let user_roles = Store.getStoreData('userroles');    
            if(Store.getStoreData('gc_companies').length === 0){
                let review_list_all = Store.getStoreData('reviewee_list');
                let update_review_list = [];
                for(let k of review_list_all){
                    if(k.ID in user_roles && user_roles[k.ID.toString()] !== 'no_access'){
                        update_review_list.push(k);
                    }
                }
                review_list = update_review_list;
            }else{
                if(parseInt(this.props.cur_gc_id) === 0){                
                    review_list = [];
                }else{
                    console.log("review_list222===>", this.props.cur_gc_id)
                    let gc_company = Store.getStoreData('gc_company');    
                    let list_users = {};               
                    for(let gc of gc_company) {                        
                        if(parseInt(gc.id) === parseInt(this.props.cur_gc_id)){
                            list_users = gc.users;
                        }
                    }
                    let new_review = [];
                    let review_list_update = Store.getStoreData('reviewee_list');                    
                    review_list_update.forEach((user) => {
                        if(user.ID in list_users && user.ID in user_roles && user_roles[user.ID.toString()] === 'bm'){
                            new_review.push(user)
                        }
                        if(user.ID in list_users && user.ID in user_roles && user_roles[user.ID.toString()] === 'team'){
                            new_review.push(user)
                        }
                        if(user.ID in user_roles && user_roles[user.ID.toString()] === 'manager'){
                            new_review.push(user)
                        }
                    })
                    review_list = new_review;
                }
            }       
            
            review_list = review_list.sort(function(a,b) {return (a.ContactName > b.ContactName) ? 1 : ((b.ContactName > a.ContactName) ? -1 : 0);} );
            
            this.setState({ready:true, assign_data: this.props.assign_data, conductRuleUserName, conductRuleUserLists: review_list})
		}
	}
    
    handlenaSectionIncluded = (ischeck) => (event) => {
        this.props.handleChangeReportable(ischeck)
    }
    handlenaSectionIncludedDis = (ischeck) => (event) => {
        this.props.handlenaSectionIncluded(ischeck)
    }
    handlenaSectionIncludedNew = (ischeck) => (event) => {
        this.props.handleChangeExIntIncd(ischeck)
    }
    handleChangeTradingErrorValue = (ischeck) => (event) => {
        this.props.handleChangeTradingError(ischeck)
    }

    handleChange = (evt) => {        
        //this.setState({[evt.target.name]: evt.target.value});
        this.props.handleChangeAll(evt)
    }

    filesLoaded = (files) => {        
        this.props.filesUpload(files)
    }

    filesLoadednew = (files) => {        
        this.props.filesUploadbreach(files)
    }
    filesLoadedfinimp = (files) => {        
        this.props.filesUploadfinimp(files)
    }
    onChangeBreachDate = (date) => { 
        this.props.ChangeBreachDate(date)       
        //this.setState({next_review_date: date});
    }

    onChangeDateOfDetaction = (date) => { 
        this.props.changeDateofDetection(date)       
        //this.setState({next_review_date: date});
    }
    
    onChangePaidDate = (date) => { 
        this.props.ChangePaidDate(date) 
    }
    onChangeReportedDate = (date) => { 
        this.props.ChangeReportedDate(date)       
        //this.setState({next_review_date: date});
    }

    generateField = (data, data_container) => {
    	return (
    		<div key={data.name}>
    			<MRModalLabel>
    			{
    				(() => {
    					if (data.required) {
    						return (<div>{data.nickname}<span style={{color: "red", paddingLeft: "5px", fontSize: "8px"}}><FaStarOfLife  title={data.nickname+" required"}/></span></div>);
    					} else {
    						return data.nickname;
    					}
    				})()
    			}
    			</MRModalLabel>
    			{
    				(() => {
    					let fieldhtml = <div></div>;
    					switch(data.type) {
    						case "Alpha":
    							fieldhtml = this.createAlphaField(data, data_container);
    							break;
    						case "Numeric":
    							fieldhtml = this.createNumericField(data, data_container);
    							break;    						
                            case "Dropdown":
                                fieldhtml = this.createDropdownField(data, data_container);
                                break;
                            case "Date":
                                fieldhtml = this.createDateField(data, data_container);
                                break;                            
                            case "User List":
                                fieldhtml = this.createUserListField(data, data_container);
                                break;                            
    						default:
    							fieldhtml = fieldhtml;
    					}
                        if (data_container === "additional_fields")
    					return fieldhtml;
    				})()
    			}
    		</div>
    	);
    }

    createDateField = (data, data_container) => {
        console.log("Data==>", data)
        let curValue = data.value !== '' ? data.value : new Date();        
		return (<CSLDateCover id={"date-"+data.name} style={{display: "inline-block"}}>
                    <DatePicker
                            onChange={this.onChangeCustomDate(data.name)}
                            clearIcon={null}                            
                            locale={"en-GB"}
                            calendarIcon={<FaCalendarAlt style={{color: '#243E57'}} />}
                            value={curValue}
                    />
                </CSLDateCover>);        
    }

    createUserListField = (data, data_container) => {
    	return (           
        		<MRModalSelect id={"userlist-"+data.name} onChange={this.handleUserListField(data.type, data_container)} name={data.name} value={data.value}>
                    <option key="sel" value="">Select</option>
        		    {
        		        this.state.conductRuleUserLists.map((opt, index) => {
        		            return(
    	    		            <option key={index} value={opt.ID}>{opt.ContactName}</option>
        		            );
        		        })
        		    }                    
        		</MRModalSelect>
    	);
    }

    handleUserListField = (field_type, data_container) => (event) => {
        event.preventDefault();        
        let assign_data = JSON.parse(JSON.stringify(this.state.assign_data));    
        console.log("event.target.name===>", event.target.name)    
        console.log("assign_data===>", assign_data)
        assign_data.additional_fields.forEach((item) => {
            if (item.name === event.target.name) {
                item.value = event.target.value;
            }
        })
        console.log("ModifyAssigndata+++==>", assign_data)	
        this.props.modifyAssignData(assign_data);        
    }

    onChangeCustomDate = (name) => (date) => {
        //event.preventDefault();        
        let assign_data = JSON.parse(JSON.stringify(this.state.assign_data));  
        console.log("assign_data===>", assign_data) 
        assign_data.additional_fields.forEach((item) => {
            if (item.name === name) {
                item.value = date;
            }
        })        	
        console.log("ModifyAssigndata+++==>", assign_data)	
        this.props.modifyAssignData(assign_data);
    }

    createAlphaField = (data, data_container) => {
		return (<CSLTextInput id={"text-"+data.name} handleTextChange={this.handleTextChange} name={data.name} value={data.value} entity="additional_fields" field="value" />);        
    }

    handleTextChange = (obj) => {
		console.log("obj:", obj);		
		let assign_data = JSON.parse(JSON.stringify(this.state.assign_data));		
		if (obj.entity === null) {
			assign_data[obj.name] = obj.value;
		} else {
			assign_data[obj.entity].forEach(item => {
				if (item.name === obj.name) item[obj.field] = obj.value;
			})
		}		
        this.props.modifyAssignData(assign_data);
	}

    createNumericField = (data, data_container) => {
    	return (<MRModalInput id={"number-"+data.name} type="number" name={data.name} value={data.value} onChange={this.handleAddlField(data.type, data_container)} />);
    }

    createDropdownField = (data, data_container) => {
    	return (
            <div>
        		<MRModalSelect id={"select-"+data.name} onChange={this.handleAddlField(data.type, data_container)} name={data.name} value={data.value}>
                    <option key="sel" value="">Select</option>
        		    {
        		        data.dropdown_options.map((opt, index) => {
        		            return(
    	    		            <option key={index} value={opt}>{opt}</option>
        		            );
        		        })
        		    }
                    {
                        (() => {
                            if (data_container === "additional_fields") {
                                if ("other" in data) {
                                    if (data.other === true) {
                                        return (<option value="other">Other</option>);
                                    }
                                }
                            }
                        })()
                    }
        		</MRModalSelect>
                {
                    (() => {
                        if (data_container === "additional_fields") {
                            if ("other" in data) {
                                if (data.other === true && data.value === "other") {
                                    return (
                                        <div style={{marginTop: "15px"}}>
                                            <div style={{float: "left", width: "20%", boxSizing: "border-box", paddingTop: "12px"}}>
                                                <MRModalLabel style={{margin: "0px"}}>Please give details</MRModalLabel>
                                            </div>
                                            <div style={{float: "left", width: "80%", boxSizing: "border-box"}}>                                                
                                                <CSLTextInput id={"text-"+data.name} entity="additional_fields" name={data.name} value={data.other_value} handleTextChange={this.handleTextChange} field="other_value"/>
                                            </div>
                                            <div style={{clear: "both"}}></div>
                                        </div>
                                    );
                                }
                            }
                        }
                    })()
                }
            </div>
    	);
    }

    handleAddlField = (field_type, data_container) => (event) => {
        event.preventDefault();        
        let assign_data = JSON.parse(JSON.stringify(this.state.assign_data));    
        console.log("event.target.name===>", event.target.name)    
        console.log("assign_data===>", assign_data)    
        console.log("data_container===>", data_container)    
        if (event.target.name === "smcr_role") {
            assign_data[event.target.name] = event.target.value;
            assign_data[data_container].forEach((item) => {
                if (item.name === event.target.name) {
                    item.value = event.target.value;
                }
            })
        } else {
            assign_data[data_container].forEach((item) => {
                if (item.name === event.target.name) {
                    item.value = event.target.value;
                }
            })
        }	
        console.log("ModifyAssigndata+++==>", assign_data)	
        this.props.modifyAssignData(assign_data);        
    }

    filesLoaded1 = (files) => {        
        this.props.filesUpload1(files)
    }

    riskcategoryValueVia = (selected_options) =>{
        this.props.riskcategoryValue(selected_options);
    }

    changeClearVia = (evt) => {
        this.props.changeClear(evt);
    }

    render()
    { 
        const breachdate_obj = new Date(this.props.breachDate);
        const breachdate_day = breachdate_obj.getDate() < 10 ? "0"+breachdate_obj.getDate().toString() : breachdate_obj.getDate();
        const breachdate_month = breachdate_obj.getMonth()+1 < 10 ? `0${(breachdate_obj.getMonth() + 1).toString()}` : breachdate_obj.getMonth()+1;
        const breachdate_year = breachdate_obj.getFullYear();
        const breachdate_date = `${breachdate_day}/${breachdate_month}/${breachdate_year}`;

        const dateofdetaction_obj = new Date(this.props.breachDate);
        const dateofdetaction_day = dateofdetaction_obj.getDate() < 10 ? "0"+dateofdetaction_obj.getDate().toString() : dateofdetaction_obj.getDate();
        const dateofdetaction_month = dateofdetaction_obj.getMonth()+1 < 10 ? `0${(dateofdetaction_obj.getMonth() + 1).toString()}` : dateofdetaction_obj.getMonth()+1;
        const dateofdetaction_year = dateofdetaction_obj.getFullYear();
        const dateofdetaction_date = `${dateofdetaction_day}/${dateofdetaction_month}/${dateofdetaction_year}`;
        
        const reporteddate_obj = new Date(this.props.reportedDate);
        const reporteddate_day = reporteddate_obj.getDate() < 10 ? "0"+reporteddate_obj.getDate().toString() : reporteddate_obj.getDate();
        const reporteddate_month = reporteddate_obj.getMonth()+1 < 10 ? `0${(reporteddate_obj.getMonth() + 1).toString()}` : reporteddate_obj.getMonth()+1;
        const reporteddate_year = reporteddate_obj.getFullYear();
        const reporteddate_date = `${reporteddate_day}/${reporteddate_month}/${reporteddate_year}`;

        let module_config = Store.getStoreData('module_config');

        if(!this.state.ready){
            return(<div>Loading...</div>)
        } 
        return (
            <div>
                {
                    (() => {
                        if(this.props.incident_types.length !== 0){
                            return(<div>
                                <MRModalLabel>INCIDENT TYPE</MRModalLabel>
                                <MRModalSelect id="incidentType_id" name="incidentType_id" onChange={this.handleChange} value={this.props.incidentType_id} disabled={this.props.disabledCheck}>
                                    <option value="">Select</option>              
                                    {
                                        this.props.incident_types.map((rc,index) => {
                                            return(
                                            <option key={index} value={rc.incidentTypeID}>{rc.incidentTypeName}</option>
                                            );
                                        })
                                    }                                                
                                </MRModalSelect> 
                            </div>)
                        }
                    })()
                }
                
                <SpacerXL/>
                <UserHr />                
                <div style={{display: "inline-block", width: "100%"}}> 
                    <div style={{display: "inline-block", width: "50%"}}>
                        <MRModalLabel>INCIDENT DATE</MRModalLabel>
                        {
                            (() => {
                                if(this.props.disabledCheck){
                                    return( <MRModalInput style={{height: "22px", width: "35%"}} type="text" defaultValue={breachdate_date} readOnly/>   )
                                }else{
                                    return(                               
                                        <CSLDateCover id="breach_date" style={{display: "inline-block"}}>
                                            <DatePicker
                                                    onChange={this.onChangeBreachDate}
                                                    clearIcon={null}                                                    
                                                    locale={"en-GB"}
                                                    calendarIcon={<FaCalendarAlt style={{color: '#243E57'}} />}
                                                    value={this.props.breachDate}
                                            />
                                        </CSLDateCover>  
                                    )
                                }
                            })()
                        } 
                    </div>
                    <div style={{display: "inline-block", width: "50%"}}>
                        <MRModalLabel>DATE OF DETECTION</MRModalLabel>
                        {
                            (() => {
                                if(this.props.disabledCheck){
                                    return( <MRModalInput style={{height: "22px", width: "35%"}} type="text" defaultValue={dateofdetaction_date} readOnly/>   )
                                }else{
                                    return(                               
                                        <CSLDateCover id="breach_date" style={{display: "inline-block"}}>
                                            <DatePicker
                                                    onChange={this.onChangeDateOfDetaction}
                                                    clearIcon={null}                                                    
                                                    locale={"en-GB"}
                                                    calendarIcon={<FaCalendarAlt style={{color: '#243E57'}} />}
                                                    value={this.props.dateofDetection}
                                                    minDate={this.props.breachDate}
                                            />
                                        </CSLDateCover>  
                                    )
                                }
                            })()
                        } 
                    </div>
                </div>
                <MRModalLabel>INDIVIDUAL REPORTING THE INCIDENT</MRModalLabel>
                <MRModalSelect id="user_id" name="conductRuleUserID" onChange={this.handleChange} value={this.props.conductRuleUserID} disabled={this.props.disabledCheck}>
                    <option value="">Select</option>      
                    {
                        this.state.conductRuleUserLists.map((rc,index) => {
                            return(
                            <option key={index} value={rc.ID}>{rc.ContactName}</option>
                            );
                        })
                    }                                   
                </MRModalSelect>
                {/* <MRModalInput name="conductRuleUserID" value={this.state.conductRuleUserName} disabled={true}/> */}
                <MRModalLabel>INCIDENT DETAILS</MRModalLabel>                
                <div style={{width: "100%"}}><HtmlEditor content={this.props.breachDetails}/></div>

                <MRModalLabel style={{marginBottom: "0px"}}>INCIDENT ATTACHMENTS</MRModalLabel>
                <ModalNameInputDiv>
                    <Dropzone onFilesAdded={this.filesLoadednew} initFiles={this.props.breach_cur_files_json} addnew={!this.props.disabledCheck}/>
                </ModalNameInputDiv>

                <MRModalLabel>WAS THE INCIDENT INTERNAL OR EXTERNAL?</MRModalLabel>
                <div style={{marginTop: "10px",display: "inline-block"}}>                    
                    {
                        (() => {
                            if(this.props.disabledCheck){
                                if(this.props.isinternal_external_incident === 1){
                                    return(<div style={{fontSize:"23px",float: "left", marginRight: "20px",marginTop: "6px"}}><IoMdRadioButtonOn /></div>)
                                }else{
                                    return(<div style={{fontSize:"23px",float: "left", marginRight: "20px",marginTop: "6px"}} ><IoMdRadioButtonOff /></div>)
                                }
                            }else{
                                if(this.props.isinternal_external_incident === 1){
                                    return(<div style={{cursor: "pointer", fontSize:"23px",float: "left", marginRight: "20px",marginTop: "6px"}}><IoMdRadioButtonOn /></div>)
                                }else if(this.props.isinternal_external_incident === 0){
                                    return(<div style={{cursor: "pointer", fontSize:"23px",float: "left", marginRight: "20px",marginTop: "6px"}} onClick={this.handlenaSectionIncludedNew(1)}><IoMdRadioButtonOff /></div>)
                                }else{
                                    return(<div style={{cursor: "pointer", fontSize:"23px",float: "left", marginRight: "20px",marginTop: "6px"}} onClick={this.handlenaSectionIncludedNew(1)}><IoMdRadioButtonOff /></div>)
                                }
                            }
                        })()
                    }
                    <MRModalSubHeaderText style={{marginLeft: "0px",paddingRight: "30px",display: "inline-block"}}>Internal</MRModalSubHeaderText> 
                </div> 
                <div style={{marginTop: "10px",display: "inline-block", marginLeft: "10px"}}>
                    {
                        (() => {
                            if(this.props.disabledCheck){
                                if(this.props.isinternal_external_incident === 1){
                                    return(<div style={{fontSize:"23px",float: "left", marginRight: "20px",marginTop: "6px"}}><IoMdRadioButtonOff /></div>)
                                }else{
                                    return(<div style={{fontSize:"23px",float: "left", marginRight: "20px",marginTop: "6px"}}><IoMdRadioButtonOn /></div>)
                                }
                            }else{
                                if(this.props.isinternal_external_incident === 1){
                                    return(<div style={{cursor: "pointer", fontSize:"23px",float: "left", marginRight: "20px",marginTop: "6px"}} onClick={this.handlenaSectionIncludedNew(0)}><IoMdRadioButtonOff /></div>)
                                }else if(this.props.isinternal_external_incident === 0){
                                    return(<div style={{cursor: "pointer", fontSize:"23px",float: "left", marginRight: "20px",marginTop: "6px"}}><IoMdRadioButtonOn /></div>)
                                }else{
                                    return(<div style={{cursor: "pointer", fontSize:"23px",float: "left", marginRight: "20px",marginTop: "6px"}} onClick={this.handlenaSectionIncludedNew(0)}><IoMdRadioButtonOff /></div>)
                                }
                            }
                        })()
                    }                    
                    <MRModalSubHeaderText style={{marginLeft: "0px",paddingRight: "30px",display: "inline-block"}}>External</MRModalSubHeaderText> 
                </div> 
                
                {
                    (() => {
                        if(this.props.isinternal_external_incident === 1){
                            if(this.props.business_areas.length !== 0){
                                return(<div>
                                    <MRModalLabel>DEPARTMENT</MRModalLabel>
                                    <MRModalSelect id="department_id" name="department_id" onChange={this.handleChange} value={this.props.department_id} disabled={this.props.disabledCheck}>
                                        <option value="">Select</option>    
                                        {
                                            this.props.business_areas.map((rc,index) => {
                                                return(
                                                <option key={index} value={rc.businessAreaID}>{rc.businessAreaName}</option>
                                                );
                                            })
                                        }                                                           
                                    </MRModalSelect> 
                                </div>)
                            }
                        }else if(this.props.isinternal_external_incident === 0){
                            return(<div>
                                <MRModalLabel>THIRD PARTY DETAILS</MRModalLabel>
                                {/* <MRModalTextarea id="third_party_details" rows="6" placeholder="Add details of the external third party here…" name="third_party_details" value={this.props.third_party_details} onChange={this.handleChange} readOnly={this.props.disabledCheck}/> */}
                                <div style={{width: "100%"}}><HtmlEditor content={this.props.third_party_details} sectionID={1}/></div>
                            </div>)
                        }
                    })()
                }
                {
                    (() => {
                        if(this.state.isFinancialSectionShow){
                            return(
                                <div>
                                    <SpacerXL/>
                                    <UserHr /> 
                                    <MRModalLabel style={{opacity: "1",fontWeight: "700",fontSize: "large"}}>Financial Impact</MRModalLabel>

                                    {
                                        (() => {
                                            if('financial_impact' in Store.getStoreData('module_config') && 'impact_type' in Store.getStoreData('module_config').financial_impact && Store.getStoreData('module_config').financial_impact.impact_type === true){
                                                return(<div>
                                                    <MRModalLabel style={{marginTop: "25px"}}>IMPACT TYPE</MRModalLabel>
                                                    <MRModalSelect id="impact_type" name="impact_type" onChange={this.handleChange} value={this.props.impact_type} disabled={this.props.disabledCheck}>
                                                        <option value="">Select</option>
                                                        <option value="Gain">Gain</option>
                                                        <option value="Loss">Loss</option>
                                                        <option value="No Impact">No Impact</option>               
                                                    </MRModalSelect>
                                                </div>)
                                            }
                                        })()
                                    }
                                    {
                                        (() => {
                                            if('financial_impact' in Store.getStoreData('module_config') && 'record_cost_of_incident' in Store.getStoreData('module_config').financial_impact && Store.getStoreData('module_config').financial_impact.record_cost_of_incident === true){
                                                return(<div>
                                                    <div style={{display: "inline-block", width: "50%"}}>
                                                        <MRModalLabel>ESTIMATED LOSS</MRModalLabel>
                                                        <div style={{display: "inline-block"}}>
                                                            <MRModalSelect style={{width: "35%"}} id="estimated_loss_curreny" name="estimated_loss_curreny" onChange={this.handleChange} value={this.props.estimated_loss_curreny} disabled={this.props.disabledCheck}>
                                                                <option value='GBP'>GBP</option>
                                                                <option value='EUR'>EUR</option>
                                                                <option value='USD'>USD</option>
                                                                <option value='CAD'>CAD</option>            
                                                            </MRModalSelect>
                                                            <MRModalInput name="estimated_loss_value" value={this.props.estimated_loss_value} onChange={this.handleChange} disabled={this.props.disabledCheck} style={{width: "45%", marginLeft: "10px", height: "26px"}}/>
                                                        </div>
                                                    </div>
                                                    <div style={{display: "inline-block", width: "50%"}}>
                                                        <MRModalLabel>ACTUAL LOSS</MRModalLabel>
                                                        <div style={{display: "inline-block"}}>
                                                            <MRModalSelect style={{width: "35%"}} id="actual_loss_curreny" name="actual_loss_curreny" onChange={this.handleChange} value={this.props.actual_loss_curreny} disabled={this.props.disabledCheck}>
                                                                <option value='GBP'>GBP</option>
                                                                <option value='EUR'>EUR</option>
                                                                <option value='USD'>USD</option>
                                                                <option value='CAD'>CAD</option>          
                                                            </MRModalSelect>
                                                            <MRModalInput name="actual_loss_value" value={this.props.actual_loss_value} onChange={this.handleChange} disabled={this.props.disabledCheck} style={{width: "45%", marginLeft: "10px", height: "26px"}}/>
                                                        </div>
                                                    </div>
                                                </div>)
                                            }
                                        })()
                                    }
                                    {
                                        (() => {
                                            if('financial_impact' in Store.getStoreData('module_config') && 'record_third_party_costs' in Store.getStoreData('module_config').financial_impact && Store.getStoreData('module_config').financial_impact.record_third_party_costs === true){
                                                return(
                                                <div>
                                                    <div style={{display: "inline-block", width: "50%"}}>
                                                        <MRModalLabel>ESTIMATED 3RD PARTY COST</MRModalLabel>
                                                        <div style={{display: "inline-block"}}>
                                                            <MRModalSelect style={{width: "35%"}} id="estimated_third_curreny" name="estimated_third_curreny" onChange={this.handleChange} value={this.props.estimated_third_curreny} disabled={this.props.disabledCheck}>
                                                                <option value='GBP'>GBP</option>
                                                                <option value='EUR'>EUR</option>
                                                                <option value='USD'>USD</option>
                                                                <option value='CAD'>CAD</option>         
                                                            </MRModalSelect>
                                                            <MRModalInput name="estimated_third_value" value={this.props.estimated_third_value} onChange={this.handleChange} disabled={this.props.disabledCheck} style={{width: "45%", marginLeft: "10px", height: "26px"}}/>
                                                        </div>
                                                    </div>
                                                    <div style={{display: "inline-block", width: "50%"}}>
                                                        <MRModalLabel>ACTUAL 3RD PARTY COST</MRModalLabel>
                                                        <div style={{display: "inline-block"}}>
                                                            <MRModalSelect style={{width: "35%"}} id="actual_third_curreny" name="actual_third_curreny" onChange={this.handleChange} value={this.props.actual_third_curreny} disabled={this.props.disabledCheck}>
                                                                <option value='GBP'>GBP</option>
                                                                <option value='EUR'>EUR</option>
                                                                <option value='USD'>USD</option>
                                                                <option value='CAD'>CAD</option>          
                                                            </MRModalSelect>
                                                            <MRModalInput name="actual_third_value" value={this.props.actual_third_value} onChange={this.handleChange} disabled={this.props.disabledCheck} style={{width: "45%", marginLeft: "10px", height: "26px"}}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                )
                                            }
                                        })()
                                    }
                                    {
                                        (() => {
                                            if('financial_impact' in Store.getStoreData('module_config') && 'financial_impact_calculation_methodology' in Store.getStoreData('module_config').financial_impact && Store.getStoreData('module_config').financial_impact.financial_impact_calculation_methodology === true){
                                                return(<div>
                                                    <MRModalLabel>FINANCIAL IMPACT CALCULATION METHODOLOGY</MRModalLabel>
                                                    <MRModalTextarea id="fin_impact_cal_method" rows="6" placeholder="Add your financial impact calculation details here…" name="fin_impact_cal_method" value={this.props.fin_impact_cal_method} onChange={this.handleChange} readOnly={this.props.disabledCheck}/>
                                                </div>)
                                            }
                                        })()
                                    }
                                    {
                                        (() => {
                                            if('financial_impact' in Store.getStoreData('module_config') && 'compensation_paid_date' in Store.getStoreData('module_config').financial_impact && Store.getStoreData('module_config').financial_impact.compensation_paid_date === true){
                                                let compdate = this.props.com_paid_date !== '' ? this.props.com_paid_date : new Date();
                                                return(<div>
                                                    <MRModalLabel>COMPENSATION PAID</MRModalLabel>
                                                    <CSLDateCover id="com_paid_date" style={{display: "inline-block"}}>
                                                        <DatePicker
                                                                onChange={this.onChangePaidDate}
                                                                clearIcon={null}                                                                
                                                                locale={"en-GB"}
                                                                calendarIcon={<FaCalendarAlt style={{color: '#243E57'}} />}
                                                                value={compdate}
                                                        />
                                                    </CSLDateCover> 
                                                </div>)
                                            }
                                        })()
                                    } 
                                    <MRModalLabel style={{marginBottom: "0px"}}>FINANCIAL IMPACT ATTACHMENTS</MRModalLabel>
                                    <ModalNameInputDiv>
                                        <Dropzone onFilesAdded={this.filesLoadedfinimp} initFiles={this.props.finimp_cur_files_json} addnew={!this.props.disabledCheck}/>
                                    </ModalNameInputDiv>
                                </div>
                            )
                        }
                    })()
                }              
                
                <SpacerXL/>
                <UserHr />                
                
                <MRModalLabel>IS THIS DEFINED AS A REPORTABLE INCIDENT?</MRModalLabel>
                <div style={{marginTop: "10px",display: "inline-block"}}>                    
                    {
                        (() => {
                            if(this.props.disabledCheck){
                                if(this.props.isreportable === 1){
                                    return(<div style={{fontSize:"23px",float: "left", marginRight: "20px",marginTop: "-3px"}}><IoMdRadioButtonOn /></div>)
                                }else{
                                    return(<div style={{fontSize:"23px",float: "left", marginRight: "20px",marginTop: "-3px"}} ><IoMdRadioButtonOff /></div>)
                                }
                            }else{
                                if(this.props.isreportable === 1){
                                    return(<div style={{cursor: "pointer", fontSize:"23px",float: "left", marginRight: "20px",marginTop: "-3px"}}><IoMdRadioButtonOn /></div>)
                                }else{
                                    return(<div style={{cursor: "pointer", fontSize:"23px",float: "left", marginRight: "20px",marginTop: "-3px"}} onClick={this.handlenaSectionIncluded(1)}><IoMdRadioButtonOff /></div>)
                                }
                            }
                        })()
                    }
                    <MRModalSubHeaderText style={{marginTop: "20px",paddingRight: "30px",display: "contents", color: "#7a8a9b"}}>Yes</MRModalSubHeaderText> 
                </div> 
                <div style={{marginTop: "10px",display: "inline-block", marginLeft: "120px"}}>
                    {
                        (() => {
                            if(this.props.disabledCheck){
                                if(this.props.isreportable === 1){
                                    return(<div style={{fontSize:"23px",float: "left", marginRight: "20px",marginTop: "-3px"}}><IoMdRadioButtonOff /></div>)
                                }else{
                                    return(<div style={{fontSize:"23px",float: "left", marginRight: "20px",marginTop: "-3px"}}><IoMdRadioButtonOn /></div>)
                                }
                            }else{
                                if(this.props.isreportable === 1){
                                    return(<div style={{cursor: "pointer", fontSize:"23px",float: "left", marginRight: "20px",marginTop: "-3px"}} onClick={this.handlenaSectionIncluded(0)}><IoMdRadioButtonOff /></div>)
                                }else{
                                    return(<div style={{cursor: "pointer", fontSize:"23px",float: "left", marginRight: "20px",marginTop: "-3px"}}><IoMdRadioButtonOn /></div>)
                                }
                            }
                        })()
                    }                    
                    <MRModalSubHeaderText style={{marginTop: "20px",paddingRight: "30px",display: "contents", color: "#7a8a9b"}}>No</MRModalSubHeaderText> 
                </div> 
                {
                    (() => {
                        if(this.props.isreportable === 1){
                            return(
                                <div>                                    
                                    <MRModalLabel>REPORTED DATE</MRModalLabel>
                                    {
                                        (() => {
                                            if(this.props.disabledCheck){
                                                return( <MRModalInput style={{height: "22px", width: "35%"}} type="text" defaultValue={reporteddate_date} readOnly/>   )
                                            }else{
                                                return(
                                                    <CSLDateCover id="reported_date" style={{display: "inline-block"}}>
                                                        <DatePicker
                                                                onChange={this.onChangeReportedDate}
                                                                clearIcon={null}                                                                
                                                                locale={"en-GB"}
                                                                minDate={new Date()}
                                                                calendarIcon={<FaCalendarAlt style={{color: '#243E57'}} />}
                                                                value={this.props.reportedDate}
                                                        />
                                                    </CSLDateCover> 
                                                )
                                            }
                                        })()
                                    }                                                                                                      
                                    <MRModalLabel>REGULATOR</MRModalLabel>
                                    <MRModalSelect id="regulator" name="regulatorID" onChange={this.handleChange} value={this.props.regulatorID} disabled={this.props.disabledCheck}>
                                        <option value="">Select</option>  
                                        {
                                            this.props.regulator_types.map((rc,index) => {
                                                return(
                                                <option key={index} value={rc.id}>{rc.ragulatorname}</option>
                                                );
                                            })
                                        }                                         
                                    </MRModalSelect> 
                                    <MRModalLabel>RULE/REFERENCE</MRModalLabel>
                                    <MRModalTextarea id="rule_reference" rows="6" placeholder="Please give further details…" name="rule_reference" value={this.props.rule_reference} onChange={this.handleChange} readOnly={this.props.disabledCheck}/>
                                    {
                                        (() => {
                                            if(Store.getStoreData('module_config').general.reporting_trading_err){
                                                return(<div>
                                                    <MRModalLabel>TRADING ERROR?</MRModalLabel>
                                                    <div style={{marginTop: "10px",display: "inline-block"}}>                    
                                                        {
                                                            (() => {
                                                                if(this.props.disabledCheck){
                                                                    if(this.props.istrading_error === 1){
                                                                        return(<div style={{fontSize:"23px",float: "left", marginRight: "20px",marginTop: "6px"}}><IoMdRadioButtonOn /></div>)
                                                                    }else{
                                                                        return(<div style={{fontSize:"23px",float: "left", marginRight: "20px",marginTop: "6px"}} ><IoMdRadioButtonOff /></div>)
                                                                    }
                                                                }else{
                                                                    if(this.props.istrading_error === 1){
                                                                        return(<div style={{cursor: "pointer", fontSize:"23px",float: "left", marginRight: "20px",marginTop: "6px"}}><IoMdRadioButtonOn /></div>)
                                                                    }else if(this.props.istrading_error === 0){
                                                                        return(<div style={{cursor: "pointer", fontSize:"23px",float: "left", marginRight: "20px",marginTop: "6px"}} onClick={this.handleChangeTradingErrorValue(1)}><IoMdRadioButtonOff /></div>)
                                                                    }else{
                                                                        return(<div style={{cursor: "pointer", fontSize:"23px",float: "left", marginRight: "20px",marginTop: "6px"}} onClick={this.handleChangeTradingErrorValue(1)}><IoMdRadioButtonOff /></div>)
                                                                    }
                                                                }
                                                            })()
                                                        }
                                                        <MRModalSubHeaderText style={{marginLeft: "0px",paddingRight: "30px",display: "inline-block"}}>Yes</MRModalSubHeaderText> 
                                                    </div> 
                                                    <div style={{marginTop: "10px",display: "inline-block", marginLeft: "10px"}}>
                                                        {
                                                            (() => {
                                                                if(this.props.disabledCheck){
                                                                    if(this.props.istrading_error === 1){
                                                                        return(<div style={{fontSize:"23px",float: "left", marginRight: "20px",marginTop: "6px"}}><IoMdRadioButtonOff /></div>)
                                                                    }else{
                                                                        return(<div style={{fontSize:"23px",float: "left", marginRight: "20px",marginTop: "6px"}}><IoMdRadioButtonOn /></div>)
                                                                    }
                                                                }else{
                                                                    if(this.props.istrading_error === 1){
                                                                        return(<div style={{cursor: "pointer", fontSize:"23px",float: "left", marginRight: "20px",marginTop: "6px"}} onClick={this.handleChangeTradingErrorValue(0)}><IoMdRadioButtonOff /></div>)
                                                                    }else if(this.props.istrading_error === 0){
                                                                        return(<div style={{cursor: "pointer", fontSize:"23px",float: "left", marginRight: "20px",marginTop: "6px"}}><IoMdRadioButtonOn /></div>)
                                                                    }else{
                                                                        return(<div style={{cursor: "pointer", fontSize:"23px",float: "left", marginRight: "20px",marginTop: "6px"}} onClick={this.handleChangeTradingErrorValue(0)}><IoMdRadioButtonOff /></div>)
                                                                    }
                                                                }
                                                            })()
                                                        }                    
                                                        <MRModalSubHeaderText style={{marginLeft: "0px",paddingRight: "30px",display: "inline-block"}}>No</MRModalSubHeaderText> 
                                                    </div> 
                                                    </div>)
                                            }
                                        })()
                                    }
                                    {
                                        (() => {
                                            if(Store.getStoreData('module_config').general.reporting_fund_portfolio_product_name){
                                                return(<div> 
                                                        <MRModalLabel>FUND/PORTFOLIO/PRODUCT NAME</MRModalLabel>
                                                        <MRModalInput name="fpp_name" value={this.props.fpp_name} onChange={this.handleChange} disabled={this.props.disabledCheck}/>
                                                        </div>)
                                            }
                                        })()
                                    }
                                    {
                                        (() => {
                                            if(this.props.disabledCheck){
                                                if(this.props.cur_files_json.length !== 0){
                                                return(<MRModalLabel style={{marginBottom: "0px"}}>EVIDENCE ATTACHMENTS</MRModalLabel> )
                                                }
                                            }else{
                                                return(<MRModalLabel style={{marginBottom: "0px"}}>EVIDENCE ATTACHMENTS</MRModalLabel> )
                                            }
                                        })()
                                    }                                    
                                    <ModalNameInputDiv>
                                        <Dropzone onFilesAdded={this.filesLoaded} initFiles={this.props.cur_files_json} addnew={!this.props.disabledCheck}/>
                                    </ModalNameInputDiv> 
                                </div>
                            )
                        }
                    })()
                }

                <MRModalLabel>IS/WAS DISCIPLINARY ACTION REQUIRED?</MRModalLabel>
                <div style={{marginTop: "10px",display: "inline-block"}}>                    
                    {
                        (() => {
                            if(this.props.disabledCheck){
                                if(this.props.disciplinaryAction === "yes"){
                                    return(<div style={{fontSize:"23px",float: "left", marginRight: "20px",marginTop: "-3px"}}><IoMdRadioButtonOn /></div>)
                                }else{
                                    return(<div style={{fontSize:"23px",float: "left", marginRight: "20px",marginTop: "-3px"}} ><IoMdRadioButtonOff /></div>)
                                }
                            }else{
                                if(this.props.disciplinaryAction === "yes"){
                                    return(<div style={{cursor: "pointer", fontSize:"23px",float: "left", marginRight: "20px",marginTop: "-3px"}}><IoMdRadioButtonOn /></div>)
                                }else{
                                    return(<div style={{cursor: "pointer", fontSize:"23px",float: "left", marginRight: "20px",marginTop: "-3px"}} onClick={this.handlenaSectionIncludedDis("yes")}><IoMdRadioButtonOff /></div>)
                                }
                            }
                        })()
                    }
                    <MRModalSubHeaderText style={{marginTop: "20px",paddingRight: "30px",display: "contents", color: "#7a8a9b"}}>Yes</MRModalSubHeaderText> 
                </div> 
                <div style={{marginTop: "10px",display: "inline-block", marginLeft: "120px"}}>
                    {
                        (() => {
                            if(this.props.disabledCheck){
                                if(this.props.disciplinaryAction === "yes"){
                                    return(<div style={{fontSize:"23px",float: "left", marginRight: "20px",marginTop: "-3px"}}><IoMdRadioButtonOff /></div>)
                                }else{
                                    return(<div style={{fontSize:"23px",float: "left", marginRight: "20px",marginTop: "-3px"}}><IoMdRadioButtonOn /></div>)
                                }
                            }else{
                                if(this.props.disciplinaryAction === "yes"){
                                    return(<div style={{cursor: "pointer", fontSize:"23px",float: "left", marginRight: "20px",marginTop: "-3px"}} onClick={this.handlenaSectionIncludedDis("no")}><IoMdRadioButtonOff /></div>)
                                }else{
                                    return(<div style={{cursor: "pointer", fontSize:"23px",float: "left", marginRight: "20px",marginTop: "-3px"}}><IoMdRadioButtonOn /></div>)
                                }
                            }
                        })()
                    }                    
                    <MRModalSubHeaderText style={{marginTop: "20px",paddingRight: "30px",display: "contents", color: "#7a8a9b"}}>No</MRModalSubHeaderText> 
                </div> 
                {
                    (() => {
                        if(this.props.disciplinaryAction === "yes"){
                            return(
                                <div>                                                               
                                    <MRModalLabel>INVESTIGATION DETAILS</MRModalLabel>
                                    <MRModalTextarea id="investigation_details" rows="6" name="detailsofinvestigation" value={this.props.detailsofinvestigation} onChange={this.handleChange} readOnly={this.props.disabledCheck}/> 
                                    {
                                        (() => {
                                            if(this.props.disabledCheck){
                                                if(this.props.cur_files_jsonNew.length !== 0){
                                                return(<MRModalLabel style={{marginBottom: "0px"}}>INVESTIGATION ATTACHMENTS</MRModalLabel> )
                                                }
                                            }else{
                                                return(<MRModalLabel style={{marginBottom: "0px"}}>INVESTIGATION ATTACHMENTS</MRModalLabel> )
                                            }
                                        })()
                                    }                                    
                                    <ModalNameInputDiv>
                                        <Dropzone onFilesAdded={this.filesLoaded1} initFiles={this.props.cur_files_jsonNew} addnew={!this.props.disabledCheck}/>
                                    </ModalNameInputDiv> 
                                </div>
                            )
                        }
                    })()
                }                

                <SpacerXL/>
                <UserHr />                  
                {
                    (() => {
                        if (this.state.assign_data.additional_fields.length !== 0) {
                            let custom_html = [];
                            this.state.assign_data.additional_fields.forEach((item) => {
                                const field = this.generateField(item, "additional_fields");
                                custom_html.push(field);
                            })
                            return custom_html;
                        }
                    })()
                }  
                {/* newsection */}
                <SpacerXL/>
                <UserHr /> 
                {
                    (() => {
                        if(Store.getStoreData('module_config').general.form_customisation_root_cause === '' || Store.getStoreData('module_config').general.form_customisation_root_cause === 'show_on_entry_form'){
                            return(<div>
                            <MRModalLabel>ROOT CAUSE</MRModalLabel>
                            <MRModalSelect name="reasonforbreach" onChange={this.handleChange} value={this.props.reasonforbreach} disabled={this.props.disabledCheck}>
                                <option value="">Select</option>  
                                {
                                    this.props.reasonforbreachlist.map((rc,index) => {
                                        return(
                                        <option key={index} value={rc.reasonforBreachID}>{rc.reasonforBreachName}</option>
                                        );
                                    })
                                }                                         
                            </MRModalSelect> 
                            </div>)
                        }
                    })()
                }
                {
                    (() => {
                        if(Store.getStoreData('module_config').general.form_customisation_incident_impact === '' || Store.getStoreData('module_config').general.form_customisation_incident_impact === 'show_on_entry_form'){
                            return(<div>                
                            <MRModalLabel>INCIDENT IMPACT</MRModalLabel>
                            <MRModalSelect name="breachimpact" onChange={this.handleChange} value={this.props.breachimpact} disabled={this.props.disabledCheck}>
                                <option value="">Select</option>  
                                {
                                    this.props.breachimpactlist.map((rc,index) => {
                                        return(
                                        <option key={index} value={rc.breachImpactID}>{rc.breachImpactName}</option>
                                        );
                                    })
                                }                                         
                            </MRModalSelect> 
                            </div>)
                        }
                    })()
                } 
                {
                    (() => {
                        if(Store.getStoreData('module_config').general.form_customisation_resolution_owner === '' || Store.getStoreData('module_config').general.form_customisation_resolution_owner === 'show_on_entry_form'){
                            return(<div> 
                            <MRModalLabel>RESOLUTION OWNER</MRModalLabel>
                            <MRModalSelect onChange={this.handleChange} name="ownershipresolution" value={this.props.ownershipresolution} disabled={this.props.disabledCheck}>                    
                            {
                                (() => {
                                    let contactsdropdown = [];
                                    contactsdropdown.push(<option value="">Select</option>)
                                    let departments_list = this.props.business_areas;                                
                                    for(let k of departments_list) {
                                        contactsdropdown.push(<option key={k.businessAreaID} value={k.businessAreaID} >{k.businessAreaName}</option>)
                                    }
                                    if(departments_list.length > 0)contactsdropdown.push(<option key={'1'} value={'1'} disabled style={{color: "#EBEBEB"}}>——————————————————————————</option>);
                                    if(this.state.conductRuleUserLists.length !== 0){
                                        let contacts = this.state.conductRuleUserLists;
                                        contacts = contacts.sort(function(a,b) {return (a.ContactName > b.ContactName) ? 1 : ((b.ContactName > a.ContactName) ? -1 : 0);} );             
                                        contacts.forEach((item) => {
                                            contactsdropdown.push(<option key={item.ContactName + '-' + item.ID} value={item.ContactName + '-' + item.ID} selected={true} >{item.ContactName}</option>)
                                        });
                                    }
                                    return contactsdropdown;        
                                })()
                            }
                            </MRModalSelect>
                            </div>)
                        }
                    })()
                }
                {
                    (() => {
                        if(Store.getStoreData('module_config').general.form_customisation_risk_category === '' || Store.getStoreData('module_config').general.form_customisation_risk_category === 'show_on_entry_form'){
                            return(<div> 
                            <MRModalLabel>RISK CATEGORY</MRModalLabel>
                            <FilterBody>
                            {
                                (() => {
                                    if(this.props.disabledCheck){
                                        return <BMApprovalTypeAhead data={this.props.riskcategorylist} selectedRiskCategory={this.props.selectedRiskCategory} ricipientValue={this.riskcategoryValueVia} isReadOnly={true} isclear={this.props.isclear} changeClear={this.changeClearVia}/>
                                    }else{
                                        if (this.state.riskcategorylist !== null) {
                                            return <BMApprovalTypeAhead data={this.props.riskcategorylist} selectedRiskCategory={this.props.selectedRiskCategory} ricipientValue={this.riskcategoryValueVia} isReadOnly={false} isclear={this.props.isclear} changeClear={this.changeClearVia}/>
                                        }
                                    }
                                })()
                            }                    
                            </FilterBody> 
                            </div>)
                        }
                    })()
                }            
            </div>
        );
    }
}
export default BreachOfRulesSection;