import React from 'react';
import styled from 'styled-components';
import CSLTable from '../Common/MCCTable';
import { FaPencilAlt,FaEdit, FaTimes,FaPlusCircle, FaEllipsisV } from "react-icons/fa";
import { IoIosCheckboxOutline, IoIosSquareOutline, IoIosWarning } from "react-icons/io";
import BusinessAreasConfigModal from './BusinessAreasConfigModal';
import Store from '../../Common/Store'
import AlertBox from '../Common/AlertBox';
import Toggle from 'react-toggle';
import "./ReactToggle.css";
import VerticalEllipsisMenuFolderReasonBreach from "./VerticalEllipsisMenuFolderReasonBreach";
import VerticalEllipsisMenuFolderDepartmentHeader from "./VerticalEllipsisMenuFolderDepartmentHeader";
import CSLLoader from './../Common/CSLLoader';


const SearchInput = styled.input`
    height: 30px;
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #222222;
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
`;
const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #1E3E62;
    color: #ffffff;
    border: 1px solid #1E3E62;
    width: 37%;
    font-size: 13px;
    height: 43px;
    border-radius: 4px;
    display: inline-block;
    margin-left: 5px;
`;
const MRModalLabel = styled.div`
    color: #212121;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
    display: inline-block;
`;
const ExportButton = styled.button`
    box-sizing: border-box;
    background-color: #213E61;
    color: #F2F2F2;
    border: 1px solid #213E61;
    width: 125px;
    font-size: 14px;
    height: 32px;
    border-radius: 5px;
    margin-right: 3px;
    margin-top: 19px;
`;

const EmailContainer = styled.div`
	width: 100%;
`;
const EmailInnerContainer = styled.div`
	background-color: #ffffff;
	padding: 30px;
`;
const EmailBoldLabel = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 5px;
`;
const EmailTopHeader = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 5px;
	width:100%;
	height: 58px;
	padding: 20px;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  background-color: #ffffff;
  box-sizing: border-box;	
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 210%;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: 0%;
  top: -46px;
  z-index: 1000;
  opacity: 0.8;
`;

class BusinessAreas extends React.Component
{
    state = {
        showActiveCheckbox: false,
        business_areas: null,
        show_business_areas_array: null,
        business_areas_search: null,
        businessAreasActiveArrayAll: null,
        businessAreasInactiveArrayAll: null,
        ready: false,
        show_business_areas_modal: false,
        standard_business_areas: null,
        curid: 0,
        content: null,
        alert_param: null,
        headerText: "",
        isLoadedIcon: false
    };
    constructor(props) {
        super(props);        
    }

    componentDidMount()
	{		
		let businessAreasActiveArrayAll = [];		
		let businessAreasInactiveArrayAll = [];
		for(let i=0;i<this.props.business_areas.length;i++){
			if(this.props.business_areas[i].isActive === 1){
                businessAreasActiveArrayAll.push(this.props.business_areas[i])
                businessAreasInactiveArrayAll.push(this.props.business_areas[i])
			}else{
				businessAreasInactiveArrayAll.push(this.props.business_areas[i])
			}
		}
		let businessareasValue = [];
		if(this.props.general.business_areas_active_inactive){
			businessareasValue = businessAreasInactiveArrayAll
			console.log("Sourav")
		}else{			
			businessareasValue = businessAreasActiveArrayAll
			console.log("Sourav1")
		}
		this.setState({showActiveCheckbox: this.props.general.business_areas_active_inactive,
			business_areas: this.props.business_areas,
			//show_business_areas_array: businessareasValue,
			show_business_areas_array: this.props.business_areas,
			business_areas_search: this.props.business_areas,
			businessAreasActiveArrayAll: businessAreasActiveArrayAll,
            businessAreasInactiveArrayAll: businessAreasInactiveArrayAll,
            standard_business_areas: this.props.standard_business_areas,
            ready: true,alert_param: null});
		console.log("business_areas===>",this.props.business_areas);
    }
    
    componentDidUpdate(prevProps)
	{
		if (prevProps !== this.props) {
			let businessAreasActiveArrayAll = [];		
            let businessAreasInactiveArrayAll = [];
            for(let i=0;i<this.props.business_areas.length;i++){
                if(this.props.business_areas[i].isActive === 1){
                    businessAreasActiveArrayAll.push(this.props.business_areas[i])
                    businessAreasInactiveArrayAll.push(this.props.business_areas[i])
                }else{
                    businessAreasInactiveArrayAll.push(this.props.business_areas[i])
                }
            }
            let businessareasValue = [];
            if(this.props.general.business_areas_active_inactive){
                businessareasValue = businessAreasInactiveArrayAll
                console.log("Sourav")
            }else{			
                businessareasValue = businessAreasActiveArrayAll
                console.log("Sourav1")
            }
            this.setState({showActiveCheckbox: this.props.general.business_areas_active_inactive,
                business_areas: this.props.business_areas,
                //show_business_areas_array: businessareasValue,
                show_business_areas_array: this.props.business_areas,
                business_areas_search: this.props.business_areas,
                businessAreasActiveArrayAll: businessAreasActiveArrayAll,
                businessAreasInactiveArrayAll: businessAreasInactiveArrayAll,
                standard_business_areas: this.props.standard_business_areas,
                ready: true,alert_param: null,
                isLoadedIcon: false});
            console.log("business_areas===>",this.props.business_areas);			
		}
    }
    
    searchValue = (event) => {
		event.preventDefault();
        console.log("Sourav",event.target.value);
		console.log("fddsf",this.state.business_areas_search)
		if(!this.state.showActiveCheckbox){
			let search_string = event.target.value;
			if (search_string === "") {
				let business_areas = this.state.businessAreasActiveArrayAll;            
				this.setState({show_business_areas_array: business_areas});
			}else {
				let all_value = this.state.businessAreasActiveArrayAll;
				let filtered_values = [];
				all_value.forEach((breach) => {
					if (breach.businessAreaName.toLowerCase().includes(search_string.toLowerCase())) {
						filtered_values.push(breach);
					}                
				})            
				let business_areas = filtered_values;

				this.setState({show_business_areas_array: business_areas})
			}
		}else{
			let search_string = event.target.value;
			if (search_string === "") {
				let business_areas = this.state.businessAreasInactiveArrayAll;            
				this.setState({show_business_areas_array: business_areas});
			}else {
				let all_value = this.state.businessAreasInactiveArrayAll;
				let filtered_values = [];
				all_value.forEach((breach) => {
					if (breach.businessAreaName.toLowerCase().includes(search_string.toLowerCase())) {
						filtered_values.push(breach);
					}                
				})            
				let business_areas = filtered_values;

				this.setState({show_business_areas_array: business_areas})
			}
		}
    }
    
    handleActiveInactive = (value) => (event) => {        
        console.log("value",value)  
		let data = value === 1 ? true : false;
		this.props.changeActiveInactive(data);		       
    }

    handlenaSectionIncluded = (section_id) => (event) => {
		console.log("content:", section_id);
		let {show_business_areas_array} = this.state;
		let newshow_business_areas_array = [];
		show_business_areas_array.forEach((item) => {
			if (item.businessAreaID === section_id) {
				item.isActive = item.isActive === 1 ? 0 : 1; 				
			}
			newshow_business_areas_array.push(item);
		})
		console.log("emails:", newshow_business_areas_array);
        this.setState({isLoadedIcon: true})
		this.props.updateshow_business_areas(newshow_business_areas_array);
	}

    showConfigModal = (curid, isdelete) => (obj) => {
		console.log("curid==>", curid)
		console.log("obj==>", obj)
		if(obj === 'Edit'){			
            const content = this.state.business_areas.find((item) => {
                return item.businessAreaID === curid;
            })
            this.setState({content,curid: curid,show_business_areas_modal: true, headerText: "Edit"});   
		}else if(obj === 'Delete'){
            if(isdelete === 0){
                let alert_param = {title: 'Warning', message: 'Are you sure you want to delete this Department from the list?', ok_text: 'Yes', cancel_text: 'No', confirm: true, 
                           alertHandler: this.updatedeleteReasonBreach, stack: {id: curid}}
                this.setState({alert_param: alert_param})
            }else{
                let alert_param = {title: 'ERROR', message: 'This Department has already been used.', ok_text: 'Ok', confirm: false, 
                           alertHandler: this.updateafternodelete, stack: {}}
                this.setState({alert_param: alert_param})
            }			
		}
	};
    

    tableData = () => {
        console.log("this.state.show_business_areas_array",this.state.show_business_areas_array)
        let ret = {data: [], columns: []};
        ret.columns =[
                        {Header: 'Name', accessor: 'name', minWidth: 240, headerStyle: {textAlign: 'left'}},					
						{'Header' : 'Status', Cell: row => (
							<div> 
							<Toggle
								style={{float:'left'}}
								defaultChecked={row.original.status === "Active" ? true : false}
								icons={false}	
                                onChange={this.handlenaSectionIncluded(row.original.id)}						
							/>
							</div>
						 ), headerStyle:{textAlign: 'center'},style: {textAlign: 'center'}},						
                        {'Header' : '', Cell: row => (
                            <div>                                
                                  <VerticalEllipsisMenuFolderReasonBreach editDelete={this.showConfigModal(row.original.id, row.original.isdelete)} options={["Edit", "Delete"]} />	
                            </div>
                         ), minWidth: 40, headerStyle:{textAlign: 'left'},style: {textAlign: 'center'}}
                        ];

        let business_areas_sort_array = this.state.show_business_areas_array.sort(function(a,b) {return (a.businessAreaName > b.businessAreaName) ? 1 : ((b.businessAreaName > a.businessAreaName) ? -1 : 0);} );
		business_areas_sort_array.forEach((item) => {
			let statusValue = item.isActive === 1 ? "Active" : "Inactive"
			let elem = {id: item.businessAreaID, name: item.businessAreaName, status: statusValue, isdelete: item.isDelete};
			ret.data.push(elem);
        })
        return ret;
    }

    notDeleteBusinessAreas = () => {
        let alert_param = {title: 'ERROR', message: 'This Department has already been used.', ok_text: 'Ok', confirm: false, 
                           alertHandler: this.updateafternodelete, stack: {}}

        this.setState({alert_param: alert_param})
    }
    
    updateafternodelete = (result, stack) => {
        console.log(result,stack)
        this.setState({alert_param: null})        
    }

    deleteBusinessAreas = (id) => (event) => {
        event.preventDefault(); 
        let alert_param = {title: 'Warning', message: 'Are you sure you want to delete this Department from the list?', ok_text: 'Yes', cancel_text: 'No', confirm: true, 
                           alertHandler: this.updatedeleteReasonBreach, stack: {id: id}}
        this.setState({alert_param: alert_param})
    }

    updatedeleteReasonBreach = (result, stack) => {   
        if(!result) {
            this.setState({alert_param: null})
            return
        }
        console.log("id",stack.id)
           	let {business_areas} = this.state;
			let newreason = [];
			for(let i=0;i<this.state.business_areas.length;i++)
			{
				if(this.state.business_areas[i].businessAreaID !== stack.id){
					newreason.push(this.state.business_areas[i])
				}
			}			 
			console.log("delete===>",newreason);   
			this.props.deleteBusinessAreasValue(newreason); 
    }

    openModal = (id) => (event) => {
		event.preventDefault();     
		console.log("id",id); 
		const content = this.state.business_areas.find((item) => {
			return item.businessAreaID === id;
		})
		this.setState({content,curid: id,show_business_areas_modal: true});        
	}
    
    addBusinessAreas = (event) => {
		event.preventDefault();
		this.setState({show_business_areas_modal: true,curid: 0, headerText: "Add"})
    }
    closeModal = () => {		
		this.setState({show_business_areas_modal: false})
    }
    
    saveBusinessAreasValue = (obj) => {
		this.setState({show_business_areas_modal: false, isLoadedIcon: true})
		this.props.saveBusinessAreasValue(obj)
    }
    
    importType = (event) => {
        event.preventDefault();
        console.log("standard_risk_triggers",this.state.standard_business_areas)
        let alert_param = {title: 'Warning', message: 'Are you sure you want to import the Department?', ok_text: 'Yes', cancel_text: 'No', confirm: true, 
                           alertHandler: this.updateimportTypeBreach, stack: {}}
        this.setState({alert_param: alert_param})
    }

    updateimportTypeBreach = (result, stack) => {       
        if(!result) {
            this.setState({alert_param: null})
            return
        }       
        let show_business_areas_array = this.state.business_areas;
        this.state.standard_business_areas.forEach((breach) => {
            show_business_areas_array.push(breach);               
        })
        this.props.importBusinessAreasValue(show_business_areas_array)
    }

    updateBusinessAreasValue = (content) => {
		console.log("content:", content);
		//console.log("this.state",this.state.reason_for_removal)
		let {business_areas} = this.state;
		let newreason = [];
		business_areas.forEach((item) => {
			if (item.businessAreaID === content.businessAreaID) {
				item = content;
				console.log("matched", item);
			}
			newreason.push(item);
		})
		console.log("resons:", newreason);
		this.setState({show_business_areas_modal: false, isLoadedIcon: true})
		this.props.updateBusinessAreasValue(newreason);		
	}

    showConfigModalDepartment = (curValue) => (obj) => {
		console.log("curid==>", curValue)
		console.log("obj==>", obj)
        if(obj === 'Make all entries inactive'){	
            let departmentValue = this.state.show_business_areas_array
            departmentValue.forEach((item) => {
                item.isActive = 0;
            })
            this.setState({show_business_areas_array: departmentValue, isLoadedIcon: true})        
            this.props.updateshow_business_areas(departmentValue);
        }		
	};
    
    
    render()    
	{
        if (!this.state.ready) {
			return (<div>Loading...</div>);
		}          
		return(
        <div>            
            <div>
                    {
                        (() => {
                            if (this.state.show_business_areas_modal) {
                                return (<div ><InactiveOverlay style={{height: "105%"}}/><BusinessAreasConfigModal headerText={this.state.headerText} closeModal={this.closeModal} curid={this.state.curid} content={JSON.parse(JSON.stringify(this.state.content))} updateBusinessAreasValue={this.updateBusinessAreasValue} saveBusinessAreasValue={this.saveBusinessAreasValue}/></div>);
                            }
                        })()
                    }
                    {
                        (() => {
                            if(this.state.isLoadedIcon){
                                return <div><CSLLoader style={{position: 'absolute'}}/></div>
                            }
                        })()
                    }                    
                    <EmailContainer>
                        <EmailInnerContainer>
                            <EmailTopHeader style={{display: "inline-block" , fontWeight: 700}}>
                                Departments
                                <div style={{float:'right'}}>
                                    <div onClick={this.addBusinessAreas}  style={{float: "left", cursor: "pointer",fontSize: "25px",color: "#04ADA8"}}>
                                        <FaPlusCircle  /> 
                                    </div>
                            
                                    <div style={{float: "right", cursor: "pointer",fontSize: "19px",color: "#e4e4e4", marginLeft: "8px"}}>
                                        {/* <FaEllipsisV  />  */}
                                        <VerticalEllipsisMenuFolderDepartmentHeader  editDelete={this.showConfigModalDepartment('Make all entries inactive')} options={["Make all entries inactive"]} />
                                        </div>
                                </div>
							</EmailTopHeader>										
                            <CSLTable add={false} processData={this.tableData} tableRows={10} />
                        </EmailInnerContainer>
                    </EmailContainer>
            </div>
            <AlertBox alertParam = {this.state.alert_param}/>
        </div>
        );
	}
}

export default BusinessAreas;