import React from 'react';
import {FaTimes, FaTrashAlt, FaQuestionCircle} from 'react-icons/fa';
import styled from 'styled-components';
import { IoIosCheckboxOutline, IoIosSquareOutline, IoIosWarning } from "react-icons/io";
import AlertBox from '../Common/AlertBox';
import Toggle from 'react-toggle';
import "./ReactToggle.css";

const EmailContainer = styled.div`
	width: 596px;
	height:640px;
	top:75px;
	position: fixed;
	background-color: #f6f9fd;
    border-radius: 2px;
    box-shadow: 0 0 20px #a7a6a6;
	z-index: 1005;
	padding: 0px;
`;
const EmailInput = styled.input`
    height: 30px;
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #222222;
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
`;
const EmailTextArea = styled.textarea`
    height: 150px;
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #222222;
    font-family: 'Montserrat', sans-serif;
`;
const VariablePill = styled.div`
	padding: 10px 15px;
	background-color: #ffffff;
	color: #222222;
	font-weight: 600;
	border: 1px solid #222222;
	display: inline-block;
	margin-left: 5px;
	margin-right: 5px;
	margin-top: 10px;
	border-radius: 2em;
	cursor: pointer;
`;
const CancelBtn = styled.button`
    padding: 10px 30px;
	background-color: #ffffff;
	border: 1px solid #EBEBEB;
	border-radius: 2px;
	color: #282828;
	cursor: pointer;	
`;
const SubmitBtn = styled.button`
	background-color: #37ADA7;
	border: 1px solid #37ADA7;
	border-radius: 4px;
	padding: 10px 30px;
	cursor: pointer;
	color: #ffffff;
`;
const MRModalLabel = styled.div`
    color: #212121;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
`;
const MRModalInput = styled.input`
    height: 25px;
    width: 80%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    padding-right: 10px;
    padding-left: 10px;
    color: #000000;
`;
const MRModalHeader = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 24px 24px 15px 24px;
`;
const MRModalHeaderText = styled.div`
    box-sizing: border-box;
    float: left;
    color: #a0a0a0;
    font-weight: 600;
    font-size: 17px;
`;
const MRModalHeaderCloseBtn = styled.div`
    box-sizing: border-box;
    float: right;
    font-size: 16px;
    color: #a0a0a0;
    cursor: pointer;
    font-size: 20px;
`;
const MRModalBody = styled.div`
    display: block;
    box-sizing: border-box;
    padding: 10px 20px 10px 20px;
    height:474px;
`;
const MRModalSelect = styled.select`
    display: block;
    box-sizing: border-box;
    height: 40px;
    width: 70%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #727272;
    margin-top: 10px;
    margin-bottom: 10px;
`;
const SaveBtn = styled.button`
	padding: 10px 30px;
	background-color: #37ADA7;
	border: 1px solid #37ADA7;
	border-radius: 2px;
	color: #ffffff;
	cursor: pointer;
	margin-left: 15px;
`; 

const DocFooter = styled.div`
    position: relative;
    background-color: white;
    border-style: solid;
    border-width: 1px;
    border-color: #e9ecef;
    height: 79px;
    width: 100%;
    margin-left: 0px;
    border-bottom-radius: 2px;
`;
const FooterButtonsContainer = styled.div`
	text-align: right;
	padding-top: 25px;
	width: 90%;
`; 

class RegulatorTypesConfigModal extends React.Component
{
	state = {ready: false,ragulatorname: "",isActive: 1,isDelete: 0,id: null,alert_param: null};

	componentDidMount()
	{        
        if(this.props.curid !== 0) {
            console.log("content==>",this.props.content)            
            this.setState({ready:true,id: this.props.content.id,ragulatorname: this.props.content.ragulatorname,
            isActive: this.props.content.isActive,isDelete: this.props.content.isDelete})
        }else{
            let randNo = Math.floor(Math.random()*(99999-10000+1)+10000);
            this.setState({ready:true,id: randNo})
        }
	}

	closeModal = (event) => {
		event.preventDefault();
		this.props.closeModal();
    }
    
    handleChange = (event) => {
        event.preventDefault();
        this.setState({[event.target.name]: event.target.value});
    }

    submitRegulatorType_validation = (event) => {
        event.preventDefault();
        let problems = false
        let message = ''
        if(this.state.ragulatorname === "")
        {
            problems = true
            message += 'Please provide a Regulator name.'
        }
        if(problems) {
            let alert_param = {title: 'ERROR', message: message, ok_text: 'Ok', confirm: false, 
                           alertHandler: this.checkvalidation, stack: {}}

            this.setState({alert_param: alert_param})            
        } else {
            this.checkvalidation(false, {})
        }
    } 

    checkvalidation = (result, stack) => {        
        this.setState({alert_param: null})
        if(!result){
            console.log("Sourav")
            this.submitRegulatorType()
        }        
    }

    submitRegulatorType = () => {
        //event.preventDefault();        
        let breachObject = {}
        breachObject.id = this.state.id;
        breachObject.ragulatorname = this.state.ragulatorname;            
        breachObject.isActive = parseInt(this.state.isActive);
        breachObject.isDelete = parseInt(this.state.isDelete);            
        if(this.props.curid !== 0) {
            this.props.updateRegulatorTypesValue(breachObject);
        }else{                
            this.props.saveRegulatorTypeValue(breachObject);
        }
    }

    handleToggleChange = (arg)=>{
		let {isActive} = this.state;
		isActive = isActive === 1 ? 0 : 1;		
		this.setState({isActive})
	}

	render()
	{
		if (!this.state.ready) {
			return (<div>Loading...</div>);
		}
		return (
            <div>
		
		    <EmailContainer id="root_cause">
                <MRModalHeader>
                    <MRModalHeaderText>{this.props.headerText} Regulator</MRModalHeaderText>
                    <MRModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></MRModalHeaderCloseBtn>
                    <div style={{clear: "both"}}></div>                
                </MRModalHeader>            
                <MRModalBody>
                    <MRModalLabel style={{display: "block"}}>Name <span style={{marginLeft: "5px",color: "red"}}>*</span></MRModalLabel>
                    <MRModalInput name="ragulatorname" value={this.state.ragulatorname} onChange={this.handleChange}/>

                    <MRModalLabel style={{marginTop: "30px",display: "block"}}>Status </MRModalLabel>
                    
                    <Toggle name="isActive"
					    defaultChecked={this.state.isActive === 1 ? true : false} 
					    icons={false}
					    onChange={()=> this.handleToggleChange('isActive')} />                         
                </MRModalBody>                
                <div style={{clear: "both"}}></div> 
                <DocFooter style={{float: "right",marginTop: "30px"}}>
                        <FooterButtonsContainer>   
                        <CancelBtn onClick={this.closeModal}>Cancel</CancelBtn>
                        <SaveBtn onClick={this.submitRegulatorType_validation}>Submit</SaveBtn>
                        </FooterButtonsContainer>   
                </DocFooter>
               
			</EmailContainer>
            <AlertBox alertParam = {this.state.alert_param}/>
            </div>
		);
	}
}

export default RegulatorTypesConfigModal;