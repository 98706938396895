import React from 'react';
import styled from 'styled-components';
import { FaHome, FaCog, FaThLarge,FaWrench } from "react-icons/fa";
import Store from '../Common/Store'
import { Link } from 'react-router-dom'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faHouseBlank, faSquareList, faGear, faFlag, faUsers } from '@fortawesome/pro-thin-svg-icons'
const HeadContainer = styled.div`
    display: block;    
    height: 35px;
    background-color: #ffffff;
    padding-left: 10px;
    padding-right: 10px;
`;
const HomeLogo = styled.div`
    float: left;
    margin-top: 7px;
    font-size: 16px;
    color: #282828;
`;
const HomeText = styled.div`
    float: left;
    margin-top: 7px;
    color: #282828;
    margin-left: 10px;
    font-size: 13px;
`;
const Categories = styled.div`
    float: right;
    font-size: 17px;
    background-color: #ebebeb;
    color: #c4c4c4;
    width: 25px;
    height: 25px;
    margin-left: 5px;
    margin-top: 4px;
    padding-left: 4px;
    padding-top: 3px;
    border-radius: 2px;
`;
class CSLHeader extends React.Component
{
    redirectToModuleConfig = (event) => {
        event.preventDefault();
        const url = "/moduleconfig";
        window.open(url, "fc_mc", "width=1400, height=900");
    }

    render()
    {
      let use_site_layout = false
      if(localStorage.getItem('site_layout')!==null){
      let site_layout = JSON.parse(localStorage.getItem('site_layout'));
      use_site_layout = site_layout.result.use_site_layout;
      }
      if(use_site_layout) return (<div></div>)
      const role = Store.getStoreData('role')
      return (
        <HeadContainer>
          <Link to={'/'}>
            <HomeText>
              <FontAwesomeIcon icon={faHouseBlank} style={{float: 'left', marginRight: '10px', fontSize: '16px', color: '#929292'}}/>
              <span style={{fontWeight: "600"}}>{this.props.headerText}</span>
            </HomeText>
          </Link>
          { role === 'admin_manager' &&
            <a href={process.env.REACT_APP_AUDIT_URL}>
              <Categories title="Audit Logs" ><FontAwesomeIcon icon={faSquareList} style={{cursor: 'pointer', fontSize: '16px', color: '#929292'}} /></Categories>
            </a>
          }
          { role === 'admin_manager' &&
            <Link to={'/moduleconfig'}>
              <Categories title="Module Config" ><FontAwesomeIcon icon={faGear} style={{fontSize: '16px', color: '#929292'}}/></Categories>
            </Link>
          }
          <div style={{clear: "both"}}></div>
        </HeadContainer>
      );
    }
}
export default CSLHeader;
