import React from 'react';
import styled from 'styled-components';
import { Icon } from 'react-icons-kit';
import {plusCircle} from 'react-icons-kit/fa/plusCircle';
import SaleDateField from './SaleDateField';
import SingleCustomField from './SingleCustomField';
import Utils from '../../Common/Utils';
import Toggle from 'react-toggle';
import "./ReactToggle.css";
import { FaPlusCircle, FaEllipsisV } from "react-icons/fa";
import CSLTable from '../Common/MCCTable';
import VerticalEllipsisMenuFolderReasonBreach from "./VerticalEllipsisMenuFolderReasonBreach";
import CustomFieldsConfigModal from './CustomFieldsConfigModal';
import AlertBox from '../Common/AlertBox';
import CSLLoader from './../Common/CSLLoader';

const EmailContainer = styled.div`
	width: 100%;
`;
const EmailInnerContainer = styled.div`
	background-color: #ffffff;
	padding: 30px;
`;
const EmailBoldLabel = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 5px;
`;
const SubHeading = styled.div`
	font-style: italic;
	font-size: 12px;
	color: #adacac;
	margin-top: 5px;
`;
const EmailLabel = styled.div`
	color: #595959;
	margin-top: 5px;
`;
const ConfigBtn = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    font-size: 13px;
    border-radius: 4px;
    padding: 5px 20px;
    cursor: pointer;
`;
const SelectedRadio = styled.div`
	background-color: #37ADA7;
	border: 1px solid #1f615e;
	padding: 5px 10px;
	color: #ffffff;
	display: inline-block;
	cursor: pointer;
`;
const UnselectedRadio = styled.div`
	background-color: #F4F4F4;
	border: 1px solid #8c8c8c;
	padding: 5px 10px;
	color: #262626;
	display: inline-block;
	cursor: pointer;
`;
const RadioBlock = styled.div`
	margin-top: 10px;
`;
const Clearfix = styled.div`
	clear: both;
`;
const FloatingSection = styled.div`
	float: left;
	box-sizing: border-box;
`;
const BoldLabel = styled.div`
	font-weight: 600;
`;
const FooterButtonsContainer = styled.div`
	text-align: right;
	padding-top: 25px;
	width: 97%;
    margin-bottom: 25px;
`;
const CancelBtn = styled.button`
	padding: 10px 20px;
	background-color: #ffffff;
	border: 1px solid #ffffff;
	border-radius: 2px;
	color: #333333;
	cursor: pointer;
`;
const SaveBtn = styled.button`
	padding: 10px 30px;
	background-color: #37ADA7;
	border: 1px solid #37ADA7;
	border-radius: 2px;
	color: #ffffff;
	cursor: pointer;
	margin-left: 15px;
`;

const MRModalLabel = styled.div`
	color: #1a3552;
	font-family: "DMSans-Medium", "DM Sans Medium", "DM Sans", sans-serif;
	font-weight: 600;
	margin-top: 10px;
	display: inline-block;
`;
const MRModalSubLabel = styled.div`
	color: #838c91;
	font-weight: 500;
	margin-top: 10px;
	display: inline-block;
`;
const EmailTopHeader = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 5px;
	width:100%;
	height: 58px;
	padding: 20px;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  background-color: #ffffff;
  box-sizing: border-box;
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 210%;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: 0%;
  top: -46px;
  z-index: 1000;
  opacity: 0.8;
`;

class CustomFields extends React.Component
{
	state = {custom_fields: null, final_custom_field: null, additional_fields: [], show_custom_fields_modal: false, curid: 0, headerText: "", content: null, alert_param: null, isLoadedIcon: false};

	componentDidMount()
	{
		let modify_additional_fields = []
		for(let k of this.props.custom_fields.additional_fields){
			if(!('isActive' in k)){
				k.isActive = 1;
				k.isDelete = 0;
				k.displayin = "";
			}
			modify_additional_fields.push(k)
		}
		console.log("modify_additional_fields", modify_additional_fields)
		this.setState({custom_fields: this.props.custom_fields, final_custom_field: this.props.custom_fields, additional_fields: modify_additional_fields});
	}

	componentDidUpdate(prevProps)
	{
		if (this.props !== prevProps) {
			let modify_additional_fields = []
			for(let k of this.props.custom_fields.additional_fields){
				if(!('isActive' in k)){
					k.isActive = 1;
					k.isDelete = 0;
					k.displayin = "";
				}
				modify_additional_fields.push(k)
			}
			this.setState({custom_fields: this.props.custom_fields, final_custom_field: this.props.custom_fields, additional_fields: modify_additional_fields, isLoadedIcon: false});
		}
	}

	switchRadio = (key) => (event) => {
		event.preventDefault();
		let custom_fields = JSON.parse(JSON.stringify(this.state.custom_fields));
		custom_fields[key] = custom_fields[key] ? false : true;
		this.setState({custom_fields, final_custom_field: custom_fields});
		//this.props.updateCustomFields(custom_fields);
	}	

	handleToggleChange = (arg)=>{
		let custom_fields = JSON.parse(JSON.stringify(this.state.custom_fields));
		custom_fields[arg] = custom_fields[arg] ? false : true;
		this.setState({custom_fields, final_custom_field: custom_fields});
	}

	addAdditionalField = (event) => {
		event.preventDefault();
		console.log("addAdditionalField");
		// let additional_fields = JSON.parse(JSON.stringify(this.state.additional_fields));		
		// const randtxt = Utils.genKey(10);
		// additional_fields.push({name: randtxt, nickname: "Untitled Field", type: "Alpha", dropdown_options: [], required: false, other: false,isActive: 1, isDelete: 0, displayin: ""});
		// this.setState({additional_fields});	
		this.setState({show_custom_fields_modal: true, curid: 0, headerText: "Add"})	
	}

	closeModal = () => {		
		this.setState({show_custom_fields_modal: false})
    }

	updateCustomFieldsAll = (custom) => {
		console.log("custom",custom)
		this.setState({final_custom_field: custom})
	}

	updateCustomFieldsAllNew = (custom) => {
		console.log("custom",custom)
		this.setState({custom_fields: custom, final_custom_field: custom})
	}

	closePage = () => {
		this.props.closePage()
	}

	updateCustomModuleconfig = () => {
		console.log("sourav", this.state.final_custom_field)
		this.props.updateCustomFields(this.state.final_custom_field)
	}

	showConfigModal = (curid, isdelete) => (obj) => {
		console.log("curid==>", curid)
		console.log("obj==>", obj)
		if(obj === 'Edit'){			
            const content = this.state.additional_fields.find((item) => {
                return item.name === curid;
            })
            this.setState({content,curid: curid, show_custom_fields_modal: true, headerText: "Edit"});   
		}else if(obj === 'Delete'){
			console.log("Sourav")
            if(isdelete === 0){
				console.log("Sourav2")
                let alert_param = {title: 'Warning', message: 'Are you sure you want to delete this Custom Field from the list?', ok_text: 'Yes', cancel_text: 'No', confirm: true, 
                           alertHandler: this.updatedeleteCustomField, stack: {id: curid}}
                this.setState({alert_param: alert_param})
            }else{
                let alert_param = {title: 'ERROR', message: 'This Custom has already been used.', ok_text: 'Ok', confirm: false, 
                           alertHandler: this.updateafternodelete, stack: {}}
                this.setState({alert_param: alert_param})
            }			
		}
	};

	updatedeleteCustomField = (result, stack) => {  
        if(!result) {
            this.setState({alert_param: null})
            return
        }
        console.log("id",stack.id)           	
        let newreason = [];
        for(let i=0;i<this.state.additional_fields.length;i++)
        {
            if(this.state.additional_fields[i].name !== stack.id){
                newreason.push(this.state.additional_fields[i])
            }
        }			 
        console.log("delete===>",newreason); 
		this.setState({alert_param: null})  
        this.props.deleteCustomFieldsValue(newreason);
    }

	updateafternodelete = (result, stack) => {
        console.log(result,stack)
        this.setState({alert_param: null})        
    }

	handlenaSectionIncludedIsRequired = (section_id) => (event) => {
		console.log("content:", section_id);
		let {additional_fields} = this.state;
		let newadditional_fields_array = [];
		additional_fields.forEach((item) => {
			if (item.name === section_id) {
				item.required = item.required ? false : true; 				
			}
			newadditional_fields_array.push(item);
		})
		console.log("emails:", newadditional_fields_array);
        this.setState({isLoadedIcon: true})
		this.props.updateAdditional_fields(newadditional_fields_array);
	}

	handlenaSectionIncluded = (section_id) => (event) => {
		console.log("content:", section_id);
		let {additional_fields} = this.state;
		let newadditional_fields_array = [];
		additional_fields.forEach((item) => {
			if (item.name === section_id) {
				item.isActive = item.isActive === 1 ? 0 : 1; 				
			}
			newadditional_fields_array.push(item);
		})
		console.log("emails:", newadditional_fields_array);
        this.setState({isLoadedIcon: true})
		this.props.updateAdditional_fields(newadditional_fields_array);
	}

	tableData = () => {
        //console.log("this.state.additional_fields",this.state.additional_fields)
        let ret = {data: [], columns: []};
        ret.columns =[
                        {Header: 'CUSTOM FIELD NAME', accessor: 'cname', minWidth: 150, headerStyle: {textAlign: 'left'}},					
                        {Header: 'TYPE', accessor: 'ctype', minWidth: 100, headerStyle: {textAlign: 'left'}},					
                        {Header: 'DISPLAY', accessor: 'cdisplay', minWidth: 100, headerStyle: {textAlign: 'left'}},					
						{'Header' : 'REQUIRED', Cell: row => (
							<div> 
							<Toggle
								style={{float:'left'}}
								defaultChecked={row.original.isRequired ? true : false}
								icons={false}	  
								onChange={this.handlenaSectionIncludedIsRequired(row.original.id)}                              						
							/>
							</div>
						 ), headerStyle:{textAlign: 'center'},style: {textAlign: 'center'}},	
						 {'Header' : 'STATUS', accessor: 'cstatus', Cell: row => (
							<div> 
							<Toggle
								style={{float:'left'}}
								defaultChecked={row.original.status === "Active" ? true : false}
								icons={false}	       
								onChange={this.handlenaSectionIncluded(row.original.id)}                         						
							/>
							</div>
						 ), headerStyle:{textAlign: 'center'},style: {textAlign: 'center'}},						
                        {'Header' : '', accessor: 'cblnk', Cell: row => (
                            <div>                                
                                  <VerticalEllipsisMenuFolderReasonBreach editDelete={this.showConfigModal(row.original.id, row.original.isdelete)} options={["Edit", "Delete"]} />	
                            </div>
                         ), minWidth: 40, headerStyle:{textAlign: 'left'},style: {textAlign: 'center'}}
                        ];

        let additional_fields_sort_array = this.state.additional_fields.sort(function(a,b) {return (a.nickname > b.nickname) ? 1 : ((b.nickname > a.nickname) ? -1 : 0);} );
		additional_fields_sort_array.forEach((item) => {
			let statusValue = item.isActive === 1 ? "Active" : "Inactive"
			let elem = {id: item.name, cname: item.nickname, ctype: item.type, cdisplay: item.displayin, isRequired: item.required, status: statusValue, isdelete: item.isDelete};
			ret.data.push(elem);
        })
        return ret;
    }   

	saveCustomFieldValue = (obj) => {
		this.setState({show_custom_fields_modal: false, isLoadedIcon: true})
		this.props.saveCustomFieldsValue(obj)
    }

	updateCustomFieldValue = (content) => {
		console.log("content:", content);		
		let {additional_fields} = this.state;
		let newreason = [];
		additional_fields.forEach((item) => {
			if (item.name === content.name) {
				item = content;
				console.log("matched", item);
			}
			newreason.push(item);
		})
		console.log("resons:", newreason);
		this.setState({show_custom_fields_modal: false, isLoadedIcon: true})
		this.props.updateCustomFieldsValue(newreason);		
	}

	render()
	{
		if (this.state.custom_fields === null) {
			return (<div>Loading...</div>);
		}
		return (
			<div>
				{
					(() => {
						if (this.state.show_custom_fields_modal) {
							return (<div ><InactiveOverlay style={{height: "105%"}}/><CustomFieldsConfigModal headerText={this.state.headerText} data={this.state.custom_fields} closeModal={this.closeModal} curid={this.state.curid} content={JSON.parse(JSON.stringify(this.state.content))} saveCustomFieldValue={this.saveCustomFieldValue} updateCustomFieldValue={this.updateCustomFieldValue}/></div>);
						}
					})()
				}
				{
					(() => {
						if(this.state.isLoadedIcon){
							return <div><CSLLoader style={{position: 'absolute'}}/></div>
						}
					})()
				} 
				<EmailContainer>
					<EmailInnerContainer>						
						<div style={{marginBottom:"10px", display: "block"}}>
							<MRModalLabel style={{display: "block", color: "#1d3957a8",marginBottom:"10px"}}>Enable Custom Fields?</MRModalLabel>
							{/* <MRModalSubLabel style={{marginTop: "10px",marginBottom:"10px", display: "block"}}>Lorem ipsum dolor sit amet, consectetur adipisici elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua.</MRModalSubLabel> */}
							<Toggle					    
								icons={false}	
								onChange={()=> this.handleToggleChange('enabled')}										
								defaultChecked={this.state.custom_fields.enabled ? true : false} 								
							/>
						</div>						
						{
							(() => {
								if (this.state.custom_fields.enabled) {
									return(<div>
									<EmailTopHeader style={{display: "inline-block" , fontWeight: 700}}>
										Custom FIelds                              
										<div style={{float:'right'}}>
											<div onClick={this.addAdditionalField}  style={{float: "left", cursor: "pointer",fontSize: "25px",color: "#04ADA8"}}>
												<FaPlusCircle  /> 
											</div>                            
											<div style={{float: "right", cursor: "pointer",fontSize: "25px",color: "#e4e4e4"}}>
												<FaEllipsisV  /> 
											</div>
										</div>
									</EmailTopHeader>
									<CSLTable add={false} processData={this.tableData} tableRows={10} />
									</div>)
								}
							})()
						}						
					</EmailInnerContainer>
				</EmailContainer>
				<FooterButtonsContainer>   
					<CancelBtn onClick={this.closePage}>Cancel</CancelBtn>
					<SaveBtn onClick={this.updateCustomModuleconfig}>Save</SaveBtn>
				</FooterButtonsContainer>
				<AlertBox alertParam = {this.state.alert_param}/>
			</div>
		);
	}
}

export default CustomFields;